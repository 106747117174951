<div class="content-container pt-5">
  <app-sub-header
    [header]="'Thank you for confirming your seats!'"
    [gohome]="true"
    [goback]="'link-account'"
    [color]="'header-title'"
    [includeCustomer]="false"
  >
  </app-sub-header>
  <div class="checkout-container text-normal m-auto" style="position: relative">
    <div class="d-flex justify-content-center" *ngIf="order">
      <div class="col-9 m-auto">
        <div class="mb-4 fileNumber-container">
          File Number: {{ order.fileId }}
        </div>

        <div class="customerName-container">
          {{ customer.name }} {{ customer.lastname }}
        </div>
        <div class="customer-info-adress-container">
          <div>{{ customer.address.line1 }}</div>
          <div>{{ customer.address.line2 }}</div>
          <div>{{ customer.address.line3 }}</div>
          <div>{{ customer.address.town }}</div>
          <div>{{ customer.address.zipCode }}</div>
        </div>
        <div class="my-4 fileNumber-container">
          Client Reference Number: {{ customer.contactNumber }}
        </div>
        <div class="d-flex mt-3">
          <div class="parentof">
            <div class="childof">
              <div
                *ngFor="let acquirement of packageAcquirements"
                class="packages-list"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span class="h5 mb-0"
                    >Package: {{ acquirement.packageName }}</span
                  >
                </div>
                <div class="row price-table mt-5">
                  <table class="table w-100">
                    <thead>
                      <tr class="trhead">
                        <th>Block</th>
                        <th>Row</th>
                        <th>Seat Number</th>
                        <th>Price</th>
                        <th>Contact</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="trbody"
                        *ngFor="let seatData of acquirement.seats"
                      >
                        <td>{{ seatData.section }}</td>
                        <td>{{ seatData.row }}</td>
                        <td>{{ seatData.seat }}</td>
                        <td>
                          {{ seatData.priceWithoutVat | currency: 'GBP' }}
                        </td>
                        <td>{{ seatData.contact }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--        <div class="col-3 d-flex flex-column customer-info">-->
      <!--          <div>-->
      <!--            <span class="contact-info-label">Contact Number:</span> {{customer.contactNumber}}-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <span class="contact-info-label">Contact Name:</span> {{customer.name}} {{customer.lastName}}-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <span class="contact-info-label">Contact Email:</span> {{customer.email}}-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <span class="contact-info-label">Contact Phone:</span> {{customer.phone}}-->
      <!--          </div>-->
      <!--          <div>-->
      <!--            <span class="contact-info-label">Contact Address:</span>-->
      <!--            <div class="customer-info-adress-container">-->
      <!--              <div>{{customer.address.line1}}</div>-->
      <!--              <div>{{customer.address.line2}}</div>-->
      <!--              <div>{{customer.address.line3}}</div>-->
      <!--              <div>{{customer.address.town}}</div>-->
      <!--              <div>{{customer.address.zipCode}}</div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
    </div>
    <div class="row mt-5 d-flex justify-content-center">
      <div class="col-9">
        <div>
          <p class="text-right price-table-little">
            Subtotal: {{ totalAmount / 1.2 / 1000 | currency: 'GBP' }}
          </p>
          <p class="text-right price-table" style="font-weight: bold">
            Total ( + VAT ): {{ totalAmount / 1000 | currency: 'GBP' }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="spinner"
      [style.opacity]="showLoader ? '1' : '0.6'"
      [style.visibility]="showLoader ? 'visible' : 'hidden'"
    >
      <i class="fa fa-spinner fa-pulse"></i>
    </div>
  </div>
  <!-- <div class="row justify-content-center" *ngIf="order">
      <button class="btn-white btn-large" (click)="endProcess()">Finish</button>
    </div> -->
  <div *ngIf="order" class="row m-5 justify-content-center">
    <hr class="h-line mb-5" />
    <div class="col text-center">
      <button class="btn-white btn-large" (click)="endProcess()">Finish</button>
    </div>
  </div>
</div>
