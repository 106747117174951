<div class="content-container pt-5 pb-4">
  <div id="admin-toolbar" class="container-fluid">
    <button
      routerLink="/admin-area/"
      class="btn-white btn-start"
      style="background: white"
    >
      Back
    </button>
    <!--    <button type='button' class='btn-start' (click)="onDelete()">Delete</button>-->
  </div>

  <table id="user-table" class="width-70 mx-auto mb-3">
    <thead>
      <tr>
        <th class="text_center">Order ID</th>
        <th class="text_center">File ID</th>
        <th class="text_center">Order State</th>
        <th class="text_center">Create At</th>
        <th class="text_center">Update At</th>
        <th class="text_center"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of orderList; let i = index">
        <td class="text_center">{{ order.order_id }}</td>
        <td class="text_center">{{ order.fileId }}</td>
        <td class="text_center">{{ order.status }}</td>
        <td class="text_center">
          {{ orderService.prettyDate(order.createAt) }}
        </td>
        <td class="text_center">
          {{ orderService.prettyDate(order.updateAt) }}
        </td>
        <td id="button-center">
          <button
            class="btn-start btn btn-secondary btn-block"
            [routerLink]="['/order/', order.order_id]"
          >
            Inspect
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="width-70 row mx-auto justify-content-between mb-3">
    <pagination
      [totalItems]="totalItems"
      [itemsPerPage]="itemsPerPage"
      [(ngModel)]="currentPage"
      [firstText]="'First'"
      [lastText]="'Last'"
      [previousText]="'Previous'"
      [nextText]="'Next'"
      (pageChanged)="pageChanged($event)"
      [boundaryLinks]="true"
      [rotate]="true"
      [maxSize]="5"
    >
    </pagination>
  </div>
</div>
