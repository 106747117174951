import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerPackagePlan} from '../../../../shared/models/customer-package/customer-package-plan.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home-page-column-item',
  templateUrl: './home-page-column-item.component.html',
  styleUrls: ['./home-page-column-item.component.scss']
})
export class HomePageColumnItemComponent implements OnInit {
  @Input('customer_package') cp: CustomerPackagePlan;
  @Input('show-package-info') pinfo;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  setSelectedPackage(): void {
    // if (this.pinfo) {
    //   this.packageTestService.selectedPackage = this.cp;
    //   this.router.navigate(['/pinfo', this.cp.name]);
    // } else {
    //   this.selectionPackageService.selectPackage(this.cp);
    // }
  }

}
