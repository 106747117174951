import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, DVM_CONFIG } from '../digitalvenue.configuration';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { Observable, Subject } from 'rxjs';
import { MapLoaderService } from './map-loader.service';

declare const DVM;

@Injectable({
  providedIn: 'root',
})
export class DVMService {
  viewer;
  viewer3d;
  viewerSubject: Subject<any> = new Subject();
  viewer3dSubject: Subject<any> = new Subject();
  isViewerSubjectInitialized = false;
  isViewer3dSubjectInitialized = false;
  subscribedCallbacks = {};
  subscribed3dCallbacks = {};
  debug: boolean;

  constructor(
    @Inject(DVM_CONFIG) private DVMConfig,
    private mapLoaderService: MapLoaderService
  ) {
    // this.initializeDVM('map_viewer', DVMConfig);
    this.viewerSubject.subscribe((viewer) => (this.viewer = viewer));
    this.debug = true;
  }

  public restartDVM(venueId?: any) {
    this.mapLoaderService.addProcess('map_loading');
    for (const event in this.subscribedCallbacks) {
      this.subscribedCallbacks[event].forEach((callback) => {
        this.viewer.unsubscribe(event, callback);
      });
    }
    this.subscribedCallbacks = {};
    // tslint:disable-next-line: forin
    for (const event in this.subscribed3dCallbacks) {
      this.subscribed3dCallbacks[event].forEach((callback) => {
        this.viewer3d.unsubscribe(event, callback);
      });
    }
    this.subscribed3dCallbacks = {};
    this.isViewerSubjectInitialized = false;
    this.isViewer3dSubjectInitialized = false;
    if (venueId) {
      const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
      viewerConfig.venue_id = venueId;
      viewerConfig.map_id = this.viewer.getMapId();
      this.initializeDVM('map_viewer', viewerConfig);
    } else {
      this.initializeDVM('map_viewer', this.DVMConfig);
    }
  }

  private initializeDVM(moduleName, config) {
    DVM.loadModule(moduleName, config)
      .then((viewer) => {
        this.viewer = viewer;
        if (this.debug) {
          (window as any).viewer = viewer;
        }
        // this.loadMap(this.DVMConfig);
        this.loadMap(config);
        if (!this.isViewerSubjectInitialized) {
          console.log('Viewer was not initialized. Init to', this.viewer);
          this.viewerSubject.next(this.viewer);
          this.isViewerSubjectInitialized = true;
        }
        viewer.flags.automatic_selection = false;
        // viewer.flags.fixed_aspect_ratio = false;

        this.mapLoaderService.removeProcess('map_loading');
      })
      .catch((err) => {
        console.error(err);
      });
    const viewer3dConfig = JSON.parse(JSON.stringify(config));
    viewer3dConfig.container = 'viewer3d-container';
    viewer3dConfig.plugins = ['navigation'];
    DVM.loadModule('3d_viewer', viewer3dConfig)
      .then((viewer3d) => {
        this.viewer3d = viewer3d;
        if (this.debug) {
          (window as any).viewer3d = viewer3d;
        }
        // if ((window as any).viewer3d == null) {
        //     (window as any).viewer3d = [];
        // }
        // (window as any).viewer3d.push(viewer3d);
        if (!this.isViewer3dSubjectInitialized) {
          this.viewer3dSubject.next(this.viewer3d);
          this.isViewer3dSubjectInitialized = true;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  changeMapConfiguration(venueId) {
    if (this.viewer) {
      const viewerConfig = JSON.parse(JSON.stringify(this.DVMConfig));
      viewerConfig.venue_id = venueId;
      viewerConfig.map_id = this.viewer.getMapId();
      this.loadMap(viewerConfig);
    }
  }

  subscribeHandler(event: 'click' | 'end_load' | 'enter' | 'leave', callback) {
    const viewerSubjectSubscribe = this.viewerSubject.subscribe((viewer) => {
      this.viewer.subscribe(event, callback);
      if (!this.subscribedCallbacks[event]) {
        this.subscribedCallbacks[event] = [];
      }
      this.subscribedCallbacks[event].push(callback);
      viewerSubjectSubscribe.unsubscribe();
    });
  }

  subscribe3dHandler(event: 'click_navigation_node', callback) {
    const viewer3dSubjectSubscribe = this.viewer3dSubject.subscribe(
      (viewer3d) => {
        this.viewer3d.subscribe(event, callback);
        if (!this.subscribed3dCallbacks[event]) {
          this.subscribed3dCallbacks[event] = [];
        }
        this.subscribed3dCallbacks[event].push(callback);
        viewer3dSubjectSubscribe.unsubscribe();
      }
    );
  }

  public loadMap(loadOptions) {
    return this.viewer.loadMap(loadOptions).then(() => {
      this.applyStyles(this.viewer);
    });
  }

  public load3DView(nodeId) {
    const loadOptions = JSON.parse(JSON.stringify(this.DVMConfig));
    loadOptions.view_id = nodeId;
    loadOptions.venue_id = this.viewer.getVenueId();
    this.viewer3d.loadView3d(loadOptions);
  }

  applyStyles(viewer) {
    const styles = [
      // Level 0. sections are visible, seats are hidden (no styles needed).
      {
        // Level 0 styles for nodes with "section" type
        section: {
          available: {
            normal: {
              none: {
                fillStyle: '#1D8EF9',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 1.5,
                cursor: 'auto',
              },
              owned: {
                fillStyle: '#DE00FF',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 1.5,
                cursor: 'auto',
              },
              vip: {
                fillStyle: 'rgb(186, 12, 47)',
                strokeStyle: 'white',
                fillOpacity: 0.2,
                lineWidth: 1.5,
                cursor: 'auto',
              },
              vipGreen: {
                fillStyle: 'green',
                strokeStyle: 'white',
                fillOpacity: 0.9,
                lineWidth: 1.5,
                cursor: 'auto',
              },
              pending: {
                fillStyle: 'orange',
                strokeStyle: 'white',
                fillOpacity: 0.9,
                lineWidth: 1.5,
                cursor: 'auto',
              },
            },
            hover: {
              none: {
                fillStyle: 'white',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 2,
                cursor: 'pointer',
              },
              owned: {
                fillStyle: 'white',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 2,
                cursor: 'pointer',
              },
              vip: {
                fillStyle: 'white',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 2,
                cursor: 'pointer',
              },
            },
          },
          unavailable: {
            normal: {
              none: {
                fillStyle: 'transparent',
                strokeStyle: 'transparent',
                cursor: 'auto',
              },
            },
          },
          disabled: 'unavailable',
        },
        // Level 0 styles for nodes with "seat" type (no styles = not shown)
        seat: {
          available: {
            normal: {
              none: {
                fillStyle: '#1D8EF9',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 0.1,
                cursor: 'auto',
              },
              owned: {
                fillStyle: '#DE00FF',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 0.1,
                cursor: 'auto',
              },
              pending: {
                fillStyle: 'orange',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 0.1,
                cursor: 'auto',
              },
            },
            hover: {
              none: {
                fillStyle: 'white',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 0.2,
                cursor: 'pointer',
              },
            },
          },
          selected: {
            normal: {
              none: {
                fillStyle: 'white',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 0.2,
                cursor: 'pointer',
                icon: 'selectedicon',
              },
              pending: {
                fillStyle: 'orange',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 0.2,
                cursor: 'auto',
              },
            },
          },
          unavailable: {
            normal: {
              none: {
                fillStyle: 'transparent',
                strokeStyle: 'transparent',
                cursor: 'auto',
              },
              owned: {
                fillStyle: '#DE00FF',
                strokeStyle: 'white',
                fillOpacity: 0.7,
                lineWidth: 0.1,
                cursor: 'auto',
              },
              pending: {
                fillStyle: '#DE00FF',
                strokeStyle: 'orange',
                fillOpacity: 0.7,
                lineWidth: 0.1,
                cursor: 'auto',
              },
            },
          },
          disabled: 'unavailable',
        },
      },
    ];
    viewer.setStyles(styles);
  }

  private getNodeId(obj) {
    return obj.nodes.length > 0 ? obj.nodes[0].id : null;
  }
}
