import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, map, take, tap } from 'rxjs/operators';
import { DVMService } from '../digitalvenue/services/dvm.service';
import { FlowcontrolService } from './flowcontrol.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiRoot = '/api';

  constructor(
    private dvmService: DVMService, // Para simular API
    private http: HttpClient,
    private flowControl: FlowcontrolService
  ) { }


  /**
   * @deprecated
   */
  getSectionsAvailability(packageID: string, seatCategoryId: string) {
    const endpoint = `${this.apiRoot}/package/${packageID}/availability/${seatCategoryId}`;
    return this.http.get(endpoint).pipe(
      map(response => response["result"]['sections'])
    )
  }


  /**
   * @deprecated
   */
  getSeatsAvailability(packageID: string, seatCategoryId: string, sectionID: string) {
    // Simulación sin tener en cuenta el package ni section
    const endpoint = `${this.apiRoot}/package/${packageID}/availability/${seatCategoryId}/${sectionID}`;
    return this.http.get(endpoint).pipe(
      map(response => response["result"]['seats']),
      tap(seats => this.flowControl.currentSectionSeats = seats)
    )
  }


  getCapacity(packageID) {
    const endpoint = `/api/package/${packageID}/capacity`;
    return this.http.get(endpoint);
  }

  /**
   * @deprecated
   */
  editTransaction(order, payload) {
    const endpoint = `${this.apiRoot}/order`;
    return this.http.post(endpoint, payload, {params: {
      order
    }});
  }

  /**
   * @deprecated
   */
  getTransaction(orderId) {
    const endpoint = `${this.apiRoot}/order/${orderId}`;
    return this.http.get(endpoint);
  }
}
