import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';
import {PackageOptionsComponent} from './package-selector/package-options/package-options.component';
import {HomePageComponent} from './package-selector/home-page/home-page.component';
import {SelectionComponent} from './package-selector/selection/selection.component';
import { AnotherPackageComponent } from './package-selector/another-package/another-package.component';
import {ProjectInfoComponent} from './package-selector/project-info/project-info.component';
import {VideoComponent} from './package-selector/video/video.component';
import { LoginClientComponent } from './login-client/login-client.component';
import { CheckoutComponent } from './checkout/checkout.component';
import {AdminAreaComponent} from './backoffice/admin-area/admin-area.component';
import {BackofficeModule} from './backoffice/backoffice.module';
import { SummaryComponent } from './summary/summary.component';
import { LinkAccountComponent } from './link-account/link-account.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/homepage'
  },
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {path: 'homepage', component: HomePageComponent},
      {path: 'project-info', component: ProjectInfoComponent},
      {path: 'video', component: VideoComponent},
      {path: 'package-info/:id', component: PackageOptionsComponent},
      {path: 'selection/packages', component: AnotherPackageComponent},
      {path: 'selection/map', component: SelectionComponent},
      {path: 'summary', component: SummaryComponent},
      {path: 'checkout', component: CheckoutComponent},
      {path: 'link-account', component: LinkAccountComponent}
    ]
  },
  {
    path: 'admin-area',
    canLoad: [AuthGuard],
    loadChildren: () => import('./backoffice/backoffice.module').then( m => m.BackofficeModule)
  },
  {
    path: 'login',
    component: LoginComponent
  }
  // // comentar esto
  // {
  //   path: 'checkout',
  //   component: CheckoutComponent
  // },
  // {
  //   path: 'summary',
  //   component: SummaryComponent
  // },
  // {
  //   path: 'pruebafd',
  //   component: PruebasAlejandroComponent
  // }
  // {
  //   path: 'homepage',
  //   component: HomePageComponent,
  //   children: [
  //     {
  //       path: 'pinfo/:packageId',
  //       component: PackageOptionsComponent
  //     }
  //   ]
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        // enableTracing: environment.routes
      }
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
