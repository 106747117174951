<div>
  <div class="super-restart-container">
    <div class="modal-header text-bold">
      <h4 class="pull-left">{{ title }}</h4>
      <button type="button" class="close" (click)="decline()" data-dismiss="modal">&times;</button>
    </div>
    <div class="modal-body text-color">
      <p> {{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="decline()">Close</button>
      <button type="button" class="btn btn-primary" (click)="confirm()">Confirm</button>
    </div>
  </div>
</div>
