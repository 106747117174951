<div class="row">
  <div class="col-6 text-package-option">
    <div class="mrgb-5">
      <p class="text-subtitle mrgb-2">Title</p>
      <p class="text-normal">TODO: Location Text in API</p>
    </div>
    <div>
      <button class="btn-white btn-view-seat-location" (click)="onGoViewSeatLocation()">View seat location</button>
    </div>

  </div>
  <div class="col-6 image-holder text-package-option">
    <div style="width: 100%">
      <app-package-carousel></app-package-carousel>

    </div>
  </div>
</div>
