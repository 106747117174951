<!-- <div class="badge badge-warning">Know that by doing that, will create a new customer</div> -->
<div class="row justify-content-center assign-struct-container">
  <div class="col step-wrapper" [class.completed]="selectedCustomer">
    <app-search-client
      *ngIf="!selectedCustomer"
      [searchType]="'customer'"
      (client)="selectCustomer($event)"
    ></app-search-client>
    <div *ngIf="selectedCustomer" class="customer-container">
      Selected the customer {{ selectedCustomer.contactNumber }}
    </div>
  </div>
  <div
    class="col step-wrapper left-separator"
    *ngIf="selectedCustomer"
    [class.completed]="selectedStruct"
  >
    <app-search-client
      *ngIf="!selectedStruct"
      [searchType]="'business'"
      (client)="selectStruct($event)"
    ></app-search-client>
    <div *ngIf="selectedStruct" class="customer-container">
      Selected the business {{ selectedStruct.contactNumber }}
    </div>
  </div>
  <div *ngIf="selectedCustomer && selectedStruct">
    <button (click)="assignContacttoStruct()" class="btn-white">
      Assign to business
    </button>
  </div>
</div>
