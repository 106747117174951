import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ErrorModalComponent } from './error-modal/error-modal.component';
import { RestartModalComponent } from './restart-modal/restart-modal.component';
import { CustomerPlan } from '../models';
import { Observable, Subject } from 'rxjs';
import { PlanSeatElementModalComponent } from './plan-seat-element-modal/plan-seat-element-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  bsModalRef: BsModalRef;

  constructor(public modalService: BsModalService) { }

  // public checkoutModal() {
  //   const config = {
  //     animated: true,
  //     keyboard: false,
  //     ignoreBackdropClick: true,
  //     class: 'modal-dialog-centered'
  //   };
  //   this.bsModalRef = this.modalService.show(CheckoutModalComponent, config);
  // }

  public errorModal(errorMessage): void  {
    const initialState = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      message: errorMessage
    };
    this.bsModalRef = this.modalService.show(ErrorModalComponent, { class: 'modal-dialog-centered', initialState });
  }

  public restartModal(planId, interval): void  {
    const resetConfig: ModalOptions = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: true,
      class: 'modal-dialog-centered',
      initialState: {
        // planId,
        // interval
      }
    };
    this.bsModalRef = this.modalService.show(RestartModalComponent, resetConfig);
  }

  public planSeatElementModal(customerPlan: CustomerPlan, accordionToggleSubject: Subject<number>): BsModalRef {
    const config: ModalOptions = {
      animated: true,
      keyboard: false,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      initialState: {
        // customerPlan,
        // accordionToggleSubject
      }
    };

    return this.modalService.show(PlanSeatElementModalComponent, config);
  }

  public extraModal(): void {
    const config: ModalOptions = {
      animated: true,
      keyboard: true,
      ignoreBackdropClick: false,
      class: 'modal-dialog-centered',
      initialState: {
        // success,
        // reject
      }
    };
  }


  // public generalAdmissionModal() {
  //   const config = {
  //     animated: true,
  //     class: 'modal-dialog-centered'
  //   };
  //   this.bsModalRef = this.modalService.show(GeneralAdmissionModalComponent, config);
  // }

  public infoModal(): void {
  }

  // public backModal() {
  //   const infoConfig: ModalOptions = {
  //     animated: true,
  //     class: 'modal-dialog-centered'
  //   };
  //   this.modalService.show(BackModalComponent, infoConfig);
  // }

  // public justAddonsModal() {
  //   const infoConfig: ModalOptions = {
  //     animated: true,
  //     class: 'modal-dialog-centered'
  //   };
  //   this.modalService.show(JustAddonsModalComponent, infoConfig);
  // }

  // public guideModal() {
  //   const config: ModalOptions = {
  //     animated: true,
  //     keyboard: false,
  //     ignoreBackdropClick: true,
  //     class: 'modal-dialog-centered',
  //   };
  //   this.bsModalRef = this.modalService.show(GuideModalComponent, config);
  // }

  // public upgradeModal() {
  //   const config: ModalOptions = {
  //     animated: true,
  //     keyboard: false,
  //     ignoreBackdropClick: true,
  //     class: 'modal-dialog-centered',
  //   };
  //   this.bsModalRef = this.modalService.show(UpgradeModalComponent, config);
  // }

  /**
   * Checks if the navigator it's a mobile
   * @returns boolean
   */
  isMobile(): boolean {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }
}
