import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminAreaComponent } from './admin-area/admin-area.component';
import {BackofficeRoutingModule} from './backoffice-routing.module';
import {UserManagementModule} from './admin-area/user-management/user-management.module';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import { OrderManagementModule } from './admin-area/order-management/order-management.module';



@NgModule({
  declarations: [
    AdminAreaComponent
  ],
  imports: [
    CommonModule,
    BackofficeRoutingModule,
    UserManagementModule,
    OrderManagementModule,
    PaginationModule
  ]
})
export class BackofficeModule { }
