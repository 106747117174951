import { Injectable, ɵALLOW_MULTIPLE_PLATFORMS } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PackageModel, TransactionModel, UserModel } from '../../shared/models';
import { SeatPackageModel } from '../../shared/models/seat-package.model';

@Injectable({
  providedIn: 'root',
})
export class PackageSelectionService {
  private _cachedSeats: string[];
  private _cachedSeats$: BehaviorSubject<string[]>;
  private cart: SeatPackageModel[];
  private currentSelection: SeatPackageModel | null;

  currentPackage: any;
  private currentTransaction: any;

  get isCartEmpty(): boolean {
    return this.cart.length === 0;
  }

  constructor() {
    this._cachedSeats = [];
    this._cachedSeats$ = new BehaviorSubject<string[]>([]);
    this.cart = [];
    this.currentSelection = null;
    this.currentPackage = null;
    this.currentTransaction = null;
  }

  resetAll() {
    this.clearSeats();
    this.clearCart();
  }

  select(selection) {
    this._cachedSeats = this._cachedSeats.concat(selection);
    this._cachedSeats$.next(this._cachedSeats);
  }

  unselect(seat) {
    const index = this._cachedSeats.indexOf(seat);
    if (seat !== -1) {
      this._cachedSeats.splice(index, 1);
      this._cachedSeats$.next(this._cachedSeats);
    }
  }

  getSelectedSeats() {
    return this._cachedSeats$.asObservable();
  }

  clearSeats() {
    this._cachedSeats = [];
    this._cachedSeats$.next(this._cachedSeats);
  }

  confirmSeats(timeLength): boolean {
    if (!this.currentPackage) {
      throw Error('No package selected');
    } else if (this._cachedSeats.length === 0) {
      return false;
    }

    const allowMultipleAdquires = false; // Se pueden hacer varias transacciones del mismo paquete

    if (!this.currentSelection || allowMultipleAdquires) {
      // Si la cart no tiene el paquete o se pueden repetir paquetes
      const item: SeatPackageModel = {
        // Item de la cart para los asientos actuales
        listOfSeats: this._cachedSeats,
        package: this.currentPackage,
        timeLength,
      };
      this.cart.push(item); // Añadir el item a la cart
      this.currentSelection = item;
      this._cachedSeats = []; // Limpiamos el bufffer de seats
      return true;
    } else if (this.currentSelection) {
      // Actualizamos el objeto de memoria para conservar el orden de la cart
      this.currentSelection.listOfSeats = this._cachedSeats;
      this.currentSelection.timeLength = timeLength;
      // console.log(
      //   '🚀 ~ file: package-selection.service.ts ~ line 64 ~ PackageSelectionService ~ commit ~ this.cart',
      //   this.cart
      // );
      return true;
    } else {
      return false;
    }
  }

  getCart(): SeatPackageModel[] {
    return this.cart;
  }

  getCartForPackage(id): SeatPackageModel | undefined {
    //! Modificar en el caso que se puedan repetir paquetes (con diferentes term length)
    return this.cart.find((item) => item.package.id === id);
  }

  clearCart() {
    // console.log('Cleared cart');
    this.cart = [];
  }

  // Añadir los datos de currentSelection a la transacción, retorna el observable de añadir a la transacción
  addToTransaction(customer: any): Observable<any> {
    return of(undefined);
  }
}
