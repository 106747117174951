<div
  *ngIf="cp; else nopackage"
   class="container divpackage"
   (click)="setSelectedPackage()"
><div style="position: absolute;bottom: 16px; left:16px">{{cp.name.toUpperCase()}}</div></div>

<ng-template #nopackage>
  <span class="container divnopackage">
    Empty
  </span>

</ng-template>
