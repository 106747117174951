<div class="full">
  <div class="content">
    <app-topbar></app-topbar>
    <div class="dynamic-content">
      <router-outlet></router-outlet>
    </div>
  </div>
  <footer>
    <div class="d-flex align-items-right">
      <div>v{{ version }}</div>
    </div>
  </footer>
</div>
