import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { CartService } from '../digitalvenue/services/cart.service';
import { CustomerService } from './customer.service';
import { PackagesService } from './packages.service';

@Injectable({
  providedIn: 'root'
})
export class FlowcontrolService {

  currentPackageID;
  currentPackageSeatCategoryId: string;
  currentPackageName;
  currentSectionSeats;
  currentOrderId;
  private customer$: BehaviorSubject<any>; // Datos del customer


  constructor(private cartService: CartService) {
    this.customer$ = new BehaviorSubject<any>(null);
  }


  getCustomer() {
    return this.customer$.asObservable();
  }

  setCustomer(customer) {
    this.customer$.next(customer);
  }




  resetAll() {
    this.currentPackageID = null;
    this.currentPackageName = null;
    this.currentSectionSeats = null;
    this.currentOrderId = null;
    this.currentPackageSeatCategoryId = null;
    this.customer$.next(null);
  }




  persistState() {

    const customer$ = this.customer$.pipe(take(1));
    const cart$ = this.cartService.cart$.pipe(take(1));

    forkJoin([customer$, cart$]).subscribe(([customer, cart]) => {
      const persistance = {
        currentPackageID: this.currentPackageID,
        currentPackageName: this.currentPackageName,
        currentPackageSeatCategoryId: this.currentPackageSeatCategoryId,
        currentOrderId: this.currentOrderId,
        cart,
        customer
      }

      const jsonPersistance = JSON.stringify(persistance);

      localStorage.setItem("previous_state", jsonPersistance);
    });
  }


  recoverState() {
    const jsonState = localStorage.getItem("previous_state");

    if (jsonState) {
      const state = JSON.parse(jsonState);

      this.currentPackageID = state.currentPackageID || null;
      this.currentPackageName = state.currentPackageName || null;
      this.currentPackageSeatCategoryId = state.currentPackageSeatCategoryId || null;
      this.currentOrderId = state.currentOrderId || null;

      this.cartService.emitCart(state.cart || {});
      this.setCustomer(state.customer || null);

      return state;

    }
  }
}
