import { TicketManagementPortalConfiguration } from './ticket-management-portal-configuration.model';
import { InjectionToken } from '@angular/core';

export const APP_CONFIG = new InjectionToken<TicketManagementPortalConfiguration>('TicketManagementPortalConfiguration');
export const ApplicationConfiguration: TicketManagementPortalConfiguration = {
    title: 'Fulham FC',
    subtitle: 'Seat Realocation Portal',
    topbar: {
        topbarLogo: {
            src: '../../assets/img/logo-shield.png',
            alt: 'Fulham FC'
        },
        topbarLogoPrint: {
            src: ''
        },
        includeInfoIcon: true,
        includeSwitchTheme: false
    },
    includeSalesRep: {
        title: 'Your Premium Services Manager'
    },
    footer: {
        copyrightText: '2020 3D Digital Venue by Mobile Media Content (MMC).'
    }

};
