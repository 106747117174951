import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UserModel} from '../../../../shared/models';
import {PaginationModel} from '../../../shared/models/PaginationModel';
import {ResponseModel} from '../../../shared/models/ResponseModel';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private apiRoot = '/bo';

  constructor(private http: HttpClient) { }

  public getUsers(page: number = 1): Observable<PaginationModel<UserModel>> {
    const url = `${this.apiRoot}/user?page=${page}`;
    return this.http.get<PaginationModel<UserModel>>(url);
  }

  public createUser(user: UserModel): Observable<UserModel> {
    const url = `${this.apiRoot}/user`;
    return this.http.post<UserModel>(url, user);
  }

  public getUser(id: string): Observable<UserModel> {
    const url = `${this.apiRoot}/user/${id}`;
    return this.http.get<UserModel>(url);
  }

  public updateUser(user: UserModel): Observable<ResponseModel<UserModel>> {
    const url = `${this.apiRoot}/user/${user.id}`;
    return this.http.patch<ResponseModel<UserModel>>(url, user);
  }

  public deleteUser(id: number): Observable<{message: string}> {
    const url = `${this.apiRoot}/user/${id}`;
    return this.http.delete<{message: string}>(url);
  }
}
