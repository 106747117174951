<div id="main-interface" class="wrap-interface">
  <div *ngIf="isMapLoading" class="spinner position-absolute">
    <i class="fa fa-spinner fa-pulse"></i>
  </div>
  <div
    [hidden]="isView3DOpen"
    id="viewer-container"
    style="max-height: 606px"
  ></div>
  <div
    [hidden]="!isView3DOpen"
    id="viewer3d-container"
    style="max-height: 606px"
  ></div>

  <!-- MINIMAP -->
  <!-- <div class='d-none d-md-block nopadding' id="minimap">
    <app-areamap-mini [mapType]="this.app_config.mapType" [blockSelected]="blockSelected" [state]="status"></app-areamap-mini>
    <tk3d-map-mini [type]="'blockmap'" class="hidden"></tk3d-map-mini>
    <tk3d-map-mini [type]="'seatmap'" class="hidden"></tk3d-map-mini>
</div> -->

  <!-- POPPER TOOLTIP -->
  <!-- <div id="tooltip" class="popover tk3dpopover" role="tooltip" (mouseenter)="popoverMouseEnter()" (mouseleave)="popoverMouseLeave()">
    <div id="arrow" data-popper-arrow></div>
    <h3 class="popover-header"></h3>
    <div class="popover-body" (click)="open3DView(popover3dview.currentView)"></div>
</div>
 -->
</div>
