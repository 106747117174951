import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessagingService } from '../shared/modals/messaging.service';
import { NavigationService } from '../services/navigation.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isShown = false;
  constructor(
    private router: Router,
    private navigatonService: NavigationService,
    private messageService: MessagingService
  ) {}

  private handleAuthError(error: HttpErrorResponse): Observable<any> {
    console.log('Tenemos un error', error);
    // handle the auth error or rethrow
    if (error.status === 401 || error.status === 403) {
      if (this.router.url !== '/login' && !this.isShown) {
        this.isShown = true;
        this.messageService.info(
          'Oops!',
          `Your session has been expired.`,
          'Accept',
          () => {
            this.isShown = false;
            localStorage.removeItem('access_token');
            this.router.navigateByUrl('/login');
          }
        );
      }
    } else if (error.status === 503) {
      this.messageService.error(
        'Oops!',
        `Something went wrong: ${error.error.message}`,
        'Accept',
        () => {
          // this.router.navigate(["homepage"]);
          this.navigatonService.resetHistory();
        }
      );
    }
    return throwError(error);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.

    // Commented because it's used on other interceptor
    // const headerName = 'X-CSRFTOKEN';
    // const token = this.auth.getCsrfToken() as string;
    // if (token !== null && !request.headers.has(headerName)) {
    //     request = request.clone({ headers: request.headers.set(headerName, token) });
    // }
    return next
      .handle(request)
      .pipe(catchError((x) => this.handleAuthError(x)));
  }
}
