import { Injectable, Inject } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import {
  DVM_CONFIG,
  APP_CONFIG,
  DVM_CALLBACKS,
  DVM_CALLBACKS_MODEL,
} from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { FlowControlService } from '../services/flow-control.service';
import { Seat } from 'src/app/shared/models';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { PackageSelectionService } from 'src/app/package-selector/services/package-selection.service';
import { SeatSelectionService } from '../services/seat-selection.service';

@Injectable()
export class ClickSeatselectionHandler extends GeneralHandler {
  constructor(
    protected dvmService: DVMService,
    @Inject(DVM_CALLBACKS) private callbacksService: DVM_CALLBACKS_MODEL,
    private seatSelectionService: SeatSelectionService,
    private modals: ModalsService,
    private packageSelectionService: PackageSelectionService,
    private flowControl: FlowControlService,
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    @Inject(APP_CONFIG) private appConfig: DigitalvenueConfiguration
  ) {
    super('click', dvmService);
  }

  sectionClickHandler(node, viewer) {
    if (node.state === 'available') {
      const load_options = {
        venue_id: viewer.getVenueId(),
        map_id: node.id,
      };

      this.dvmService.loadMap(load_options); //Permite chaining
    }
  }

  seatClickHandler(node, viewer) {
    const action: 'select' | 'unselect' =
      node.state === 'available' ? 'select' : 'unselect';
    try {
      let seats;
      if (action === 'select') {
        seats = this.seatSelectionService.selectseat(node);
      } else if (action === 'unselect') {
        seats = this.seatSelectionService.unselectseat(node);
      }

      const effect = {
        action,
        seats,
      };

      this.callbacksService.onSeatSelection(null, effect, viewer);
    } catch (e) {
      this.callbacksService.onSeatSelection(e, null, viewer);
    }
  }

  protected handle(obj) {
    // Close popovers
    // const tooltip: HTMLElement = document.querySelector('#tooltip');
    // tooltip.removeAttribute('data-show');

    // Handle
    const viewer = obj.instance;
    const nodes = obj.nodes;
    if (nodes.length) {
      if (nodes[0].type === 'section') {
        // if (this.flowControl.suitesHash[nodes[0].id]) {
        //   this.dvmService.load3DView(nodes[0].id);
        // } else {
        //   this.sectionClickHandler(nodes[0], viewer);
        // }
        this.sectionClickHandler(nodes[0], viewer);
      } else if (nodes[0].type === 'seat') {
        this.seatClickHandler(nodes[0], viewer);
      }
    }
  }
}
