import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { PackageModel, UserModel } from '../../shared/models';
import { APP_CONFIG } from '../../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../../configuration/ticket-management-portal-configuration.model';
import { Router } from '@angular/router';
import { PackagesService } from '../../services/packages.service';
import { forkJoin } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { FlowcontrolService } from '../../services/flowcontrol.service';
import { CartService } from '../../digitalvenue/services/cart.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  user: UserModel;
  defaultColumns = 4;
  packageAvailableList: Array<any>;
  isLoading: boolean;

  constructor(
    /* tslint:disable */
    @Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
    private navigation: NavigationService,
    private flowControl: FlowcontrolService,
    private zone: NgZone,
    private packagesService: PackagesService,
    private cartService: CartService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // TODO: Quick testing until API is available
    // this.user = this.packageTestService.userTest;
    this.init();
  }

  private init(): void {
    // RESET *like new session*
    this.navigation.resetHistory();
    this.cartService.resetCart();
    this.flowControl.resetAll();
    this.flowControl.persistState();

    const packages$ = this.packagesService.getPackagesFromJSON();
    const pkgDesc$ = this.packagesService.getAllDescriptions();
    const categories$ = this.packagesService.getCategoriesFromJSON();

    this.isLoading = true;
    forkJoin([packages$, pkgDesc$, categories$]).subscribe(
      ([packages]) => {
        // Solo nos importa la respuesta del packages, los otros son para cachearlos
        this.isLoading = false;
        // @ts-ignore
        this.packageAvailableList = Object.values(
          // @ts-ignore
          packages.packages as PackageModel
        ).sort((a, b) => a.packagePosition - b.packagePosition);
      }
    );
  }

  onGoCustomerData(): void {
    this.zone.run(() => {
      this.router.navigate(['selection/packages']);
    });
    // this.router.navigate(['customer-selector']);
  }

  packageListToMap(list: Array<PackageModel>): Map<string, PackageModel> {
    const mapping = new Map<string, PackageModel>();
    for (const item of list) {
      mapping.set(item.name.replace(' ', '-').toLowerCase(), item);
    }
    return mapping;
  }
}
