<div class="content-container">
  <div id="admin-toolbar" class="container-fluid">
    <button
      routerLink="/orders-list/"
      class="btn-start btn"
      style="background: white"
    >
      Back
    </button>
    <!--    <button type='button' class='btn-start' (click)="onDelete()">Delete</button>-->
  </div>

  <div class="container">
    <form (ngSubmit)="onSubmit(orderForm)" #orderForm="ngForm">
      <div class="justify-content-center" *ngIf="order">
        <fieldset class="field-set">
          <legend style="color: white">Order</legend>
          <div class="form-content">
            <div class="form-item row">
              <div class="col-3">
                <label>Order ID</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [(ngModel)]="order.order_id"
                  name="order id"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>File ID</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [(ngModel)]="order.fileId"
                  name="file number"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Order State</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [(ngModel)]="order.status"
                  name="order state"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Created At</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [value]="orderService.prettyDate(order.createAt)"
                  name="order create"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Updated At</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [value]="orderService.prettyDate(order.updateAt)"
                  name="order create"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Associated Customer ID</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [(ngModel)]="order.customer"
                  name="customer"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row d-flex">
              <div class="col-12 pr-0 pr-0">
                <button (click)="updateOrder(order)" class="btn btn-dark">
                  Update
                </button>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset *ngFor="let pkg of order.packages; let i = index">
          <legend style="color: white">Order Package {{ pkg.id }}</legend>
          <div class="form-content">
            <div class="form-item row">
              <div class="col-3">
                <label>Package ID</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [(ngModel)]="order.packages[i].package_id"
                  name="package_id"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Order ID</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [(ngModel)]="order.packages[i].order_id"
                  name="order_id"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Created At</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [value]="orderService.prettyDate(order.packages[i].createAt)"
                  name="order-package-create"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Updated At</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [value]="orderService.prettyDate(order.packages[i].updateAt)"
                  name="order-package-update"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Seats <small>(Secutix ID List)</small></label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <input
                  type="text"
                  [value]="presentSeats(order.packages[i].seats)"
                  name="seats"
                  [readOnly]="true"
                />
              </div>
            </div>
            <div class="form-item row d-flex">
              <div class="col-12 pr-0 pr-0">
                <button
                  (click)="downloadSecutix(order.packages[i].raw_response)"
                  class="btn btn-dark"
                >
                  Download Raw Response
                </button>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset>
          <legend style="color: white">
            Sales Rep. Nº {{ order.sales_rep.username }}
          </legend>
          <div class="form-content">
            <div class="form-item row">
              <div class="col-3">
                <label>Full Name</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <label style=""
                  >{{ order.sales_rep.first_name | titlecase }}
                  {{ order.sales_rep.last_name | titlecase }}</label
                >
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Email</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <label>{{ order.sales_rep.email }}</label>
              </div>
            </div>
            <div class="form-item row">
              <div class="col-3">
                <label>Email</label>
              </div>
              <div class="col-9 pr-0 pr-0">
                <label>{{ order.sales_rep.phone }}</label>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
  </div>
</div>
