import { Component, Input, OnInit } from '@angular/core';
import { PackageModel } from '../../../shared/models';

@Component({
  selector: 'app-package-item-overview',
  templateUrl: './package-item-overview.component.html',
  styleUrls: ['./package-item-overview.component.scss'],
})
export class PackageItemOverviewComponent implements OnInit {
  @Input() selectedPackage: PackageModel;

  constructor() {}

  ngOnInit(): void {
  }
}
