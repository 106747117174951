import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { PackageModel } from '../../../shared/models';

@Component({
  selector: 'app-package-item-location',
  templateUrl: './package-item-location.component.html',
  styleUrls: ['./package-item-location.component.scss'],
})
export class PackageItemLocationComponent implements OnInit {
  @Input() selectedPackage: PackageModel;
  @Output()
  goToSeatView: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onGoViewSeatLocation() {
    this.goToSeatView.emit(true);
  }
}
