import {
  Component,
  OnInit,
  Input,
  Inject,
  HostListener,
  AfterContentInit,
} from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FlowControlService } from '../services/flow-control.service';
import { DigitalvenueConfiguration } from '../digitalvenue-configuration.model';
import { APP_CONFIG, DVM_CONFIG } from '../digitalvenue.configuration';
import { CustomerPlan } from 'src/app/shared/models';
import { DVMService } from '../services/dvm.service';
// declare var $: any;
import * as $ from 'jquery';
import { DVMConfiguration } from '../dvmconfiguration.model';
// import { Popover3dviewService } from '../services/popover-3dview.service';
import { ClickSeatselectionHandler } from '../handlers/click-seatselection.handler';
import { LoadSeatselectionHandler } from '../handlers/load-seatselection.handler';
// import { PopoverCreateHandler } from '../handlers/popover-create.handler';
// import { PopoverDestroyHandler } from '../handlers/popover-destroy.handler';
import { NavigationHandler } from '../handlers/navigation.handler';
import { MapLoaderService } from '../services/map-loader.service';

@Component({
  selector: 'app-main-interface',
  templateUrl: './main-interface.component.html',
  styleUrls: ['./main-interface.component.scss'],
  providers: [
    LoadSeatselectionHandler,
    ClickSeatselectionHandler,
    // PopoverCreateHandler,
    // PopoverDestroyHandler,
    NavigationHandler,
  ],
})
export class MainInterfaceComponent implements OnInit {
  @Input() selectedSeats: object[];
  @Input() isSelectionAllowed;
  customerPlan: CustomerPlan;
  _previewPlan: string;
  innerWidth = window.innerWidth;

  // Maps
  currentMap: any;
  selectableMapsArray: Array<object>;

  get isView3DOpen() {
    if (!this.dvmService.viewer3d || !this.dvmService.viewer3d.getViewId()) {
      return false;
    }
    return true;
  }

  get sector() {
    return null;
  }

  get seat() {
    return null;
  }

  get blockSelected() {
    return null;
  }

  /**
   * Event that detects the window size
   * @param event window resize
   */
  @HostListener('window:resize', ['$event'])
  sizeChange(event) {
    // gets the window width on reize
    this.innerWidth = window.innerWidth;
  }

  get previewPlan(): string {
    return this._previewPlan;
  }

  get hideClubPreviews() {
    if (this.dvmService.viewer) {
      return (
        this.isView3DOpen ||
        this.dvmService.viewer.getMapId() !== this.dvmConfig.map_id ||
        !this.dvmService.viewer.getMapId()
      );
    }
    return null;
  }

  get suitesTablesText() {
    const nodeId = this.dvmService.viewer3d
      ? this.dvmService.viewer3d.getViewId()
      : '';
    return this.flowControl.getSuitesLoungesExtraText(nodeId);
  }

  constructor(
    @Inject(APP_CONFIG) public dvConfig: DigitalvenueConfiguration,
    @Inject(DVM_CONFIG) public dvmConfig: DVMConfiguration,
    // public popover3dview: Popover3dviewService,
    public dvmService: DVMService,
    private userService: UserService,
    public flowControl: FlowControlService,
    private clickHandler: ClickSeatselectionHandler,
    private loadHandler: LoadSeatselectionHandler,
    // private popoverCreateHandler: PopoverCreateHandler,
    // private popoverDestroyHandler: PopoverDestroyHandler,
    private navigationHandler: NavigationHandler,
    private mapLoaderService: MapLoaderService
  ) {
    this.selectableMapsArray = this.flowControl.getAllMaps();
  }

  get isMapLoading() {
    return this.mapLoaderService.isLoading;
  }

  // popoverMouseEnter() {
  //   this.popover3dview.deleteTimer();
  // }

  // popoverMouseLeave() {
  //   this.popover3dview.setTimer();
  // }

  isRelocationAvailable(date: string) {
    const appointmentTimestamp = new Date(date).getTime();
    const now = new Date().getTime();
    return now >= appointmentTimestamp ? true : false;
  }

  closeGuideOverlay() {
    document.getElementById('guide-overlay').style.display = 'none';
    this.flowControl.guideOverlayClosed = true;
  }

  // isGuideOverlayAvailable() {
  //   return this.isRelocationAvailable(this.customerPlan.appointment_date_from) && !this.isSelectionAllowed;
  //   // return false;
  // }

  open3DView(viewId) {
    this.dvmService.load3DView(viewId);
    // const tooltip: HTMLElement = document.querySelector('#tooltip');
    // tooltip.removeAttribute('data-show');
  }

  //   public open3DView(viewId) {
  //     const loadOptions = JSON.parse(JSON.stringify(this.dvmConfig));
  //     loadOptions.view_id = viewId;
  //     loadOptions.venue_id = this.dvmConfig.venue_id;
  //     this.dvmService.viewer3d.loadView3d(loadOptions);
  // }

  ngOnInit() {
    this.dvmService.restartDVM();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewChecked() {
    let viewer;
    let viewer3d;

    if (this.dvmService.isViewerSubjectInitialized) {
      viewer = this.dvmService.viewer;
    }

    if (this.dvmService.isViewer3dSubjectInitialized) {
      viewer3d = this.dvmService.viewer3d;
    }
  }
}
