import { Injectable } from '@angular/core';
import { DVMService } from './dvm.service';
import { FlowControlService } from './flow-control.service';
import { buffer, catchError, tap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { findNode } from '@angular/compiler';

@Injectable({
  providedIn: 'root',
})

/* ISOLATED SEAT HANDLING */
export class IsolatedSeatsService {
  singleSeatsBuffer = [];
  singleSeatsAction = '';

  constructor(private dvmService: DVMService) {}

  public checkPendingIsolatedSeats() {
    // Recogemos los asientos pending el mapa
    const pendingElements = this.dvmService.viewer.getNodesByTag(
      'seat',
      'pending'
    );
    // Pasamos los asientos pending a la comprobación de isolated
    return this.checkIsolatedSeats(pendingElements);
  }

  public checkIsolatedSeats(seats, seatObject = null, action = null) {
    const isolatedSeats = [];
    for (let i = 0; i < seats.length; ++i) {
      // Recorremos los asientos recibidos
      const seat = seats[i];
      if (seat.row) {
        const row = seat.row; // Fila del asiento
        const index = row.indexOf(seat.id); // Indice de la silla en el array de asientos
        const right_id1 = row[index + 1]; // ID Asiento de la derecha del asiento recibido
        const right_id2 = row[index + 2]; // ID Asiento de 2 a la derecha del asiento recibido
        const left_id1 = row[index - 1]; // ID Asiento de la izquierda del asiento recibido
        const left_id2 = row[index - 2]; // ID Asiento de 2 a la izquierda del asiento recibido

        if (this.isAvailable(right_id1)) {
          // Si hay asiento disponible a la derecha
          if (!this.isAvailable(right_id2)) {
            // Si no hay asiento disponible 2 a la derecha
            isolatedSeats.push(right_id1); // El asiento de la derecha está aislado
          }
        }

        if (this.isAvailable(left_id1)) {
          // Si hay asiento disponible a la izquierda
          if (!this.isAvailable(left_id2)) {
            // Si no hay asiento disponible 2 a la izquierda
            isolatedSeats.push(left_id1); // El asiento de la izquierda está aislado
          }
        }
      }
    }
    // Comprobamos si hay asientos aislados y si se está asiendo la comprobación cuando se ha hecho un acción en un asiento concreto
    if (isolatedSeats.length > 0 && action != null && seatObject != null) {
      // Manejamos el isolated para ese asiento de forma unica
      this.singleSeatHandling(seatObject, action);
    }
    // Devolvemos los asientos aislados
    return isolatedSeats;
  }

  private isAvailable(id) {
    if (id) {
      const element = this.dvmService.viewer.getNodeById(id);
      return element.state === 'available';
    }
    return false; // Si no existe, no es available
  }

  /*
   * CHECK IF BUFFER NEEDS TO BE RESET
   */
  public checkResetBuffer(areaid) {
    if (this.singleSeatsBuffer.length > 0 && this.singleSeatsAction === 'add') {
      // AREAID CHANGE CONDITION
      if (areaid !== this.singleSeatsBuffer[0]['areaid']) {
        this.singleSeatsBuffer = [];
      }
    }
  }

  /*
   * RESET BUFFER
   */
  public resetBuffer() {
    this.singleSeatsBuffer = [];
    this.singleSeatsAction = '';
  }

  /*
   * TO BE EXECUTED WHEN A SEAT IS UNSELECTED AND THE ISOLATED SEATS BUFFER IS NOT EMPTY
   */
  public singleSeatHandling(seatObject, action) {
    // IF CURRENT ACTION IS NOT 'REMOVE'
    if (this.singleSeatsAction !== action) {
      if (this.singleSeatsBuffer.length > 0) {
        // IF SEAT SELECTED IS ON THE BUFFER, WE REMOVE IT
        if (
          this.dvmService.viewer.getNodeById(seatObject.id).tag === 'pending'
        ) {
          this.singleSeatsBuffer = [];
        } else {
          // IF NOT, WE SEND WARNING TO THE USER
          const errorMessage = 'IsolatedSeatsNotHandled';
          const errorText =
            'Your selection is leaving an isolated seat and could not be completed. ' +
            'Please, fix your selection by clicking the isolated seat to continue.';
          // this.modals.throwErrorModal(errorMessage, errorText);
          return throwError(new Error(errorMessage + '%%%' + errorText));
        }
        // PRINT BUFFER UPDATES ON THE MAP
        this.printBuffer();
        // this.flowControl.showLoader(false);
        // this.loaderService.removeProcess("selectseat");
        return;

        // IF BUFFER IS EMPTY, WE SET THE NEW ACTION
      } else if (this.singleSeatsBuffer.length === 0) {
        this.singleSeatsAction = action;
      }
    }

    // IF THE ISOLATED SEATS BUFFER IS EMPTY, WE ADD THE SEAT TO THE BUFFER
    if (this.singleSeatsBuffer.length <= 1) {
      this.singleSeatsBuffer.push(seatObject);
    } else {
      // IF THE BUFFER ALREADY CONTAINS TWO SEATS, WARNING IS SENT TO THE USER
      const errorMessage = 'IsolatedSeatsNotHandled';
      const errorText =
        'Your selection is leaving an isolated seat and could not be completed. ' +
        'Please, fix your selection by clicking the isolated seat to continue.';
      // this.modals.throwErrorModal(errorMessage, errorText);
      throw new Error(errorMessage + '%%%' + errorText);
    }
    // IF THE BUFFER CONTAINS TWO SEATS, THEY ARE SENT TO BE RESOLVED
    let seats;
    try {
      if (this.singleSeatsBuffer.length >= 2) {
        if (this.singleSeatsAction === 'remove') {
          seats = this.sendSingleSeatsRemoveBuffer();
        } else {
          seats = this.sendSingleSeatsAddBuffer();
        }
      }
      return seats;
    } finally {
      this.printBuffer();
      if (seats) {
        this.singleSeatsBuffer = [];
        this.dvmService.viewer.setNodesTag(
          this.dvmService.viewer.getNodesByTag('seat', 'pending'),
          'none'
        );
      }
    }
  }

  /*
   * SEND BUFFER TO BE RESOLVED WHEN 'REMOVE' ACTION IS ACTIVE
   */
  private sendSingleSeatsRemoveBuffer() {
    const seats = [];
    for (let i = 0; i < this.singleSeatsBuffer.length; ++i) {
      seats.push(this.singleSeatsBuffer[i]);
    }
    if (seats.length > 0) {
      // CHECK ISOLATED SEATS INTERNALLY
      // this.printBuffer();
      const isolatedSeats = this.checkPendingIsolatedSeats();
      if (isolatedSeats.length > 0) {
        this.dvmService.viewer.select(this.singleSeatsBuffer[1].id);
        this.singleSeatsBuffer.length = 1;
        this.printBuffer();
        const errorMessage = 'IsolatedSeatsNotHandled';
        const errorText =
          'Your selection is leaving an isolated seat and could not be completed. ' +
          'Please, fix your selection by clicking the isolated seat to continue.';
        // throw new Error(`${errorMessage}::${errorText}`);
        //! AQUI HAY QUE MANDAR EL ERROR PARA EL HANDLER
        throw new Error(errorMessage + '%%%' + errorText);
        // this.flowControl.showLoader(false);
      } else {
        return seats;
      }
    }
  }

  /*
   * SEND BUFFER TO BE RESOLVED WHEN 'ADD' ACTION IS ACTIVE
   */
  private sendSingleSeatsAddBuffer() {
    // Comprobamos si el array tiene elementos
    if (this.singleSeatsBuffer.length > 0) {
      // CHECK ISOLATED SEATS INTERNALLY
      // Acualizamos el mapa con los datos del buffer
      this.printBuffer();
      // Intentamos solucionar los isolated cogiendo los asientos pending del mapa
      const isolatedSeats = this.checkPendingIsolatedSeats();
      if (isolatedSeats.length > 0) {
        this.dvmService.viewer.unselect(this.singleSeatsBuffer[1].id);
        this.singleSeatsBuffer.length = 1;
        this.printBuffer();
        const errorMessage = 'IsolatedSeatsNotHandled';
        const errorText =
          'Your selection is leaving an isolated seat and could not be completed. ' +
          'Please, fix your selection by clicking the isolated seat to continue.';
        throw new Error(errorMessage + '%%%' + errorText);
        // this.flowControl.showLoader(false);
      } else {
        // PENDIENTE DE REVISION
        // CHECK ISOLATED SEATS EXTERNALLY
        return this.singleSeatsBuffer;
      }
    }
  }

  /*
   * PRINT BUFFER CHANGES IN THE SEATMAP
   */
  public printBuffer() {
    let action; // Acción que se ha realizado al hacer click sobre el asiento
    let counteraction; // Acción contraria de la que se ha realizado
    if (this.singleSeatsAction === 'add') {
      action = 'select';
      counteraction = 'unselect';
    } else if (this.singleSeatsAction === 'remove') {
      action = 'unselect';
      counteraction = 'select';
    }

    // Buscamos todos los elementos que estan como pending en el mapa
    const pendingElements = this.dvmService.viewer.getNodesByTag(
      'seat',
      'pending'
    );
    if (pendingElements.length > 0 && pendingElements != null) {
      // Si hay elementos pending
      for (let i = 0; i < pendingElements.length; ++i) {
        this.dvmService.viewer[counteraction](pendingElements[i]); // Hacemos la acción contraria sobre los elementos que estan como pending
        this.dvmService.viewer.setNodesTag(pendingElements[i], 'none'); // Les quitamos la etiqueta de pending
      }
    }
    if (this.singleSeatsBuffer.length > 0) {
      // Si hay asientos aislados
      for (let j = 0; j < this.singleSeatsBuffer.length; ++j) {
        this.dvmService.viewer[action](this.singleSeatsBuffer[j].id); // Hacemos la acción original sobre los elementos aislados
        this.dvmService.viewer.setNodesTag(
          this.singleSeatsBuffer[j].id,
          'pending'
        ); // Les ponemos la etiqueta de pending
      }
    }
  }
}
