import { Component, Input, OnInit } from '@angular/core';
import { PackageService } from '../../services/package.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { FlowcontrolService } from 'src/app/services/flowcontrol.service';
import { PackagesService } from 'src/app/services/packages.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-home-page-package',
  templateUrl: './home-page-package.component.html',
  styleUrls: ['./home-page-package.component.scss'],
})
export class HomePagePackageComponent implements OnInit {
  @Input('package') pkg: any;
  @Input('show-package-info') pinfo;
  imageClasses: Array<string> = [];
  image;
  imageUrl;
  packageDesc: any;

  constructor(
    private navigation: NavigationService,
    private flowControlService: FlowcontrolService,
    private packageService: PackageService,
    private packagesService: PackagesService
  ) {}

  ngOnInit(): void {
    const pkgDesc$ = this.packagesService.getPackageDescription(this.pkg.id);
    forkJoin([pkgDesc$]).subscribe(([pkgDesc]: any) => {
      this.packageDesc = pkgDesc;
    });
    // const random = Math.floor(Math.random() * this.imageClasses.length);
    // this.image = this.imageClasses[random];
    // this.imageUrl = this.pkg.image;
  }

  openDetails(): void {
    this.navigation.goTo(`/package-info/${this.pkg.id}`);
    this.flowControlService.currentPackageID = this.pkg.id;
    this.flowControlService.currentPackageSeatCategoryId =
      this.pkg.seatCategoryId;
  }

  // setRandomImage(): string {
  //   const random = Math.floor(Math.random() * this.imageClasses.length);
  //   return this.imageClasses[random];
  // }
}
