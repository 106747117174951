import {
  Component,
  OnDestroy,
  OnInit,
  EventEmitter,
  Output,
  Inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { PackagesService } from '../services/packages.service';
import { FlowcontrolService } from '../services/flowcontrol.service';
import { NavigationService } from '../services/navigation.service';
import { CartService } from '../digitalvenue/services/cart.service';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { DigitalvenueConfiguration } from '../digitalvenue/digitalvenue-configuration.model';
import { DVMConfiguration } from '../digitalvenue/dvmconfiguration.model';
import {
  APP_CONFIG,
  DVM_CONFIG,
} from '../digitalvenue/digitalvenue.configuration';
import { DVMService } from '../digitalvenue/services/dvm.service';

@Component({
  selector: 'app-user-selection',
  templateUrl: './user-selection.component.html',
  styleUrls: ['./user-selection.component.scss'],
})
export class UserSelectionComponent implements OnInit, OnDestroy {
  // State
  dvmSelectedSeats: Array<string>;
  /* tslint:disable */
  categories: Object;
  categoryID; // Displayed and selected on the view
  categoryNames: Object[];

  // @Output('selectedSeats') selectedSeatsEmitter = new EventEmitter<any>();

  // Instance
  currentPackage;
  // Cart subscription
  cartSubscription: Subscription;
  @Output() termLenghtQty = new EventEmitter<unknown>();

  constructor(
    @Inject(APP_CONFIG) public dvConfig: DigitalvenueConfiguration,
    @Inject(DVM_CONFIG) public dvmConfig: DVMConfiguration,
    private navigation: NavigationService,
    private cartService: CartService,
    private apiService: ApiService,
    private flowControl: FlowcontrolService,
    private packagesService: PackagesService,
    private routing: Router,
    public dvmService: DVMService
  ) {
    this.dvmSelectedSeats = [];
    this.categoryNames = [];
  }

  ngOnInit(): void {
    this.packagesService
      .getPackageDetails(this.flowControl.currentPackageID)
      .subscribe((data) => {
        const categoriesArray = Object.values(
          data.seasonTicket.seasonTicketItems[0].categories
        );
        this.categories = categoriesArray.reduce((prev: any, curr: any) => {
          prev[curr.id] = {
            id: curr.id,
            name: curr.name,
            amount: curr.price.amountWithoutVat,
            minQty: curr.price.minQty,
          };
          this.categoryNames.push({ id: curr.id, name: curr.name }); // Aprovechamos el bucle para rellenar los nombres
          return prev;
        }, {});
        this.categoryID = this.categoryNames[0]['id'];
        // Seteamos la quantity de la primera categoria (sera la que se ponga primera en el selector)
        // para sincronizar bien con el resto de componentes
        const { minQty } = this.categories[this.categoryID];
        this.termLenghtQty.emit(minQty);
        this.categoryNames.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
      });

    this.cartSubscription = this.cartService.cart$
      .pipe(map((cart) => cart[this.flowControl.currentPackageID]))
      .subscribe((packageCart) => {
        if (packageCart) {
          this.currentPackage = packageCart;
          if (
            !this.currentPackage.audienceSubCatId ||
            !this.currentPackage.audienceSubCatName
          ) {
            this.currentPackage.audienceSubCatId = this.categoryNames[0]['id'];
            this.currentPackage.audienceSubCatName =
              this.categoryNames[0]['name'];
          }
          const { seats } = this.currentPackage;
          if (seats) {
            this.dvmSelectedSeats = seats.map((seat) => seat.dvm.seat_id);

            if (
              this.currentPackage.packageId === '10228599874597' || // Matchday Plus (Standard) Distributable Ticket
              this.currentPackage.packageId === '10228603284752' || // Matchday Plus (Standard) Non-Distributable Ticket
              this.currentPackage.packageId === '10228599874598' || // Matchday Plus (Premium) Distributable Ticket
              this.currentPackage.packageId === '10228603284751' // Matchday Plus (Premium) Non-Distributable Ticket
            ) {
              this.currentPackage.seats.forEach((seat) => {
                seat.audienceSubCatId = this.categoryNames[0]['id'];
                seat.audienceSubCatName = this.categoryNames[0]['name'];
              });
            }
          }
        }
      });
  }

  onTermChange(categoryID: string): void {
    this.categoryID = categoryID;
    const { minQty } = this.categories[categoryID];
    if (
      this.currentPackage.packageId === '10228599874597' || // Matchday Plus (Standard) Distributable Ticket
      this.currentPackage.packageId === '10228603284752' || // Matchday Plus (Standard) Non-Distributable Ticket
      this.currentPackage.packageId === '10228599874598' || // Matchday Plus (Premium) Distributable Ticket
      this.currentPackage.packageId === '10228603284751' // Matchday Plus (Premium) Non-Distributable Ticket
    ) {
      this.currentPackage.seats.forEach((seat) => {
        seat.audienceSubCatId = this.categories[categoryID].id;
        seat.audienceSubCatName = this.categories[categoryID].name;
      });
    }
    this.termLenghtQty.emit(minQty);
    this.currentPackage.audienceSubCatId = this.categories[categoryID].id;
    this.currentPackage.audienceSubCatName = this.categories[categoryID].name;
  }

  public open3dView(seat: string): void {
    this.dvmService.load3DView(seat);
  }

  ngOnDestroy(): void {
    this.cartSubscription.unsubscribe();
  }
}
