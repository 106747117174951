<div class="table-container f-center">
  <table class="table table-grid">
    <thead>
      <tr class="trhead">
        <th class="text-normal">Minimum Purchase (Seats)</th>
        <th class="text-normal">Capacity</th>
        <th class="text-normal">Price Per Seat</th>
        <th class="text-normal">Term Lengths (Seasons)</th>
      </tr>
    </thead>
    <tbody>
      <!--    <tr class="trbody" *ngFor="let category of categoriesData">-->
      <tr class="trbody">
        <td class="text-normal">{{ categoriesData[0].minSeats }}</td>
        <td class="text-normal">{{ categoriesData[0].capacity }}</td>
        <td class="text-normal">
          {{ categoriesData[0].amountWithoutVat | currency: 'GBP' }} + VAT
        </td>
        <td class="text-normal">{{ termLength }}</td>
      </tr>
    </tbody>
  </table>
</div>
