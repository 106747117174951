import { Injectable } from '@angular/core';
import { PackageService } from '../package-selector/services/package.service';
import { FlowcontrolService } from './flowcontrol.service';
import { NavigationService } from './navigation.service';
import { PackagesService } from './packages.service';

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  constructor(
    private packagesService: PackagesService,
    private flowControl: FlowcontrolService,
    private navigation: NavigationService
  ) {}

  init() {
    const state = this.flowControl.recoverState();

    const step = this.getStep(state);

    this.navigateToStep(step);

    // console.log("Navigating to", step)

    // Request and cache the translations file
    return this.packagesService.getTranslations().toPromise();
    // No solicitamos los paquetes desde aquí porque esa petición tarda bastante en resolverse
    // y este código es blocking
  }

  getStep(state) {
    if (!state) {
      return 'homepage';
    }

    if (
      state.currentPackageID &&
      state.currentPackageName &&
      state.currentPackageSeatCategoryId &&
      state.customer &&
      state.cart &&
      state.currentOrderId
    ) {
      return 'checkout';
    } else if (
      state.currentPackageID &&
      state.currentPackageName &&
      state.currentPackageSeatCategoryId &&
      state.customer &&
      Object.values(state.cart).length
    ) {
      return 'summary';
    } else if (
      state.currentPackageID &&
      state.currentPackageName &&
      state.currentPackageSeatCategoryId &&
      state.customer
    ) {
      return 'selection/map';
    } else if (state.currentPackageID && state.currentPackageName) {
      return 'link-account';
    } else {
      return 'homepage';
    }
  }

  navigateToStep(step) {
    // Navegamos de forma progresiva para la funcionalidad del boton de back

    if (step === 'homepage') return;
    this.navigation.goTo('link-account');

    if (step === 'link-account') return;
    this.navigation.goTo('selection/map');

    if (step === 'selection/map') return;
    this.navigation.goTo('summary');

    if (step === 'summary') return;
    this.navigation.goTo('checkout');
  }
}
