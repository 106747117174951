<!-- <div id='topbar' class='col-xs-12 no-padding' [ngStyle]="{'background-color': this.app_config.colors.topbar}"> -->
<div id='topbar' class='col-xs-12 no-padding'>
  <button id='back-btn' class='pull-left mt-e icon-container' type='button' *ngIf="isStep2 || isStep3" (click)="back()">
      <div class="icon arrow-left-line2"></div>
  </button>
  <div class='info-container col-md-12 col-xs-12'>
      <div *ngIf='isStep2 || isStep3' class="info-text-container" >
        <span style="color: rgba(255,255,255,0.5);" class="info-text">Block </span>
        <span class="info-text">
          {{currentSection}}
        </span>
        <div *ngIf='isStep3 && !isSectionView()' style="display: inline;">
          <span style="color: rgba(255,255,255,0.5); margin-left: 10px;" class="info-text">Row </span>
          <span class="info-text">
            {{currentRow}}
          </span>
          <span style="color: rgba(255,255,255,0.5); margin-left: 10px;" class="info-text">Seat </span>
          <span class="info-text">
            {{currentSeat}}
          </span>
        </div>
      </div>
      <!-- <app-search *ngIf='this.app_config.includeSearch' class='search-container' [status]="status"></app-search> -->
  </div>
</div>
