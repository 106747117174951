import { Injectable } from '@angular/core';
import { DvmInterceptor } from '../digitalvenue/digitalvenue.configuration';
import { FlowcontrolService } from './flowcontrol.service';


@Injectable()
export class CartDataInterceptor implements DvmInterceptor {

  constructor(private flowControl: FlowcontrolService) { }


  addSeatsInterceptor(seats: any, cart: any): Object {
    let seatsData = seats.map(seatData => this.flowControl.currentSectionSeats[seatData.id]);

    const packageCart = cart[this.flowControl.currentPackageID];

    if (packageCart) {
      packageCart.seats = packageCart.seats.concat(seatsData);
    } else {
      cart[this.flowControl.currentPackageID] = {
        packageId: this.flowControl.currentPackageID,
        packageName: this.flowControl.currentPackageName,
        seats: seatsData
      }
    }
    return cart;
  }


  removeSeatsInterceptor(seats: any, cart: any): Object {
    const packageCart = cart[this.flowControl.currentPackageID];

    seats.forEach(seatData => {
      const index = packageCart.seats.findIndex(cartItem => cartItem.dvm.seat_id === seatData.id);
      if (index !== -1) {
        packageCart.seats.splice(index, 1);
      }
    });

    return cart;
  }


}
