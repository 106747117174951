import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackageModel, PackageSecutix, SeasonTicketItemCategory } from '../../shared/models';
import { PackagesService } from '../../services/packages.service';
import { forkJoin } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { FlowcontrolService } from '../../services/flowcontrol.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-package-options',
  templateUrl: './package-options.component.html',
  styleUrls: ['./package-options.component.scss'],
})
export class PackageOptionsComponent implements OnInit {
  @ViewChild('navOverview') navOverview: any;
  @ViewChild('navLocation') navLocation: any;
  @ViewChild('navSeatView') navSeatView: any;
  @ViewChild('navMemberBenefits') navMemberBenefits: any;
  @ViewChild('navUniqueBenefits') navUniqueBenefits: any;
  @ViewChild('navPrices') navPrices: any;
  @ViewChild('navVR') navVR: any;
  navArray: any;
  previousSelectedTarget: any;
  currentState: number;

  packageData: PackageSecutix;
  packageDesc: PackageModel;
  categories: Record<string, SeasonTicketItemCategory>;
  pkgId: string;

  constructor(
    private navigation: NavigationService,
    private flowControlService: FlowcontrolService,
    private activatedRoute: ActivatedRoute,
    private packagesService: PackagesService
  ) {}

  ngOnInit(): void {
    this.pkgId = this.flowControlService.currentPackageID ?? this.activatedRoute.snapshot.paramMap.get('id');
    this.init();
  }

  init(): void {
    // Solicitamos los datos del paquete y las descripciones
    const pkgData$ = this.packagesService.getPackageDetails(this.pkgId);
    const pkgDesc$ = this.packagesService.getPackageDescription(this.pkgId);
    // Subscripcion al completado de ambos (como Promise.all)
    forkJoin([pkgData$, pkgDesc$]).subscribe(
      ([pkgData, pkgDesc]: any[]) => {
        this.packageData = pkgData;
        this.packageDesc = pkgDesc;
        this.categories = pkgData.seasonTicket.seasonTicketItems[0].categories;
        // En caso de ejecutarse sincrono, enviamos la ejecucion al siguiente tick del event loop
        setTimeout(() => {
          this.navOverview.nativeElement.classList.add('nav-option-active');
          this.currentState = 0;
          this.previousSelectedTarget = this.navOverview.nativeElement;
          this.navArray = [
            this.navOverview,
            this.navLocation,
            this.navSeatView,
            this.navMemberBenefits,
            this.navUniqueBenefits,
            this.navPrices,
            this.navVR,
          ];
        });
      }
    );
  }

  packetName(): string {
    return this.packageData ? this.packageData.externalName.toUpperCase() : '';
  }

  onGoToSeatViewFromLocation(value: boolean): void {
    if (value) {
      if (this.previousSelectedTarget) {
        this.previousSelectedTarget.classList.remove('nav-option-active');
      }
      const element = this.navArray[2].nativeElement as HTMLElement;
      element.classList.add('nav-option-active');
      this.currentState = 2;
      this.previousSelectedTarget = element;
    }
  }

  onClick(event: MouseEvent, clicked: number): void {
    if (this.previousSelectedTarget) {
      this.previousSelectedTarget.classList.remove('nav-option-active');
    }
    (event.target as HTMLElement).classList.add('nav-option-active');

    this.currentState = clicked;
    this.previousSelectedTarget = event.target as HTMLElement;
  }

  navigate(): void {
    const elem = this.navArray[this.currentState].nativeElement;
    this.previousSelectedTarget.classList.remove('nav-option-active');
    elem.classList.add('nav-option-active');
    this.previousSelectedTarget = elem;
  }

  goSeatSelection(): void {
    // this.navigation.goTo("selection/map/" + this.packageData.id);
    this.flowControlService.currentPackageID = this.packageData.id;
    this.flowControlService.currentPackageName = this.packageData.externalName;

    this.flowControlService
      .getCustomer()
      .pipe(take(1))
      .subscribe((customer) => {
        if (customer) {
          this.navigation.goTo('selection/map'); // Navegamos al siguiente
        } else {
          this.navigation.goTo('link-account');
        }
      });
  }

}
