<!--<div class="col-3">-->
<!--  <div class="row divpackage" style="background-color: red"> aaaaaaa </div>-->
<!--  <div class="row divpackage" style="background-color: green"> </div>-->
<!--  <div class="row divpackage" style="background-color: red"> aaaaaaa</div>-->
<!--  <div class="row divpackage" style="background-color: green"></div>-->
<!--  <div class="row divpackage" style="background-color: red"> aaaaaaa</div>-->
<!--  <div class="row divpackage" style="background-color: green"> aaaaaa</div>-->
<!--  <div class="row divpackage" style="background-color: red"></div>-->
<!--  <div class="row divpackage" style="background-color: green">aaaaa</div>-->
<!--</div>-->

<app-home-page-column-item
  *ngFor="let package of availablePackages"
  [customer_package]="package"
  [show-package-info]="pinfo"
  class="item-column row"
></app-home-page-column-item>
