import { Component, OnInit } from '@angular/core';
import { PackageModel } from '../../shared/models';
import { PackagesService } from 'src/app/services/packages.service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { CartService } from 'src/app/digitalvenue/services/cart.service';

@Component({
  selector: 'app-another-package',
  templateUrl: './another-package.component.html',
  styleUrls: ['./another-package.component.scss'],
})
export class AnotherPackageComponent implements OnInit {
  defaultColumns = 4;
  packageAvailableList: Array<PackageModel>;

  constructor(
    private packagesService: PackagesService,
    private cartService: CartService,
  ) {}

  ngOnInit(): void {
    const packages$ = this.packagesService.getPackages();
    const cart$ = this.cartService.cart$.pipe(take(1));

    forkJoin([packages$, cart$]).subscribe(([packages, cart]: any) => {
      const packagesArray: PackageModel[] = Object.values(packages.packages);
      const acquiredPackages = Object.keys(cart);
      this.packageAvailableList = packagesArray.filter(
        (pkg: PackageModel) => acquiredPackages.indexOf(pkg.id) === -1
      );
    });
  }
}
