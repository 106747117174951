import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { FlowcontrolService } from './flowcontrol.service';

interface HistoryItem {
  path: string;
  extras?: NavigationExtras;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private history: any; // es un array pero la version de typescript 3.9 no tiene el '.at' que se usa en el goBack().
  private readonly localStorageHistoryKey = 'user_history';

  constructor(private router: Router, private flowControl: FlowcontrolService) {
    /* tslint:disable */
    const _history = this.getUserHistory();
    this.history = _history ?? [];
  }

  private saveHistory(item: HistoryItem[]): void {
    localStorage.setItem(this.localStorageHistoryKey, JSON.stringify(item));
  }

  private getUserHistory(): any {
    const item = localStorage.getItem(this.localStorageHistoryKey);
    return JSON.parse(item);
  }

  private stepUpHistory(item: HistoryItem): void {
    this.history.push(item);
    this.saveHistory(this.history);
  }

  private stepBackHistory(): any {
    this.history.pop();
    this.saveHistory(this.history);
    return this.history.at(-1);
  }

  // Reset history without redirecting to homepage
  resetHistory(): boolean {
    if (this.history.length) {
      // If there is history, cannot set start ("because there is a branch")
      return false; // Warn cannot be set
    }
    const entry = { path: '/homepage' };
    this.history.unshift(entry);
    this.saveHistory(this.history);
    return true;
  }

  // Reset history without redirecting to requested path
  setHistoryStart(path: string, extras?: NavigationExtras): boolean {
    if (this.history.length) {
      // If there is history, cannot set start ("because there is a branch")
      return false; // Warn cannot be set
    }
    const entry = { path, extras }; // Create history entry
    this.history.unshift(entry); // At to the start (push is valid bc empty array, but unshift protects more)
    return true;
  }

  goTo(path: string, extras?: NavigationExtras): void {
    const entry = { path, extras }; // Create history entry
    this.stepUpHistory(entry);
    this.flowControl.persistState();
    this.router.navigate([path], extras); // Navigate to requested path with provided extras
  }

  goBack(): void {
    const entry = this.stepBackHistory();
    if (entry != null) {
      this.router.navigate([entry.path], entry.extras); // Go to previous route
    } else {
      const state = JSON.parse(localStorage.getItem('previous_state'));
      if (state && state.cart) {
        for (const pkg of Object.keys(state.cart)) {
          if (state.cart[pkg].seats.length === 0) {
            delete state.cart[pkg];
            localStorage.setItem('previous_state', JSON.stringify(state));
          }
        }
      }
      this.router.navigate(['/homepage']); // if history is undefined, reset and go homepage
    }
  }
}
