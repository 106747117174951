import { Injectable } from '@angular/core';
import { switchMap, take, tap } from 'rxjs/operators';
import { DVM_CALLBACKS_MODEL } from '../digitalvenue/digitalvenue.configuration';
import { CartService } from '../digitalvenue/services/cart.service';
import { DVMService } from '../digitalvenue/services/dvm.service';
import { FlowControlService } from '../digitalvenue/services/flow-control.service';
import { MessagingService } from '../shared/modals/messaging.service';
import { ApiService } from './api.service';
import { FlowcontrolService } from './flowcontrol.service';

@Injectable({
  providedIn: 'root',
})
export class DvmCallbacksService implements DVM_CALLBACKS_MODEL {
  constructor(
    private flowControl: FlowcontrolService,
    private apiService: ApiService,
    private messagingService: MessagingService,
    private dvmService: DVMService,
    private cartService: CartService
  ) {}

  loadSeatmapHandler(viewer) {
    const map_id = viewer.getMapId();
    const { currentPackageID: packageID } = this.flowControl;
    const seatCategoryId = this.flowControl.currentPackageSeatCategoryId;

    return this.apiService
      .getSeatsAvailability(packageID, seatCategoryId, map_id)
      .pipe(
        tap((seats) => {
          const seatsIds = Object.keys(seats);
          viewer.setAvailability('seat', seatsIds);
        }),
        switchMap(() => this.cartService.cart$),
        take(1),
        tap((cart: any) => {
          const currentSeats = cart[
            this.flowControl.currentPackageID
          ].seats.map((seat) => seat.dvm.seat_id);
          viewer.select(currentSeats);
        })
      );
  }

  onSeatSelection(error, effect, viewer) {}
}
