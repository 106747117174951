import { Component, OnInit } from '@angular/core';
import { UserModel } from '../../../../shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { MessagingService } from '../../../../shared/modals/messaging.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { ResponseModel } from '../../../shared/models/ResponseModel';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  user: UserModel;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private messageService: MessagingService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.setUser(id);
  }

  private setUser(id: string): void {
    this.userService.getUser(id).subscribe(
      (user: UserModel) => {
        this.user = user;
      },
      (error: Error) => {
        console.log(error);
      }
    );
  }

  public onSubmit(form: NgForm): void {
    this.userService.updateUser(this.user).subscribe(
      (response: ResponseModel<UserModel>) => {
        this.toastr.success('User updated successfully', 'Updated');
        this.init();
      },
      (error: Error) => {
        this.toastr.success(error.message, 'Could not update');
      }
    );
  }

  public onDelete(): void {
    this.messageService.info(
      'Delete',
      `Do you really want to delete the user: ${this.user.username}`,
      'Delete',
      () => {
        this.userService.deleteUser(this.user.id).subscribe(
          (response: { message: string }) => {
            this.toastr.success(response.message, 'Deleted');
            this.router.navigate(['user-list']);
          },
          (error) => {
            this.toastr.success(error.error.message, 'Could not delete');
          }
        );
      },
      'Cancel',
      () => {}
    );
  }
}
