import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private cart: BehaviorSubject<Object>;
  
  get cart$() {
    return this.cart.asObservable();
  }

  constructor() {
    this.cart = new BehaviorSubject({});
  }


  resetCart() {
    this.cart.next({});
  }


  emitCart(cart) {
    this.cart.next(cart);
  }




}
