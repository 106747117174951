import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/services';

@Component({
  selector: 'app-search-client',
  templateUrl: './search-client.component.html',
  styleUrls: ['./search-client.component.scss'],
})
export class SearchClientComponent implements OnInit {
  // States

  customers: any[] | null = null;
  error: boolean;
  showLoader: boolean;

  @Input() searchType: 'customer' | 'business';

  @Input() criteria:
    | 'customer'
    | 'struct'
    | 'struct-customer'
    | 'struct-relay-customer';

  // Emitters
  @Output('client') clientEmitter = new EventEmitter<any>();

  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  handleSubmit(values) {
    const { contactID } = values;
    if (!contactID) {
      return;
    }

    this.showLoader = true;
    this.userService.searchClient(contactID).subscribe((result) => {
      this.showLoader = false;
      let resultsArray = result instanceof Array ? result : [result];

      if (this.criteria) {
        resultsArray = resultsArray.filter((customer) => {
          if (
            customer.type === 'INDIVIDUAL' &&
            !customer.structure &&
            this.criteria === 'customer'
          ) {
            return true;
          } else if (
            customer.type === 'RELAY' &&
            !customer.structure &&
            this.criteria === 'struct-relay-customer'
          ) {
            return true;
          } else if (
            customer.type === 'INDIVIDUAL' &&
            customer.structure &&
            this.criteria === 'struct-customer'
          ) {
            return true;
          } else if (
            customer.type === 'STRUCTURE' &&
            this.criteria === 'struct'
          ) {
            return true;
          }

          return false;
        });
      }

      if (resultsArray.length) {
        this.customers = resultsArray;
      } else {
        this.customers = null;
      }
      this.error = result.hasOwnProperty('codeError');
    });
  }

  handleParamsSubmit(values) {
    // if (Object.values(values).some((val) => !val)) {
    //   console.log(values);
    //   // Falta alguna prop por llenar, avisar a la view
    //   return;
    // }
    this.showLoader = true;
    if (this.searchType === 'customer') {
      this.userService.findClient(values).subscribe((results: Array<any>) => {
        this.showLoader = false;
        this.customers = results;
      });
    } else {
      this.userService.findStruct(values).subscribe((results: Array<any>) => {
        this.showLoader = false;
        this.customers = results;
        // console.log(this.customers);
      });
    }
  }

  emitFound(customer) {
    if (
      Object.keys(customer).length === 3 ||
      Object.keys(customer).length === 2
    ) {
      this.userService
        .searchClient(customer.contactNumber)
        .subscribe((fullCustomer) => {
          this.clientEmitter.emit(fullCustomer);
        });
    } else {
      this.clientEmitter.emit(customer);
    }
  }

  hasProp(address: any): any {
    if (Object.values(address).length > 1) {
      return address.line1;
    }
    return address;
  }
}
