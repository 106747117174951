import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-package-carousel',
  templateUrl: './package-carousel.component.html',
  styleUrls: ['./package-carousel.component.scss'],
})
export class PackageCarouselComponent implements OnInit {
  @Input('images') images: any;

  constructor() {}

  ngOnInit(): void {}
}
