import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { PackageOptionsComponent } from './package-options/package-options.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HomePageColumnComponent } from './home-page/home-page-column/home-page-column.component';
import { HomePageColumnItemComponent } from './home-page/home-page-column/home-page-column-item/home-page-column-item.component';
import {AppRoutingModule} from '../app-routing.module';
import { PackageItemOverviewComponent } from './package-options/package-item-overview/package-item-overview.component';
import { PackageItemLocationComponent } from './package-options/package-item-location/package-item-location.component';
import { PackageItemSeatViewComponent } from './package-options/package-item-seat-view/package-item-seat-view.component';
import { PackageItemMemberBenefitsComponent } from './package-options/package-item-member-benefits/package-item-member-benefits.component';
import { PackageItemUniqueBenefitsComponent } from './package-options/package-item-unique-benefits/package-item-unique-benefits.component';
import { PackageItemPricesComponent } from './package-options/package-item-prices/package-item-prices.component';
import {FormsModule} from '@angular/forms';
import { SelectionComponent } from './selection/selection.component';
import { HomePagePackageComponent } from './home-page/home-page-package/home-page-package.component';
import { AnotherPackageComponent } from './another-package/another-package.component';
import { PackageItemVrComponent } from './package-options/package-item-vr/package-item-vr.component';
import { ProjectInfoComponent } from './project-info/project-info.component';
import { VideoComponent } from './video/video.component';
import { PackageCarouselComponent } from './package-carousel/package-carousel.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { CheckoutComponent } from '../checkout/checkout.component';
import { DigitalvenueModule } from '../digitalvenue/digitalvenue.module';
import { UserSelectionComponent } from '../user-selection/user-selection.component';

@NgModule({
    declarations: [
        PackageOptionsComponent,
        HomePageComponent,
        HomePageColumnComponent,
        HomePageColumnItemComponent,
        PackageItemOverviewComponent,
        PackageItemLocationComponent,
        PackageItemSeatViewComponent,
        PackageItemMemberBenefitsComponent,
        PackageItemUniqueBenefitsComponent,
        PackageItemPricesComponent,
        SelectionComponent,
        UserSelectionComponent,
        HomePagePackageComponent,
        AnotherPackageComponent,
        PackageItemVrComponent,
        ProjectInfoComponent,
        VideoComponent,
        PackageCarouselComponent,
        CheckoutComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        AppRoutingModule,
        FormsModule,
        IvyCarouselModule,
        DigitalvenueModule
    ]
})
export class PackageSelectorModule { }
