import { Component, OnInit, OnDestroy } from '@angular/core';
import { FlowcontrolService } from '../services/flowcontrol.service';
import { ApiService } from '../services/api.service';
import { NavigationService } from '../services/navigation.service';

type OrderState = 'CLOSED' | 'OPEN' | 'ABANDONED';

interface OrderProductSeatOperation {
  audienceCatId: string;
  audienceSubCategory: string;
  audienceSubCategoryId: string;
  productId: string;
  product: string;
  productCode: string;
  sales_price: any;
  unitPriceWithoutVatTotalAmount: number;
  vatRate: number;
  unitPrice: number;
}

interface OrderProductSeat {
  movementId: string;
  operationId: string;
  seat: {
    mmc_id: string;
    seatId: string;
    row: string;
    seatCategoryId: string;
    seatNumber: string;
    contactReference: string;
    contact: {
      contactId: string;
      contactNumber: string;
      name: string;
      lastname: string;
    }
  }
  operation: OrderProductSeatOperation;
}

interface OrderProduct {
  audienceCatId: string;
  audienceSubCategory: string;
  audienceSubCategoryId: string;
  productId: string;
  product: string;
  productCode: string;
  sales_price: any;
  unitPriceWithoutVatTotalAmount: number;
  vatRate: number;
  unitPrice: number;
  seats: OrderProductSeat[]
}

interface OrderCheckoutPage {
  totalAmount: string;
  reservationAmount: string;
  reservationAmountWithoutVat: string;
  orderId: string;
  filedId: string;
  orderState: OrderState;
  currencyCode: string;
  creationDateTime: string;
  contactReference: string;
  products: OrderProduct[]
}


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  // State
  packageAcquirements;
  totalAmount;
  totalAmountWithoutVat;
  showLoader: boolean;
  customer;
  order;
  innerWidth: number;

  constructor(
    private navigation: NavigationService,
    private apiService: ApiService,
    private flowControl: FlowcontrolService,
  ) {
    this.packageAcquirements = [];
    this.showLoader = true;
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.apiService
      .getTransaction(this.flowControl.currentOrderId)
      .subscribe((order: OrderCheckoutPage) => {
        this.order = order;
        for (const p of Object.values(order.products)) {
          const amount =
            p['unitPriceWithoutVatTotalAmount'] * p['seats'].length; // para valor CON vat usar'unitPrice'
          this.packageAcquirements.push({
            packageId: p['productId'],
            packageName: p['product'],
            seats: Object.values(p['seats']).map((seatData) => ({
              id: seatData.seat.mmc_id ? seatData.seat.mmc_id : 'S_99-99-99',
              section: seatData.seat.mmc_id
                ? seatData.seat.mmc_id.split('-')[0].split('_')[1]
                : '99',
              row: seatData.seat.mmc_id
                ? seatData.seat.mmc_id.split('-')[1]
                : '99',
              seat: seatData.seat.mmc_id
                ? seatData.seat.mmc_id.split('-')[2]
                : '99',
              priceWithoutVat: seatData.operation.sales_price
                .unitPriceWithoutVatTotalAmount
                ? seatData.operation.sales_price.unitPriceWithoutVatTotalAmount
                : '0',
              contact: `${seatData.seat.contact.name} ${seatData.seat.contact.lastname}`
            })),
            totalAmount: Number(amount),
          });
        }
        this.totalAmount = order.totalAmount;
        this.totalAmountWithoutVat = order.reservationAmountWithoutVat;
        this.showLoader = false;
      });

    this.flowControl.getCustomer().subscribe((customer) => {
      this.customer = customer;
    });
  }

  endProcess() {
    this.showLoader = false;
    this.navigation.goTo('homepage');
  }

  isIpad(): boolean {
    return /iPad/i.test(window.navigator.userAgent);
  }
}
