import { Component, Input, OnInit } from '@angular/core';
import localeGB from '@angular/common/locales/en-GB';
import localeGBExtras from '@angular/common/locales/extra/en-GB';
import { PriceHash } from '../../../shared/models/customer-package/customer-package-plan.model';
import { PackageModel, PackageSecutix } from '../../../shared/models';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-package-item-prices',
  templateUrl: './package-item-prices.component.html',
  styleUrls: ['./package-item-prices.component.scss'],
})
export class PackageItemPricesComponent implements OnInit {
  @Input() selectedPackageDescription: PackageModel ;
  @Input() categories: any[];
  @Input() selectedPackageData: PackageSecutix;
  termLength: string;
  categoriesData: any[];

  constructor() {}

  ngOnInit(): void {
    registerLocaleData(localeGB, 'en-GB', localeGBExtras);
    this.init();
  }

  private init(): void {
    this.categoriesData = Object.values(this.categories)
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      .map((category: any) => {
        // if (
        //   this.selectedPackage.id === '10228599874595' || // digital brasserie
        //   this.selectedPackage.id === '10228599874596' || // physical brasserie
        //   this.selectedPackage.id === '10228599874592' || // digital gourmet
        //   this.selectedPackage.id === '10228599874591' // physical gourmet
        // ) {
        //   category.price.minQty = 2;
        // }
        this.sanitizeTermLengths(category.name);
        return {
          minSeats: this.selectedPackageData.minQuantity,
          amount: category.price.amount,
          amountWithoutVat: category.price.amountWithoutVat,
          termLength: this.termLength,
          capacity: this.selectedPackageDescription.capacity,
        };
      });
  }

  toDots(value: number | PriceHash[]): string {
    if (typeof value === 'number') {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  private sanitizeTermLengths(data: string): void {
    // lo siento
    if (!this.termLength) {
      if (data.includes('2')) this.termLength = '2';
      if (data.includes('3')) this.termLength = '3';
      if (data.includes('5')) this.termLength = '5';
    }
    if (this.termLength === '2' && data.includes('3')) this.termLength = '2, 3';
    if (this.termLength === '2' && data.includes('5')) this.termLength = '2, 5';
    if (this.termLength === '3' && data.includes('5')) this.termLength = '3, 5';
    if (this.termLength === '2, 3' && data.includes('5'))
      this.termLength = '2, 3, 5';
  }
}
