<carousel [cellWidth]="'100%'" [height]="550" [dots]="true" [objectFit]="'contain'" [arrows]="true">
  <!-- <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 1.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 2.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 3.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 4.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 5.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 6.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 7.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 8.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 9.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/Placeholder 10.png">
  </div>
  <div class="carousel-cell">
    <img src="../../../assets/img/bg-pkgs/placeholders/football-3568906_1920.jpg">
  </div> -->
  <div class="carousel-cell" *ngFor="let img of images">
    <img [src]="'../../../assets/img'+img"/>
  </div>
</carousel>
