<div>
  <div class="spinner" [style.opacity]="showLoader ? '1' : '0.6'" [style.visibility]="showLoader ? 'visible' : 'hidden'">
    <i class="fa fa-spinner fa-pulse"></i>
  </div>
  <div class="row justify-content-between">
    <div class="col-5">
      <form #searchForm="ngForm" class="search-form" (ngSubmit)="handleSubmit(searchForm.value)" (keydown)="searchForm2.resetForm()">
        <div class="col-11 row">
          <div class="pad-0 col">
            <h4 class="search-section-title">Search by Client Reference Number</h4>
            <input type="text" name="contactID" placeholder="Client Reference Number" ngModel class="search-field"/>
          </div>
          <button class="btn-white my-2 py-2 px-4 col-12">Search</button>
        </div>
      </form>
      <h5 class="options-separator"><span>OR</span></h5>
      <form #searchForm2="ngForm" class="search-form" (ngSubmit)="handleParamsSubmit(searchForm2.value)" (keydown)="searchForm.resetForm()">
        <div class="col-11 row">
          <div class="pad-0 col">
            <h4 class="search-section-title">Search by:</h4>
            <input type="text" name="firstName" placeholder="First Name" ngModel class="search-field"/>
            <input type="text" name="lastName" placeholder="Last Name" ngModel class="search-field"/>
            <input type="text" name="email" placeholder="Email" ngModel class="search-field"/>
          </div>
          <button class="btn-white my-2 py-2 px-4 col-12">Search</button>
        </div>
      </form>
    </div>
    <div class="col-5">
      <div class="customers-table-container">
        <h4 *ngIf="customers">Results</h4>
        <table class="table customers-table" *ngIf="customers">
          <thead>
          <tr>
            <th *ngIf="customers">
              Name
            </th>
            <th>
              Zip
            </th>
            <th>
              Type
            </th>
            <th>
              Role
            </th>
            <th>
              Action
            </th>

          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let customer of customers">
            <td>
              {{customer.title !== "UNDEFINED" ? customer.title : ""}} {{customer.name}} {{customer.lastname}}
            </td>
            <td>
              {{customer.address.zipCode}}
            </td>
            <td>{{customer.type}}</td>
            <td>{{customer.role}}</td>

            <td>
              <button class="btn btn-secondary" (click)="emitFound(customer)">Select</button>
            </td>
          </tr>
          </tbody>
        </table>
        <div *ngIf="error">
          <span class="alert alert-danger" role="alert">Client relay not found</span>
        </div>
      </div>
    </div>
  </div>
</div>
