<div class="row">
  <div class="col-auto text-package-option">
    <div
      class="benefit-item"
      *ngFor="let benefit of selectedPackage.benefits.unique.content"
    >
      <p class="text-normal" [innerHTML]="benefit.text"></p>
      <p
        *ngFor="let content of benefit.content"
        [innerHTML]="content.text"
        class="text-normal"
      ></p>
    </div>
  </div>
  <div class="col-auto text-package-option caption-container">
    <div *ngFor="let caption of selectedPackage.benefits.unique.captions">
      <p class="text-normal" [innerHTML]="caption.text"></p>
    </div>
  </div>
</div>
