import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PackageModel } from '../../../shared/models';

@Component({
  selector: 'app-package-item-vr',
  templateUrl: './package-item-vr.component.html',
  styleUrls: ['./package-item-vr.component.scss'],
})
export class PackageItemVrComponent implements OnInit {
  @Input() selectedPackage: PackageModel;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  public iframeUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      this.selectedPackage.iframe
    );
  }
}
