<div>
  <div class="spinner" [style.opacity]="showLoader ? '1' : '0.6'" [style.visibility]="showLoader ? 'visible' : 'hidden'">
    <i class="fa fa-spinner fa-pulse"></i>
  </div>

  <div class="">

    <div class="d-flex flex-nowrap justify-content-center">

      <form #searchForm="ngForm" class="search-form" (ngSubmit)="handleSubmit(searchForm.value)" (keydown)="searchForm2.resetForm()">
        <h6 *ngIf="searchType === 'customer';else businessTitle" class="search-section-title">Search by Client Reference Number</h6>
        <ng-template #businessTitle>
          <h6 class="search-section-title">Search by Business Reference Number</h6>
        </ng-template>
        <div class="d-flex">
          <div class="d-flex flex-wrap align-items-center">
            <input *ngIf="searchType === 'customer';else businessInput" type="text" name="contactID" placeholder="Client Reference Number" ngModel class="search-field"/>
            <ng-template #businessInput>
              <input type="text" name="contactID" placeholder="Business Reference Number" ngModel class="search-field"/>
            </ng-template>
            <button class="btn-white my-2 py-2 px-4 w-100">Search</button>
          </div>
        </div>
      </form>

      <div class="options-separator">
        <div class="line-separator"></div>
        <span>OR</span>
        <div class="line-separator"></div>
      </div>

      <form #searchForm2="ngForm" class="search-form" (ngSubmit)="handleParamsSubmit(searchForm2.value)" (keydown)="searchForm.resetForm()">
        <h6 class="search-section-title">Search by:</h6>
        <div class="d-flex flex-wrap align-items-center">
          <div *ngIf="searchType === 'customer';else businessSearch">
            <input type="text" name="firstName" placeholder="First Name" ngModel class="search-field"/>
            <input type="text" name="lastName" placeholder="Last Name" ngModel class="search-field"/>
            <input type="text" name="email" placeholder="Email" ngModel class="search-field"/>
          </div>
          <ng-template #businessSearch>
            <input type="text" name="businessName" placeholder="Business Name" ngModel class="search-field"/>
          </ng-template>
          <button class="btn-white my-2 py-2 px-4 w-100">Search</button>
        </div>
      </form>

    </div>

    <hr *ngIf="customers" class="h-line my-4">

    <div class="d-flex flex-nowrap justify-content-center">

      <div *ngIf="searchType === 'customer';else businessContainer">
        <!-- <div class="col-5"> -->
          <div class="row customers-table-container">
            <div class="col-2">
              <h6 *ngIf="customers" class="p-3">Results:</h6>
            </div>
            <div class="col-10">
              <table class="table customers-table" *ngIf="customers">
                <thead>
                  <tr>
                    <th *ngIf="customers">
                      Name
                    </th>
                    <th>
                      Postcode
                    </th>
                    <th *ngIf="searchType === 'customer'">
                      Email
                    </th>
                    <th>
                      Type
                    </th>
                    <th>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let customer of customers">
                    <td>
                      {{customer.title !== "UNDEFINED" ? customer.title : ""}} {{customer.name}} {{customer.lastname}}
                    </td>
                    <td>
                      {{customer.address.zipCode}}
                    </td>
                    <td *ngIf="searchType === 'customer'">
                      {{customer.email}}
                    </td>
                    <td>{{customer.type}}</td>
                    <td>
                      <button class="btn-white btn-secondary" (click)="emitFound(customer)">Select</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="error">
                <span class="alert alert-danger" role="alert">Contacts not found</span>
              </div>
            </div>
          </div>
        <!-- </div> -->
      </div>

      <ng-template #businessContainer class="col-5">
        <div class="row customers-table-container" >
          <div class="col-2">
            <h6 *ngIf="customers" class="p-2">Results:</h6>
          </div>
          <div class="col-10">
            <table class="table customers-table" *ngIf="customers">
              <thead>
                <tr>
                  <th *ngIf="customers">
                    Name
                  </th>
                  <th>
                    Address
                  </th>
                  <th>
                    Business Reference
                  </th>
                  <th>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let customer of customers">
                  <td>
                    {{customer.structureName || customer.structure.name}}
                  </td>
                  <td>
                    {{hasProp(customer.address)}}
                  </td>
                  <td>
                    {{customer.contactNumber}}
                  </td>
                  <td>
                    <button class="btn btn-secondary" (click)="emitFound(customer)">Select</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div *ngIf="error">
              <span class="alert alert-danger" role="alert">Contacts not found</span>
            </div>
          </div>
        </div>
      </ng-template>

    </div>

  </div>

</div>
