import { Injectable } from '@angular/core';
import { PackageModel, UserModel } from '../shared/models/';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerPlan } from '../shared/models';
import { TransactionModel } from '../shared/models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user$: BehaviorSubject<UserModel>; // Datos del usuario
  private access$: BehaviorSubject<any>; // Datos del usuario respecto API authorization
  private clientSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private apiRoot = '/api';

  // Guardar el token en localStorage y avisar a los subscriptores de nuevo usuario

  private setUserTap(toStore: boolean) {
    return tap((response: any) => {
      let access;
      if (toStore) {
        const expires_at = Date.now() + response.expires_in * 1000;
        access = { token: response.token, expires_at: expires_at };
        localStorage.setItem('access_token', JSON.stringify(access));
      } else {
        const accessJSON = localStorage.getItem('access_token');
        access = JSON.parse(accessJSON);
      }
      this.user$.next(response.user);
      this.access$.next(access);
    });
  }

  constructor(private http: HttpClient) {
    this.user$ = new BehaviorSubject<UserModel>(undefined);
    this.access$ = new BehaviorSubject<UserModel>(undefined);
  }

  // Retonar el subject del usuario como observable
  getUser(): Observable<UserModel> {
    return this.user$.asObservable();
  }

  // Retornar el subject del usuario (API) como observable
  getAccess(): Observable<any> {
    return this.access$.asObservable();
  }

  // Peticion login a la API
  login(username: string, password: string): Observable<any> {
    const endpoint = `${this.apiRoot}/auth/login`;
    const body = {
      username,
      password,
    };
    return this.http.post(endpoint, body).pipe(this.setUserTap(true));
  }

  // Peticion logout a la API
  logout() {
    const endpoint = `${this.apiRoot}/auth/logout`;
    return this.http.get(endpoint).pipe(
      tap(() => {
        localStorage.removeItem('access_token');
      })
    );
  }

  // Solicitar el usuario loggeado a la API (conociendo solo el token)
  getUserLogged() {
    // Temporal mientras no funciona auth/logged
    const endpoint = `${this.apiRoot}/auth/logged`;
    return this.http.get(endpoint).pipe(this.setUserTap(false));
  }

  assignContacttoStruct(structure, contacts) {
    const endpoint = `${this.apiRoot}/structure/${structure}/members`;
    const body = {
      contactNumbers: contacts,
    };
    return this.http.patch(endpoint, body);
  }

  searchClient(contactID) {
    const endpoint = `${this.apiRoot}/contact/${contactID}`;
    return this.http.get(endpoint).pipe(map((data) => data['response']));
  }

  findClient(filter) {
    // console.log(filter);
    const endpoint = `${this.apiRoot}/contacts`;
    return this.http.get(endpoint, { params: filter });
  }

  findStruct(filter) {
    // console.log(filter);
    const endpoint = `${this.apiRoot}/structures`;
    return this.http.get(endpoint, { params: filter });
  }

  setClient(client) {
    this.clientSubject.next(client);
  }

  get client$() {
    return this.clientSubject.asObservable();
  }
}
