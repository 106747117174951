
<!--<div class="assign-person-container p-3">-->
<!--  <div class="modal-header mb-3">-->
<!--    <h4 class="modal-title header-title pull-left">Assign seat</h4>-->
<!--    <button type="button" class="btn-close close pull-right" aria-label="Close">-->
<!--      <span aria-hidden="true" class="visually-hidden" (click)="closeModal()">&times;</span>-->
<!--    </button>-->
<!--  </div>-->
<!--  <div class="">-->
<!--    <div [ngSwitch]="step">-->

<!--      <div class="nav-button my-3" *ngIf="step" (click)="selectStep(null)">-->
<!--        Back-->
<!--      </div>-->

<!--      <div *ngSwitchCase="null" class="link-options-container">-->
<!--        <div class="link-option">-->
<!--          <p class="link-option-desc">You do not have an account?</p>-->
<!--          <div class="w-100">-->
<!--            <button class="link-option-button mb-3" (click)="selectStep('create-customer')">Sign Up</button>-->
<!--            <button class="link-option-button" (click)="selectStep('create-struct-customer')">Business Member Sign Up</button>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="link-option">-->
<!--          <p class="link-option-desc">Already have an account?</p>-->
<!--          <button class="link-option-button" (click)="selectStep('select')">Login</button>-->
<!--        </div>-->
<!--      </div>-->


<!--      <div *ngSwitchCase="'create-customer'">-->
<!--        <app-customer-form buttonText="Sign Up" (selectCustomer)="selectClient($event)"></app-customer-form>-->
<!--      </div>-->

<!--      <div *ngSwitchCase="'create-struct-customer'">-->
<!--        <app-customer-form buttonText="Sign Up" (selectCustomer)="selectClient($event)" [includeStructure]="true"></app-customer-form>-->
<!--      </div>-->

<!--      <div *ngSwitchCase="'select'">-->
<!--        <app-search-client (client)="useCustomer($event)"></app-search-client>-->
<!--      </div>-->

<!--      <div *ngSwitchCase="'edit-customer'">-->
<!--        <app-customer-form-->
<!--          buttonText="Select customer"-->
<!--          [editable]="true"-->
<!--          [initReadOnly]="true"-->
<!--          [baseCustomer]="currentCustomer"-->
<!--          (selectCustomer)="selectClient($event)"-->
<!--        ></app-customer-form>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
  <div class="modal-header">
    <button type="button" class="btn-close close pull-right" aria-label="Close">
      <span aria-hidden="true" class="visually-hidden" (click)="closeModal()">&times;</span>
    </button>
  </div>
  
<div class="content-container">

  <app-sub-header
    [header]="pageHeader"
    [color]="'header-title'"
    [overrideBack]="true"
    [includeCustomer]="false"
    [goback]="false"
    (back)="handleBack()"
  >
  </app-sub-header>

  <div *ngIf="!editCustomerPhase" class="customer-options-container m-auto"     [ngClass]="{'w-75': businessStep}">
    <div [ngSwitch]="step" class="standard-customer-options" [class.current]="!atBusinessPage">

      <div *ngSwitchCase="null" class="link-options-container">
        <div class="link-option">
          <p class="link-option-desc">Don't have an account?</p>
          <button class="btn-white link-option-button" (click)="selectStep('create-customer')">Create New Customer Account</button>
        </div>
        <div class="link-option">
          <p class="link-option-desc">Already have an account?</p>
          <button class="btn-white link-option-button" (click)="selectStep('select')">Login</button>
        </div>
        <div class="link-option">
          <p class="link-option-desc">Business Portal</p>
          <button class="btn-white link-option-button" (click)="toggleBusinessPage(true)">Business page</button>
        </div>
      </div>


      <div *ngSwitchCase="'create-customer'">
        <app-customer-form buttonText="Sign Up" (selectCustomer)="selectClient($event)"></app-customer-form>

      </div>

      <div *ngSwitchCase="'select'">
        <app-search-client [searchType]="'customer'" (client)="selectClient($event)"></app-search-client>
      </div>

      <!-- <div *ngSwitchCase="'create-business'" >
          <app-create-struct (structContactID)="handleAssignStruct($event)"></app-create-struct>
          <app-customer-form
          buttonText="Create business account"
          (selectCustomer)="createBusinessCustomer($event)"
          [includeStructure]="true"
          ></app-customer-form>
      </div> -->

    </div>

    <div [ngSwitch]="businessStep" class="struct-customer-options" [class.current]="atBusinessPage">
      <div *ngSwitchCase="null" class="link-options-container">
        <!-- <div class="link-option">
            <p class="link-option-desc">Need a new structure member?</p>
            <button class="link-option-button" (click)="selectBusinessStep('create-business-member')">Create structure account</button>
        </div> -->
        <div class="link-option">
          <p class="link-option-desc">Create new business contact account</p>
          <button class="btn-white link-option-button" (click)="selectBusinessStep('find-struct')">Create</button>
        </div>
        <!-- <div class="link-option">
            <p class="link-option-desc">Already have an account?</p>
            <button class="link-option-button" (click)="selectBusinessStep('find-struct-member')">Find customer account</button>
        </div> -->
        <div class="link-option">
          <p class="link-option-desc">Assign company to business contact account</p>
          <button class="btn-white link-option-button" (click)="selectBusinessStep('assign-struct-to-member')">Assign</button>
        </div>
        <div class="link-option">
          <p class="link-option-desc">Create new business and contact accounts</p>
          <button class="btn-white link-option-button" (click)="selectBusinessStep('create-struct')" >Create</button>
        </div>
      </div>

      <div *ngSwitchCase="'create-business-member'">
        <app-customer-form buttonText="Sign Up" (selectCustomer)="useCustomer($event)" [includeStructure]="true" [structure]="selectedStruct"></app-customer-form>
      </div>

      <div *ngSwitchCase="'find-struct'">
        <app-customer-form
          buttonText="Confirm and continue"
          [editable]="true"
          (selectCustomer)="useCustomer($event)"
          [includeStructure]="true"
        ></app-customer-form>
      </div>

      <div *ngSwitchCase="'find-struct-member'">
        <app-search-client [criteria]="'struct-customer'" (client)="selectClient($event)"></app-search-client>
      </div>

      <!-- <div *ngSwitchCase="'find-struct'">
          <app-search-client [criteria]="'struct'" (client)="handleNewStruct($event)"></app-search-client>
      </div> -->


      <div *ngSwitchCase="'assign-struct-to-member'">
        <app-assign-struct (customer)="selectClient($event)"  (structure)="handleNewStruct($event)"></app-assign-struct>
      </div>

      <div *ngSwitchCase="'create-struct'">
        <app-create-struct (struct)="handleNewStruct($event)"></app-create-struct>
      </div>
    </div>
  </div>

  <div *ngIf="editCustomerPhase" class="w-75 m-auto">
    <app-customer-form
      buttonText="Confirm and continue"
      [editable]="true"
      [initReadOnly]="true"
      [baseCustomer]="currentCustomer"
      (selectCustomer)="useCustomer($event)"
      [includeStructure]="!!currentCustomer.structureContactNumber"
      [structure]="selectedStruct"
    ></app-customer-form>
  </div>
</div>

