import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CategoryModel, PackageRelation, PackageSecutix } from '../shared/models';

type AvilablePackages = { packages: Record<string, PackageSecutix>, relations: Record<string, PackageRelation[]> };

@Injectable({
  providedIn: 'root',
})
export class PackagesService {
  constructor(private http: HttpClient) {}

  private availablePackages: AvilablePackages;
  private packageDescriptions: any;
  private packagesRelations: any;
  private categories: CategoryModel[];
  private apiRoot = '/api';
  private readonly sessionStoragePackageKey = 'packages_session';
  private readonly sessionStorageCategoryKey = 'category_session';

  // UTILITIES

  // Translations
  private translations = { secutixToMMC: {}, mmcToSecutix: {} };

  private saveOnSessionStorage(data: any, key: string): void {
    sessionStorage.setItem(key, JSON.stringify(data));
  }

  private getPackagesFromSessionStorage(): AvilablePackages {
    const data = sessionStorage.getItem(this.sessionStoragePackageKey);
    return JSON.parse(data);
  }

  private getCategoryFromSessionStorage(): any {
    const data = sessionStorage.getItem(this.sessionStorageCategoryKey);
    return JSON.parse(data);
  }

  getTranslations(): Observable<Object> {
    // const endpoint = isDevMode()
    //   ? '/assets/data/id_map_dev.json'
    //   : '/assets/data/id_map_pro.json';
    const endpoint = '/assets/data/id_map_pro.json';
    // console.log(endpoint);
    return this.http.get(endpoint).pipe(
      map((translations) => {
        this.translations.mmcToSecutix = translations;
        for (const id in translations) {
          // this.translations.mmcToSecutix = {...this.translations.mmcToSecutix, translations[id]: id};
          this.translations.secutixToMMC[translations[id]] = id;
        }
        return this.translations;
      })
    );
  }

  /**
   * @deprecated
   */
  getPackages(): Observable<AvilablePackages> {
    const endpoint = `${this.apiRoot}/packages`;
    // const endpoint = "./assets/json/demo-data.json"
    // Placeholder para el observable a retornar
    let observable: Observable<any>;
    const sessionData = this.getPackagesFromSessionStorage();
    // Comprobar si los paquetes estan cacheados
    if (this.availablePackages) {
      // Devolver los cacheados como observable completado
      observable = of(this.availablePackages);
    } else if (sessionData) {
      observable = of(sessionData).pipe(
        tap((data: AvilablePackages) => this.availablePackages = data)
      );
    } else {
      // Solicitar los paquetes a la API
      observable = this.http.get<AvilablePackages>(endpoint).pipe(
        tap((data) => (this.availablePackages = data)), // Cachear los paquetes
        tap((data) => (this.saveOnSessionStorage(data, this.sessionStoragePackageKey)))
      );
    }
    return observable;
  }

  /**
   * Devuelve lo mismo que en la peticion 'getPackages' pero procede de un json en vez de la api
   * Esto es por la desconexion de secutix. (06-08-2024)
   * @since 2.0.0
   */
  getPackagesFromJSON(): Observable<AvilablePackages> {
    const endpoint = '/assets/data/packages_and_relations_pro.json';
    return this.http.get<AvilablePackages>(endpoint).pipe(
      tap((data) => (this.availablePackages = data)), // Cachear los paquetes
      tap((data) => (this.saveOnSessionStorage(data, this.sessionStoragePackageKey)))
    );
  }

  getPackageDetails(id: string): Observable<any> {
    // console.log('Solicitando la id', id);
    // console.log('AVAILABLE PACKAGES -----> ', this.availablePackages);
    let observable: Observable<any> = null;
    // Comprobar si los paquetes estan cacheados
    if (this.availablePackages && this.availablePackages.packages) {
      // console.log(this.availablePackages.packages[id]);
      // Retornar observable completado del paquete solicitado
      observable = of(this.availablePackages.packages[id]);
    } else {
      // Solicitar paquetes (cacheandolos) y retornar observable del paquete pedido
      observable = this.getPackages().pipe(
        map((packages) => packages.packages[id])
      );
    }
    return observable;
  }

  /**
   * Devuelve las categories de la api
   * @deprecated
   */
  getCategories(): Observable<any> {
    const endpoint = `${this.apiRoot}/categories`;
    let observable;
    const sessionData = this.getCategoryFromSessionStorage();
    if (this.categories) {
      observable = of(this.categories);
    } else if (sessionData) {
      this.categories = sessionData;
      observable = of(sessionData).pipe(tap((data) => this.categories = data));
    } else {
      observable = this.http
        .get(endpoint)
        .pipe(
          tap((data: any) => (this.categories = data)),
          tap((data) => this.saveOnSessionStorage(data, this.sessionStorageCategoryKey))
        );
    }
    return observable;
  }

  /**
   * Devuelve las categorias de un JSON.
   * Esto es por la desconexion de secutix. (06-08-2024)
   * @since 2.0.0
   */
  getCategoriesFromJSON(): Observable<any> {
    const endpoint = `/assets/data/categories.json`;
    let observable;
    const sessionData = this.getCategoryFromSessionStorage();
    if (this.categories) {
      observable = of(this.categories);
    } else if (sessionData) {
      this.categories = sessionData;
      observable = of(sessionData).pipe(tap((data) => this.categories = data));
    } else {
      observable = this.http
        .get(endpoint)
        .pipe(
          tap((data: any) => (this.categories = data)),
          tap((data) => this.saveOnSessionStorage(data, this.sessionStorageCategoryKey))
        );
    }
    return observable;
  }

  getAllDescriptions(): Observable<any> {
    // const endpoint = isDevMode()
    //   ? '/assets/data/package-descriptions_dev.json'
    //   : '/assets/data/package-descriptions_pro.json';
    const endpoint = '/assets/data/package-descriptions_pro.json';
    // Pedir y cachear las descripciones
    return this.http.get(endpoint).pipe(
      tap((data) => (this.packageDescriptions = data))
      // tap((data) => console.log('descs', data))
    );
  }

  getPackageDescription(id: string): Observable<any> {
    let observable = null;
    // console.log(id);
    const mmcID = this.translations.secutixToMMC[id];
    // console.log(mmcID);
    if (this.packageDescriptions) {
      // console.log('packageDescriptions: ', this.packageDescriptions);
      // Retornar observable completado de la descripcion solicitada
      observable = of(this.packageDescriptions[mmcID]);
    } else {
      // Solicitar descripciones (y cachear) y retornar observable de la solicitada
      observable = this.getAllDescriptions().pipe(map((data) => data[mmcID]));
    }
    return observable;
  }
}
