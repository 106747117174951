<div class="content-container">
  <!-- <div class="container-fluid"> -->

  <app-sub-header header="PACKAGES" color="header-title">
    <p class="subheader-content text-center">
      “Make no mistake, the Riverside development will be a location like no
      other, a real game-changer for Fulham Football Club, our neighbourhood,
      and all of London.”
      <br /><br /><b>Shahid Khan, Fulham FC Chairman</b>
    </p>
  </app-sub-header>

  <div class="packages-container">
    <ng-container *ngIf="!isLoading; else spinnerTemplate">
      <div class="row d-flex justify-content-center">
        <app-home-page-package
          *ngFor="let pkg of packageAvailableList"
          [package]="pkg"
          [show-package-info]="true"
        >
        </app-home-page-package>
      </div>
    </ng-container>
  </div>

  <ng-template #spinnerTemplate>
    <div class="spinner">
      <i class="fa fa-spinner fa-pulse"></i>
    </div>
  </ng-template>

  <!-- </div> -->
</div>
