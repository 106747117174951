import { Component, OnInit } from '@angular/core';
import {UserModel} from '../../../../shared/models';
import {NgForm} from '@angular/forms';
import {UserService} from '../service/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessagingService} from '../../../../shared/modals/messaging.service';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  user: UserModel;

  constructor(private userService: UserService,
              private route: ActivatedRoute,
              private messageService: MessagingService,
              private toastr: ToastrService,
              private router: Router) {
    this.user = {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      type: 'sales_rep',
      username: '',
      is_active: true
    };
  }

  ngOnInit(): void {
  }

  public onSubmit(form: NgForm): void {
    this.userService.createUser(this.user).subscribe(
      (user: UserModel) => {
        this.toastr.success('User created successfully', 'Created');
        this.router.navigate(['user-list']);
      }, (error: Error) => {
        this.toastr.success(error.message, 'Could not create');
      }
    );
  }

}
