<div [ngSwitch]="currentView">
  <div
    *ngSwitchCase="null"
    class="d-flex flex-column align-items-center"
    style="text-align: center"
  >
    <form
      #customerForm="ngForm"
      class="form-group d-flex flex-column align-items-center login-form"
      (ngSubmit)="submitForm(customerForm)"
    >
      <div
        class="col-11 structure-container"
        [ngClass]="{ 'read-only': isReadOnly, 'no-bar': isReadOnly }"
        *ngIf="includeStructure"
      >
        <input
          type="text"
          placeholder="Name of the business"
          class="login-field"
          name="structureContactNumber"
          [readonly]="true"
          [ngModel]="structContactNumber"
          [disabled]="isReadOnly"
        />
        <div class="structure-actions">
          <button class="btn-white" (click)="selectView('find-struct')">
            Find Business
          </button>
          <button
            class="btn-white"
            [disabled]="!selectedStruct"
            (click)="selectView('struct-form')"
          >
            Edit Business
          </button>
        </div>
      </div>
      <div
        class="col-11"
        *ngIf="isReadOnly"
        [ngClass]="{ 'read-only': isReadOnly }"
      >
        <input
          type="text"
          placeholder="Contact Number"
          class="login-field"
          readonly
          name="contactNumber"
          [ngModel]="baseCustomer ? baseCustomer.contactNumber : null"
          [disabled]="isReadOnly"
        />
      </div>

      <div class="d-flex col-11" [ngClass]="{ 'read-only': isReadOnly }">
        <div class="mr-2">
          <select
            name="title"
            class="login-field"
            [ngModel]="baseCustomer ? baseCustomer.title : 'UNDEFINED'"
            [disabled]="isReadOnly"
            required
          >
            <option value="UNDEFINED" [disabled]="isReadOnly">
              Salutation
            </option>
            <option value="MR" [disabled]="isReadOnly">MR</option>
            <option value="MRS" [disabled]="isReadOnly">MRS</option>
            <option value="MR_AND_MRS" [disabled]="isReadOnly">MR & MRS</option>
            <option value="MISS" [disabled]="isReadOnly">MISS</option>
          </select>
        </div>
        <div class="mr-2 row-main-entry">
          <input
            type="text"
            placeholder="First Name"
            class="login-field"
            name="name"
            [readonly]="isReadOnly"
            #name="ngModel"
            [ngModel]="baseCustomer ? baseCustomer.name : null"
            [disabled]="isReadOnly"
            required
          />
          <div
            *ngIf="name.invalid && (name.dirty || name.touched)"
            class="alert"
          >
            <div *ngIf="name.errors.required">Name is required.</div>
          </div>
        </div>

        <div class="row-main-entry">
          <input
            type="text"
            placeholder="Last Name"
            class="login-field"
            name="lastname"
            [readonly]="isReadOnly"
            #lastname="ngModel"
            [ngModel]="baseCustomer ? baseCustomer.lastname : null"
            [disabled]="isReadOnly"
            required
          />
          <div
            *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)"
            class="alert"
          >
            <div *ngIf="lastname.errors.required">Lastname is required.</div>
          </div>
        </div>
      </div>
      <div class="col-11" [ngClass]="{ 'read-only': isReadOnly }">
        <input
          type="text"
          placeholder="Email"
          class="login-field"
          name="email"
          [readonly]="isReadOnly"
          [disabled]="isReadOnly"
          [pattern]="emailRegex"
          #email="ngModel"
          [ngModel]="baseCustomer ? baseCustomer.email : null"
          required
        />
        <div
          *ngIf="email.invalid && (email.dirty || email.touched)"
          class="alert"
        >
          <div *ngIf="email.errors.required">Email is required.</div>
          <div *ngIf="email.errors.pattern">Email must be valid.</div>
        </div>
      </div>
      <div class="col-11 d-flex" [ngClass]="{ 'read-only': isReadOnly }">
        <div class="mrgr-1">
          <select
            name="phonePrefix"
            class="login-field"
            [disabled]="true"
            [ngModel]="baseCustomer ? baseCustomer.phonePrefix : prefixPhone"
            required
          >
            <option [ngValue]="'UNDEFINED'" [disabled]="true">
              Country Code
            </option>
            <option [ngValue]="item[1]" *ngFor="let item of countryPhone">
              {{ item[1] }} ({{ item[0] }})
            </option>
          </select>
        </div>
        <div class="mrgr-1">
          <input
            type="text"
            placeholder="Phone Number"
            class="login-field"
            name="phone"
            [readonly]="isReadOnly"
            [pattern]="phoneRegex"
            [disabled]="isReadOnly"
            #phone="ngModel"
            [ngModel]="baseCustomer ? baseCustomer.phone : null"
            required
          />
          <div
            *ngIf="phone.invalid && (phone.dirty || phone.touched)"
            class="alert"
          >
            <div *ngIf="phone.errors.required">Phone is required.</div>
            <div *ngIf="phone.errors.pattern">Only numbers are allowed.</div>
          </div>
        </div>
        <div class="row-main-entry">
          <input
            type="date"
            placeholder="Birthdate"
            class="login-field"
            id="input-birthdate"
            name="birthdate"
            [disabled]="isReadOnly"
            #birthdate="ngModel"
            [ngModel]="baseCustomer ? baseCustomer.birthdate : 'Birthdate'"
            required
          />

          <div
            *ngIf="birthdate.invalid && (birthdate.dirty || birthdate.touched)"
            class="alert"
          >
            <div *ngIf="birthdate.errors.required">Birthdate is required.</div>
          </div>
        </div>
      </div>
      <div
        class="d-flex col-11 justify-content-around"
        [ngClass]="{ 'read-only': isReadOnly }"
      >
        <!--              line 1-->
        <div class="row-main-entry mrgr-1" ngModelGroup="address">
          <input
            type="text"
            placeholder="Address Line 1"
            class="login-field"
            name="line1"
            [readonly]="isReadOnly"
            [disabled]="isReadOnly"
            #line1="ngModel"
            [ngModel]="baseCustomer ? baseCustomer.address.line1 : null"
            required
          />
          <div
            *ngIf="line1.invalid && (line1.dirty || line1.touched)"
            class="alert"
          >
            <div *ngIf="line1.errors.required">Address is required.</div>
          </div>
        </div>
        <!--              end line 1-->
        <!--              line 2-->
        <div class="row-main-entry mrgr-1" ngModelGroup="address">
          <input
            type="text"
            placeholder="Address Line 2"
            class="login-field"
            name="line2"
            [readonly]="isReadOnly"
            [disabled]="isReadOnly"
            #line2="ngModel"
            [ngModel]="baseCustomer ? baseCustomer.address.line2 : null"
          />
          <!--                <div *ngIf="line.invalid && (line.dirty || line.touched)" class="alert">-->
          <!--                  <div *ngIf="line.errors.required">-->
          <!--                    line2 is required.-->
          <!--                  </div>-->
          <!--                </div>-->
        </div>

        <!--              end line 2-->
        <!--              line 3-->
        <div class="row-main-entry" ngModelGroup="address">
          <input
            type="text"
            placeholder="Address Line 3"
            class="login-field"
            name="line3"
            [readonly]="isReadOnly"
            [disabled]="isReadOnly"
            #line3="ngModel"
            [ngModel]="baseCustomer ? baseCustomer.address.line3 : null"
          />
          <!--                <div *ngIf="line.invalid && (line.dirty || line.touched)" class="alert">-->
          <!--                  <div *ngIf="line.errors.required">-->
          <!--                    Address is required.-->
          <!--                  </div>-->
          <!--                </div>-->
        </div>

        <!--              end line 3-->
      </div>
      <div class="col-11 d-flex" [ngClass]="{ 'read-only': isReadOnly }">
        <div class="mrgr-1 row-main-entry" ngModelGroup="address">
          <input
            type="text"
            placeholder="Town"
            class="login-field"
            name="town"
            [readonly]="isReadOnly"
            [disabled]="isReadOnly"
            #town="ngModel"
            [ngModel]="baseCustomer ? baseCustomer.address.town : null"
            required
          />
          <div
            *ngIf="town.invalid && (town.dirty || town.touched)"
            class="alert"
          >
            <div *ngIf="town.errors.required">Town is required.</div>
          </div>
        </div>
        <div class="mrgr-1" ngModelGroup="address">
          <input
            type="text"
            placeholder="Postcode"
            class="login-field"
            name="zipCode"
            [readonly]="isReadOnly"
            [disabled]="isReadOnly"
            #zipcode="ngModel"
            [ngModel]="baseCustomer ? baseCustomer.address.zipCode : null"
            required
          />
          <div
            *ngIf="zipcode.invalid && (zipcode.dirty || zipcode.touched)"
            class="alert"
          >
            <div *ngIf="zipcode.errors.required">Postcode is required.</div>
          </div>
        </div>
        <div class="">
          <!--                    <input type="text" placeholder="Country code" class="login-field" name="countryCode" [readonly]="isReadOnly" [ngModel]="baseCustomer ? baseCustomer.countryCode : null">-->
          <select
            name="countryCode"
            class="login-field"
            #ccode="ngModel"
            [disabled]="isReadOnly"
            [ngModel]="baseCustomer ? baseCustomer.countryCode : 'UNDEFINED'"
            (change)="onChangeCountry($event.target)"
            required
          >
            <option [ngValue]="'UNDEFINED'" [disabled]="true">Country</option>
            <option [value]="item[0]" *ngFor="let item of countryCode">
              {{ item[1] }}
            </option>
          </select>
          <div
            *ngIf="ccode.invalid && (ccode.dirty || ccode.touched)"
            class="alert"
          >
            <div *ngIf="ccode.errors.required">Country is required.</div>
          </div>
        </div>
      </div>

      <div class="mt-1 col-11 d-flex justify-content-end">
        <label class="d-flex align-items-center text-normal">
          <span>Opt-In.</span>
          <input
            class="TC w-auto ml-3"
            type="checkbox"
            name="optin"
            [ngModel]="
              baseCustomer ? baseCustomer.authorizations === 'full' : false
            "
            [disabled]="isReadOnly"
            [indeterminate]="
              baseCustomer ? baseCustomer.authorizations === 'partial' : false
            "
          />
        </label>
      </div>

      <div class="w-100 py-5 d-flex flex-column align-items-center">
        <hr class="h-line mb-5" />
        <div class="d-flex">
          <button
            *ngIf="editable && isReadOnly"
            type="button"
            class="btn-white mr-4"
            (click)="toggleReadOnly()"
          >
            Edit details
          </button>
          <button class="btn-white">
            {{ editable && !isReadOnly ? 'Save' : buttonText }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div *ngSwitchCase="'find-struct'">
    <app-search-client
      [criteria]="'struct'"
      (client)="selectStruct($event)"
    ></app-search-client>
  </div>

  <div *ngSwitchCase="'struct-form'">
    <app-create-struct
      [baseStruct]="selectedStruct"
      (struct)="selectStruct($event)"
    ></app-create-struct>
  </div>
</div>
