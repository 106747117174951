<div class="content-container">
  <div class="h-100 d-flex flex-column justify-content-between">
    <div>
      <app-sub-header
        [header]="packetName()"
        [goback]="'/homepage'"
        [color]="'header-title'"
      >
      </app-sub-header>

      <div
        class="d-flex flex-column align-items-center heading-container"
        style="text-align: center"
      >
        <nav class="navbar navbar-expand no-padding">
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div class="navbar-nav">
              <a
                class="nav-item nav-link navbar-text"
                (click)="onClick($event, 0)"
                style="cursor: pointer"
                #navOverview
                >Overview</a
              >
              <!-- <a class="nav-item nav-link navbar-text" (click)="onClick($event, 1)" style="cursor: pointer;" #navLocation>Location</a> -->
              <!--los private dinings no tienen 360, se quita del menu si son esos pkgs -->
              <a
                *ngIf="pkgId != '10228603284753' && pkgId != '10228603284754'"
                class="nav-item nav-link navbar-text"
                (click)="onClick($event, 6)"
                style="cursor: pointer"
                #navVR
                >360</a
              >
              <a
                class="nav-item nav-link navbar-text"
                (click)="onClick($event, 2)"
                style="cursor: pointer"
                #navSeatView
                >Sample Seat View</a
              >
              <a
                class="nav-item nav-link navbar-text"
                (click)="onClick($event, 3)"
                style="cursor: pointer"
                #navMemberBenefits
                >Core Benefits</a
              >
              <a
                class="nav-item nav-link navbar-text"
                (click)="onClick($event, 4)"
                style="cursor: pointer"
                #navUniqueBenefits
                >Unique Benefits</a
              >
              <a
                class="nav-item nav-link navbar-text"
                (click)="onClick($event, 5)"
                style="cursor: pointer"
                #navPrices
                >Prices</a
              >
            </div>
          </div>
        </nav>
        <div
          class="benefits-container mt-4 mb-5"
          *ngIf="currentState == 3 || currentState == 4"
        >
          <!-- <app-package-item-overview
            *ngIf="currentState == 0"
            [selectedPackage]="packageDesc"
          ></app-package-item-overview>
          <app-package-item-location
            (goToSeatView)="onGoToSeatViewFromLocation($event)"
            *ngIf="currentState == 1"
            [selectedPackage]="packageDesc"
          ></app-package-item-location>
          <app-package-item-seat-view
            *ngIf="currentState == 2"
            [selectedPackage]="packageDesc"
          ></app-package-item-seat-view> -->
          <app-package-item-member-benefits
            *ngIf="currentState == 3"
            [selectedPackage]="packageDesc"
          ></app-package-item-member-benefits>
          <app-package-item-unique-benefits
            *ngIf="currentState == 4"
            [selectedPackage]="packageDesc"
          ></app-package-item-unique-benefits>
          <!-- <app-package-item-prices
            *ngIf="currentState == 5"
            [selectedPackage]="packageDesc"
            [capacity]="packageCapacity"
            [categories]="categories"
          ></app-package-item-prices>
          <app-package-item-vr
            *ngIf="currentState == 6"
            [selectedPackage]="packageDesc"
          ></app-package-item-vr> -->
        </div>
      </div>

      <div class="packages-options-container mt-4 mb-5">
        <app-package-item-overview
          *ngIf="currentState == 0"
          [selectedPackage]="packageDesc"
        ></app-package-item-overview>
        <app-package-item-location
          (goToSeatView)="onGoToSeatViewFromLocation($event)"
          *ngIf="currentState == 1"
          [selectedPackage]="packageDesc"
        ></app-package-item-location>
        <app-package-item-seat-view
          *ngIf="currentState == 2"
          [selectedPackage]="packageDesc"
        ></app-package-item-seat-view>
        <!-- <app-package-item-member-benefits *ngIf="currentState==3" [selectedPackage]="packageDesc"></app-package-item-member-benefits> -->
        <!-- <app-package-item-unique-benefits *ngIf="currentState==4" [selectedPackage]="packageDesc"></app-package-item-unique-benefits> -->
        <app-package-item-prices
          *ngIf="currentState == 5"
          [selectedPackageDescription]="packageDesc"
          [categories]="categories"
          [selectedPackageData]="packageData"
        ></app-package-item-prices>
        <app-package-item-vr
          *ngIf="currentState == 6"
          [selectedPackage]="packageDesc"
        ></app-package-item-vr>
      </div>
    </div>
  </div>
</div>
