<div class="content-container">

  <app-sub-header
  [header]="'Select your seats'"
  [gohome]="true"
  [goback]="'package-info/' + currentPackage.id"
  [color]="'header-title'">
  </app-sub-header>

  <div id="general-wrap" class="mx-4 m-auto d-flex flex-column justify-content-between">

    <div id='interface-wrap' class="row">
      <div class='col-7'>
        <div id="map-interface-card">
          <app-venue-topbar></app-venue-topbar>
          <app-main-interface></app-main-interface>
        </div>
      </div>

      <div class="col col-5 mt-3">
        <div class="text-package">
          {{currentPackage.externalName | titlecase}}
        </div>
        <div>
          <div>
            <app-user-selection (termLenghtQty)="changeTermLength()"></app-user-selection>
            <!-- <app-user-selection (termLenght)="changeTermLength($event)" (selectedSeats)="getSelectedSeats($event)"></app-user-selection> -->
          </div>
        </div>
      </div>
    </div>

    <div class="my-5">
      <hr class="h-line mb-5">
      <div class="d-flex justify-content-center">
        <button (click)="goSummary()" class="btn-white btn-large" [disabled]="!areSelectedSeats">Checkout</button>
      </div>
    </div>

  </div>

</div>
