import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MapLoaderService {
  private processes: any[];

  constructor() {
    this.processes = [];
    // setInterval(() => console.log(this.processes), 4000)
  }

  get isLoading() {
    return Boolean(this.processes.length);
  }

  loadingSentence() {
    return this.processes.length;
  }

  addProcess(process: string | Array<string>) {
    this.processes = this.processes.concat(process);
  }

  removeProcess(process) {
    const index = this.processes.indexOf(process);
    if (index !== -1) {
      this.processes.splice(index, 1);
    }
  }
}
