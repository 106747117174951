import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { APP_CONFIG } from '../configuration/ticket-management-portal-configuration';
import { TicketManagementPortalConfiguration } from '../configuration/ticket-management-portal-configuration.model';
import { UserService } from '../services';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MessagingService } from '../shared/modals/messaging.service';
import { FlowControlService } from '../digitalvenue/services/flow-control.service';
import { ModalsService } from '../shared/modals/modals.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
  user_name: string;

  constructor(
    @Inject(APP_CONFIG) public app_config: TicketManagementPortalConfiguration,
    public router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.getUser().subscribe((user) => {
      if (user) {
        this.user_name = user.first_name + ' ' + user.last_name;
      } else {
        this.user_name = null;
      }
    });
  }

  logout(): void {
    this.userService.logout().subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  public isMobile(): boolean {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
