import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AddApiUrlInterceptor,
  WithCredentialsInterceptor,
  HttpTokenInterceptorMod,
} from './interceptors';
import { ModalComponent } from './modals';
import { MessagingService } from './modals/messaging.service';
import { ModalsService } from './modals/modals.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { RestartModalComponent } from './modals/restart-modal/restart-modal.component';
import { FormsModule } from '@angular/forms';
import { PlanSeatElementModalComponent } from './modals/plan-seat-element-modal/plan-seat-element-modal.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { CategoriesYearPipe } from './pipes/categories-year.pipe';
import { CustomerFormComponent } from './components/customer-form/customer-form.component';
import { SearchClientComponent } from './components/search-client/search-client.component';
import { CreateStructComponent } from './components/create-struct/create-struct.component';
import { AssignStructComponent } from './components/assign-struct/assign-struct.component';
import { AssignRelayComponent } from './components/assign-relay/assign-relay.component';

@NgModule({
  declarations: [
    ModalComponent,
    ErrorModalComponent,
    RestartModalComponent,
    PlanSeatElementModalComponent,
    SubHeaderComponent,
    CategoriesYearPipe,
    CustomerFormComponent,
    SearchClientComponent,
    CreateStructComponent,
    AssignStructComponent,
    AssignRelayComponent,
  ],
  imports: [ModalModule.forRoot(), CommonModule, FormsModule],
  exports: [
    SubHeaderComponent,
    CategoriesYearPipe,
    CustomerFormComponent,
    SearchClientComponent,
    CreateStructComponent,
    AssignStructComponent,
    AssignRelayComponent,
  ],
  entryComponents: [
    ModalComponent,
    ErrorModalComponent,
    RestartModalComponent,
    PlanSeatElementModalComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AddApiUrlInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptorMod,
      multi: true,
    },
    MessagingService,
    ModalsService,
  ],
})
export class SharedModule {}
