import { Component, OnDestroy, OnInit } from '@angular/core';
import { PackageSelectionService } from '../services/package-selection.service';
import { PackagesService } from '../../services/packages.service';
import { NavigationService } from '../../services/navigation.service';
import { FlowcontrolService } from '../../services/flowcontrol.service';
import { CartService } from '../../digitalvenue/services/cart.service';
import { SeatCart, PackageSecutix } from '../../shared/models';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SeatSelectionService } from '../../digitalvenue/services/seat-selection.service';

type CurrentPackage = PackageSecutix;

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss'],
})
export class SelectionComponent implements OnInit, OnDestroy {
  currentPackage: CurrentPackage;
  showLateralBar: boolean;
  minQtyToSelect = 1;
  areSelectedSeats = false;
  seatsOnCart: SeatCart[];
  // Cart subscription
  cartSubscription: Subscription;

  constructor(
    private flowcontrolService: FlowcontrolService,
    private navigation: NavigationService,
    private packagesService: PackagesService,
    private packageSelectionService: PackageSelectionService,
    private cartService: CartService,
    private seatSelectionService: SeatSelectionService
  ) {}

  ngOnInit(): void {
    const pkgId = this.flowcontrolService.currentPackageID;
    this.packagesService
      .getPackageDetails(pkgId)
      .subscribe((packageDetailts) => {
        this.currentPackage = packageDetailts;
        this.packageSelectionService.currentPackage = packageDetailts;
        this.minQtyToSelect = this.currentPackage.minQuantity;
      });
    this.showLateralBar = false;
    this.packageSelectionService.getSelectedSeats().subscribe((seats) => {
      this.showLateralBar = seats.length !== 0;
    });

    this.cartSubscription = this.cartService.cart$
      .pipe(map((cart) => cart[pkgId]))
      .subscribe((packageCart) => {
        if (packageCart) {
          const { seats } = packageCart;
          this.seatsOnCart = seats;
          this.areSelectedSeats = this.evaluateAreSelectedSeats();
          this.seatSelectionService.resetCountSelectedSeat = this.seatsOnCart.length;
        }
      });
  }

  evaluateAreSelectedSeats(): boolean {
    return (
      this.seatsOnCart &&
      this.seatsOnCart.length > 0 &&
      this.seatsOnCart.length >= this.minQtyToSelect
    );
  }

  changeTermLength(): void {
    this.areSelectedSeats = this.evaluateAreSelectedSeats();
  }

  goSummary(): void {
    if (this.seatSelectionService.countOfSelectedSeats < this.minQtyToSelect) {
      return;
    }
    this.navigation.goTo('summary');
  }

  ngOnDestroy(): void {
    this.seatSelectionService.resetCountSelectedSeat = 0;
  }
}
