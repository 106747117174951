import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalData } from '../models/index';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent implements ModalData {
  title: string;
  content: string;
  closeBtnName: string;
  closeFunction: any;
  acceptBtnName = 'Confirm';
  acceptFunction: any;

  constructor(public bsModalRef: BsModalRef) {
  }

  accept() {
    this.bsModalRef.hide();
    if (this.acceptFunction) {
      this.acceptFunction();
    }
  }

  close() {
    this.bsModalRef.hide();
    if (this.closeFunction) {
      this.closeFunction();
    }
  }


}

