import { Component, OnInit } from '@angular/core';
import {UserService} from '../service/user.service';
import {UserModel} from '../../../../shared/models';
import {PaginationModel} from '../../../shared/models/PaginationModel';
import {PageChangedEvent} from 'ngx-bootstrap/pagination';
import {ActivatedRoute, Router} from '@angular/router';
import {MessagingService} from '../../../../shared/modals/messaging.service';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  userList: Array<UserModel>;

  // PaginationModel varialbes
  page: number;
  totalItems: number;
  itemsPerPage = 10;
  totalPages: number;
  currentPage: number;

  constructor(private userService: UserService,
              private route: ActivatedRoute,
              private messageService: MessagingService,
              private toastr: ToastrService,
              private router: Router) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.getUsers();
  }

  private getUsers(page: number = 1): void {
    const startpage = parseInt(this.route.snapshot.queryParamMap.get('startpage'), 10);
    this.currentPage = isNaN(startpage) ? 1 : startpage;
    this.userService.getUsers(page).subscribe(
      (users: PaginationModel<UserModel>) => {
        this.userList = users.list;
        this.totalItems = users.count;
        this.totalPages = this.totalItems / this.itemsPerPage;
        document.getElementById('user-table')?.classList.remove('loading');
      }
    );
  }

  public pageChanged(event: PageChangedEvent): void {
    document.getElementById('user-table').classList.add('loading');
    this.getUsers(event.page);
  }

  public onDelete(user: UserModel, index: number): void {
    this.messageService.info(
      'Delete',
      `Do you really want to delete the user: ${user.username}`,
      'Delete',
      () => {
        this.userService.deleteUser(user.id).subscribe(
          (response: {message: string}) => {
            this.toastr.success(response.message, 'Deleted');
            this.userList.splice(index, 1);
          }, (error) => {
            this.toastr.success(error.error.message, 'Could not delete');
          }
        );
      },
      'Cancel',
      () => {
      }
    );
  }

}
