import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
  NgZone,
} from '@angular/core';
import { MessagingService } from '../shared/modals/messaging.service';
import { FlowcontrolService } from '../services/flowcontrol.service';
import { NavigationService } from '../services/navigation.service';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { PackagesService } from '../services/packages.service';
import { CustomerService } from '../services/customer.service';
import { CartService } from '../digitalvenue/services/cart.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignPersonModalComponent } from '../assign-person-modal/assign-person-modal.component';
import { UserService } from '../services';
import { Subscription } from 'rxjs';

enum PAYMENT_METHOD {
  CARD = 0,
  INSTALLMENT = 1,
  INVOICE = 2,
  NONE = 3,
}

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  // Instance variables
  cart;

  // State
  acceptedTerms: boolean;
  packageAcquirements;
  subtotal: number;
  total: number;
  showLoader: boolean;
  customer;

  // package translation
  packagesRelations = {};
  selectedTypeOption = {};
  changedIdHash = {};
  innerWidth: number;

  subscriptionList: Array<Subscription> = [];

  constructor(
    private navigation: NavigationService,
    private customerService: CustomerService,
    private packagesService: PackagesService,
    private cartService: CartService,
    private bsModalService: BsModalService,
    private flowControl: FlowcontrolService,
    private messageService: MessagingService,
    private userService: UserService,
    private zone: NgZone
  ) {
    this.packageAcquirements = [];
    this.showLoader = false;
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((sub) => sub.unsubscribe());
  }

  logger(e): void {
    // console.log(e);
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.flowControl.getCustomer().subscribe((customer) => {
      this.customer = customer;
    });

    const packages$ = this.packagesService.getPackages();

    const subs = packages$
      .pipe(
        switchMap((packages) => {
          return this.cartService.cart$.pipe(
            tap((cart) => (this.cart = cart)),
            map((cart) => {
              // console.log('La cart a procesar es', cart);

              return Object.values(cart).map((cartItem: any) => {
                const packageDetails = packages.packages[cartItem.packageId];
                // console.log(packageDetails);
                let pkgName = packageDetails.internalName.split('-')[0];
                if (pkgName.includes('Non')) {
                  pkgName = pkgName.split('Non')[0];
                } else if (pkgName.includes('Distributable Ticket')) {
                  pkgName = pkgName.split('Distributable Ticket')[0];
                }
                pkgName = pkgName.trim();
                if (
                  packages.relations[pkgName] &&
                  packages.relations[pkgName].length > 1
                ) {
                  this.packagesRelations[pkgName] = packages.relations[pkgName];
                  this.selectedTypeOption[pkgName] = packageDetails.id;
                }
                const categories = packageDetails.seasonTicket.seasonTicketItems[0].categories;
                const categoriesList = Object.values(categories);
                const seats = [];
                let totalAmount = 0;

                cartItem.seats.forEach((seatData) => {
                  const categoryId = seatData.audienceSubCatId || cartItem.audienceSubCatId;
                  const amount = categories[categoryId].price?.amount;
                  const seat = {
                    area: seatData.dvm.section_id.split('_')[1],
                    row: seatData.row,
                    seat: seatData.number,
                    id: seatData.dvm.seat_id,
                    physicalSeatId: seatData.physicalSeatId,
                    categoryId,
                    amount,
                  };
                  seats.push(seat);
                  totalAmount += amount;
                });

                return {
                  packageId: cartItem.packageId,
                  packageName: cartItem.packageName,
                  audienceSubCatId: cartItem.audienceSubCatId,
                  audienceSubCatName: cartItem.audienceSubCatName,
                  seatCategoryId: cartItem.seats[0].seatCategoryId,
                  categories: categoriesList,
                  shortName: pkgName,
                  seats,
                  totalAmount,
                };
              });
            })
          );
        })
      )
      .subscribe((packageAcquirements) => {
        this.packageAcquirements = packageAcquirements;
        this.firstSeatAssignation(this.packageAcquirements);
        // console.log(this.packageAcquirements);

        // console.log('packageAcquirements: ', this.packageAcquirements);
        this.subtotal = this.packageAcquirements.reduce(
          (prev, curr) => prev + curr.totalAmount,
          0
        );
        this.total = this.subtotal;
      });
    this.subscriptionList.push(subs);
  }

  addMorePackages(): void {
    this.navigation.goTo('selection/packages');
  }

  createOrder(): void {
    if (this.packageAcquirements.length <= 0) {
      return;
    }
    this.showLoader = true;

    globalThis.onbeforeunload = this.beforeUnload;
    this.customerService
      .createTransaction(this.changedIdHash)
      .subscribe((response) => {
        this.navigation.goTo('checkout');
        globalThis.onbeforeunload = null;
      });
  }

  @HostListener('window:beforeunload', ['$event'])
  private beforeUnload(event): any {
    event.preventDefault();
    event.returnValue =
      'Are you sure you want to exit? Your order is still processing';
    return event;
  }

  removePackage(packageId): void {
    this.cartService.cart$.pipe(take(1)).subscribe((cart) => {
      delete cart[packageId];
      this.cartService.emitCart(cart);
    });
  }

  private firstSeatAssignation(packages: Array<any>): void {
    for (const p of packages) {
      // console.log(this.customer);
      p.seats[0].assignedClient = this.customer;
      this.cart[p.packageId].seats[0].assignedClient = this.customer;
    }
  }

  public removeAssign(packageId, seat, acquirement): void {
    this.messageService.info(
      'Remove Assignment',
      `Do you really want to unassign client to this seat`,
      'Accept',
      () => {
        const cartPackage = this.cart[packageId];
        const packageSeat = cartPackage.seats.find(
          (cartSeat) => cartSeat.physicalSeatId === seat.physicalSeatId
        );
        delete packageSeat.assignedClient;
        delete seat.assignedClient;
      },
      'Cancel',
      () => {}
    );
  }

  assignSeat(packageId, seat, acquirement): void {
    const initialState = {
      businessStep: null,
    };
    const modalRef = this.bsModalService.show(AssignPersonModalComponent, {
      class: 'modal-xl',
      initialState,
    });

    modalRef.onHide.subscribe((data) => {
      // console.log('Hemos recibido', data);
      const client = modalRef.content.selectedClient;
      if (client) {
        const error = false;
        // for (const s of this.cart[packageId].seats) {
        //   if (
        //     s.assignedClient &&
        //     s.assignedClient.contactNumber === client.contactNumber
        //   ) {
        //     error = true;
        //     this.messageService.info(
        //       'Duplicated customer',
        //       `the client has already been assigned a seat`,
        //       'Accept',
        //       () => {},
        //       'Cancel',
        //       () => {}
        //     );
        //   }
        // }
        // console.log(error);
        if (!error) {
          seat.assignedClient = client;
          // console.log('La cart que tenemos guardada es', this.cart);
          const cartPackage = this.cart[packageId];
          const packageSeat = cartPackage.seats.find(
            (cartSeat) => cartSeat.physicalSeatId === seat.physicalSeatId
          );
          packageSeat.assignedClient = client;
        }
      }
    });
  }

  handleSeatCategoryChange(acquirement, seat, seatCategoryId): void {
    const packageSeats = this.cart[acquirement.packageId].seats;
    const currentSeat = packageSeats.find((s) => s.dvm.seat_id === seat.id);
    currentSeat.audienceSubCatId = seatCategoryId;

    this.cartService.emitCart(this.cart);
  }

  checkAllSeatsAssigned(): boolean {
    let response = true;
    if (this.packageAcquirements) {
      for (const c of this.packageAcquirements) {
        for (const s of c.seats) {
          if (!s.assignedClient) {
            response = false;
          }
        }
      }
    } else {
      response = false;
    }
    return response;
  }

  changePackageType(event: any, pkgName: string): void {
    // console.log('old -> ', this.packageAcquirements);
    const cartClone = JSON.parse(JSON.stringify(this.packageAcquirements));
    for (const p of cartClone) {
      if (p.packageName === pkgName) {
        this.changedIdHash[p.packageId] = event.target.value;
        // p.packageId = event.target.value;
      }
    }
    this.packageAcquirements = cartClone;
  }

  // this.packageAcquirements.map(packageAdquirement => {

  //   const a = {
  //     "packageId": packageAdquirement.id,
  //     "customer": 101537194568,
  //     "audienceSubCatId": 101536524239,
  //     "seatCategoryId": "101536520275",
  //     "seatList": ["101476737131"]
  //   }

  // })
}
