<!--<div id="general-wrap" style="background-color: #0FA6E5">-->
<!--  <div id="interface-wrap" class="row align-items-center justify-content-center">-->
<!--    <div class="col">-->
<!--      <div id="map-interface-card" style="width: 100%">-->
<!--        <div id="main-interface" class="wrap-interface">-->
<!--          <div id="viewer3d-container">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<app-preview-landing></app-preview-landing>-->
<div style="width: 100%;">
  <div id="general-wrap">
    <div id="interface-wrap" class="row align-items-center justify-content-center">
      <div class="col">
        <div id="map-interface-card" style="width: 100%">
          <div id="main-interface" class="wrap-interface">
            <div id="viewer3d-container" style="height: inherit">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
