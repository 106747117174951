<div
  *ngIf="packageDesc"
  class="package"
  [ngClass]="image"
  [style.background-size]="'cover'"
  [style.background-image]="
    'url(../../../../assets/img' + packageDesc?.package_image + ')'
  "
  (click)="openDetails()"
>
  <!-- <div class="package" [ngClass]="image" (click)="openDetails()"> -->
  <div
    style="position: absolute; width: 100%; bottom: 12px; text-align: center"
  >
    {{ pkg.externalName }}
  </div>
</div>
