import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, of, throwError } from 'rxjs';
import { map, take, concatMap, catchError } from 'rxjs/operators';
import { CartService } from '../digitalvenue/services/cart.service';
import { FlowcontrolService } from './flowcontrol.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private apiRoot = '/api';

  constructor(
    private http: HttpClient,
    private cartService: CartService,
    private flowControl: FlowcontrolService
  ) {}

  getStructureTypes() {
    return this.http
      .get(`${this.apiRoot}/structure-types`)
      .pipe(map((data) => data['response']));
  }

  createCustomer(customer) {
    let endpoint = `${this.apiRoot}/contact`;
    if (customer.structureContactNumber) {
      endpoint = `${this.apiRoot}/contact-structure`;
    }
    return this.http
      .post(endpoint, customer)
      .pipe(map((data) => data['response']));
  }

  editCustomer(customer) {
    let endpoint = `${this.apiRoot}/contact`;
    if (customer.structureContactNumber) {
      endpoint = `${this.apiRoot}/contact-structure`;
    }
    return this.http
      .patch(endpoint, customer)
      .pipe(map((data) => data['response']));
  }

  createStructure(struct) {
    const endpoint = `${this.apiRoot}/structure`;
    return this.http
      .post(endpoint, struct)
      .pipe(map((data) => data['response']));
  }

  updateStruct(struct) {
    const endpoint = `${this.apiRoot}/structure`;
    return this.http
      .patch(endpoint, struct)
      .pipe(map((data) => data['response']));
  }

  createTransaction(changedIdHash: any) {
    const endpoint = `${this.apiRoot}/order`;
    const close_endpoint = `${this.apiRoot}/close-order`;

    const cart$ = this.cartService.cart$.pipe(take(1));
    const customer$ = this.flowControl.getCustomer().pipe(take(1));

    return forkJoin([cart$, customer$]).pipe(
      concatMap(([cart, customerData]: any) => {
        const { contactId: customer, contactNumber } = customerData;
        // console.log('la cart para la que haremos el proceso es', cart);
        // console.log(changedIdHash);
        const payloads = Object.values(cart).map((cartItem: any) => ({
          packageId: changedIdHash[cartItem.packageId] ?? cartItem.packageId,
          customer,
          audienceSubCatId: cartItem.audienceSubCatId,
          seatCategoryId: cartItem.seats[0].seatCategoryId,
          seatList: cartItem.seats.map((seat) => ({
            id: seat.physicalSeatId,
            audienceSubCatId: seat.audienceSubCatId, // Allows to overwrite the general audience
            assignedCustomerId: seat.assignedClient?.contactId,
          })),
        }));
        const [createOrderPayload, ...editOrderPayloads] = payloads;

        // console.log(payloads);
        return this.http.post(endpoint, createOrderPayload).pipe(
          concatMap((response: any) => {
            const { orderId: order } = response;
            this.flowControl.currentOrderId = order;

            if (!editOrderPayloads.length) {
              return of(response);
            }
            const editOrderRequests = editOrderPayloads.map((payload) => {
              return this.http.post(endpoint, payload, {
                params: { order },
              });
            });

            return forkJoin(editOrderRequests);
          }),
          concatMap((response: any) =>
            this.http.post(close_endpoint, {
              customer: contactNumber,
              orderId:
                response instanceof Array
                  ? response[0].orderId
                  : response.orderId,
              sectionList: Object.values(cart).map((cartItem: any) =>
                cartItem.seats.map((seat) => seat.blockId)
              ),
            })
          )
        );
      })
    );
  }
}
