import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CustomerService } from '../services/customer.service';
import { FlowcontrolService } from '../services/flowcontrol.service';
import { NavigationService } from '../services/navigation.service';

@Component({
  selector: 'app-link-account',
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.scss'],
})
export class LinkAccountComponent implements OnInit {
  pageHeader = 'Select Account Option';

  step: 'create-customer' | 'select' | 'create-business' | null = null;
  businessStep:
    | 'create-business-member'
    | 'find-stuct'
    | 'assign-struct-to-member'
    | 'create-struct'
    | 'assign-struct-to-relay'
    | 'select-customer-for-structure'
    | 'assigning'
    | null = null;

  editCustomerPhase = false;

  atBusinessPage = false;

  selectedStruct;

  currentCustomer;

  constructor(
    private navigation: NavigationService,
    private activatedRoute: ActivatedRoute,
    private flowControl: FlowcontrolService
  ) {}

  ngOnInit(): void {
    this.flowControl
      .getCustomer()
      .pipe(take(1))
      .subscribe((customer) => {
        if (customer) {
          this.currentCustomer = customer;
          this.editCustomerPhase = true;
          this.flowControl.setCustomer(null);
        }
        this.flowControl.persistState();
      });
  }

  goToMethod(route: string) {
    this.navigation.goTo(route, { relativeTo: this.activatedRoute });
  }

  handleBack() {
    if (this.editCustomerPhase) {
      this.editCustomerPhase = false;
      this.currentCustomer = null;
    } else if (this.atBusinessPage && this.businessStep) {
      this.businessStep = null;
      this.pageHeader = 'Business Page'; //! incoherencia
    } else if (this.atBusinessPage && !this.businessStep) {
      this.atBusinessPage = false;
      this.pageHeader = 'Customer Account Options';
    } else if (!this.atBusinessPage && this.step) {
      this.step = null;
      this.pageHeader = 'Customer Account Options';
    } else {
      this.navigation.goBack();
    }
  }

  selectStep(step) {
    this.step = step;

    if (step === 'create-customer') {
      this.pageHeader = 'Create New Customer';
    } else if (step === 'select') {
      this.pageHeader = 'Find Customer Account';
    }
  }

  selectBusinessStep(step) {
    this.businessStep = step;

    if (step === 'create-business-member') {
      this.pageHeader = 'Assign Customer - Create Business Member';
    } else if (step === 'assign-struct-to-member') {
      this.pageHeader = 'Assign Customer - Assign Customer to Business';
    } else if (step === 'create-struct') {
      this.pageHeader = 'Assign Customer - Create Business';
    }
  }

  toggleBusinessPage(isOpen?: boolean) {
    this.atBusinessPage = isOpen ?? !this.atBusinessPage;
    this.pageHeader = this.atBusinessPage
      ? 'Business Account Options' //! incoherencia
      : 'Customer Account Options';
  }

  // -SELECT- STEP

  selectClient(client) {
    this.currentCustomer = client;
    this.editCustomerPhase = true;
  }

  // -CREATE STRUCT- STEP
  createBusinessCustomer(struct) {}

  // -EDIT CUSTOMER (use customer)-

  useCustomer(customer) {
    this.flowControl.setCustomer(customer); // Seleccionamos el user
    this.navigation.goTo('selection/map'); // Navegamos al siguiente
  }

  handleNewStruct(struct) {
    this.selectedStruct = struct;
    this.businessStep = 'select-customer-for-structure';
  }

  handleNewStructAndCustomer(struct) {
    this.selectedStruct = struct;
    // this.businessStep = 'create-business-member';
    this.businessStep = 'create-business-member';
  }

  selectedClientToAssignStruct(client): void {
    this.currentCustomer = client;
    this.editCustomerPhase = false;
    this.businessStep = 'assigning';
  }
}
