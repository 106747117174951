<div class="content-container">
  <div class="mrgt-10 text-center d-flex flex-column align-items-center">
    <div class="login-wrapper">
      <div class="mb-5">
        <p class="header-title mb-4">LOG IN</p>
        <p class="text-normal">London's original football club has a new world-class matchday experience.</p>
        <p class="text-normal">Welcome to The Riverside.</p>
      </div>
      <div>
        <form #LoginForm="ngForm" (ngSubmit)="login()">
          <div class="form">
            <input class="my-2" type="text" id="InAccountId" [(ngModel)]="username" name="username" placeholder="Username" required>
            <input class="my-2" type="password" id="InPassword" [(ngModel)]="password" name="password" placeholder="Password" required>
            <button  type="submit" class="btn-white submit my-4" [disabled]="!LoginForm.valid">Log in</button>
          </div>
        </form>
      </div>
      <!-- <div class="mrgt-5">
        <a href="#">TODO: Forgot password?</a>
      </div>
      <div class="mrgt-2">
        <a href="#">TODO: Sign Up</a>
      </div> -->
      <div class="alert-danger p-2 w-50 mt-4 mx-auto" role="alert" *ngIf="showError">
        Invalid credentials
      </div>
    </div>
  </div>
</div>
