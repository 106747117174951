import { Injectable, Inject, ViewRef } from '@angular/core';
import { DVMService } from '../services/dvm.service';
import { GeneralHandler } from './general.handler';
import {
  DVM_CALLBACKS,
  DVM_CALLBACKS_MODEL,
  DVM_CONFIG,
} from '../digitalvenue.configuration';
import { DVMConfiguration } from '../dvmconfiguration.model';
import { FlowControlService } from '../services/flow-control.service';
import { ApiService } from 'src/app/services/api.service';
import { PackageSelectionService } from 'src/app/package-selector/services/package-selection.service';
import { MapLoaderService } from '../services/map-loader.service';
import { FlowcontrolService } from 'src/app/services/flowcontrol.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoadSeatselectionHandler extends GeneralHandler {
  private wasCartLoaded: boolean;

  constructor(
    @Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
    @Inject(DVM_CALLBACKS) private callbacksService: DVM_CALLBACKS_MODEL,
    private flowControlService: FlowcontrolService,
    private flowControl: FlowControlService,
    protected dvmService: DVMService,
    private apiService: ApiService,
    private packageSelectionService: PackageSelectionService,
    private mapLoaderService: MapLoaderService
  ) {
    super('end_load', dvmService);
    this.wasCartLoaded = false;
  }

  loadBlockmapHandler(viewer): void {
    this.mapLoaderService.addProcess('sections_loading');
    // tslint:disable-next-line:max-line-length
    this.apiService
      .getSectionsAvailability(
        this.flowControlService.currentPackageID,
        this.flowControlService.currentPackageSeatCategoryId
      )
      .subscribe((sections) => {
        viewer.setAvailability('section', sections);
        // Seleccionar los asientos de este paquete en la cart
        const currPackageId = this.packageSelectionService.currentPackage.id;
        const cartItem =
          this.packageSelectionService.getCartForPackage(currPackageId);
        if (cartItem && !this.wasCartLoaded) {
          this.packageSelectionService.select(cartItem.listOfSeats);
          this.wasCartLoaded = true;
        }
        this.mapLoaderService.removeProcess('sections_loading');
      });
  }

  loadSeatmapHandler(viewer): void {
    const section = viewer.getMapId();
    // Aqui puede ponerse logica que tenga que ejecutarse siempre
    // Por ejemplo, las pantallas de carga
    this.mapLoaderService.addProcess('sections_loading');
    // tslint:disable-next-line:max-line-length
    // Comento la llamada ya que mas abajo llama al callbackresponse y vuelve a setear las sillas, de esta manera se elimina
    // una llamada, dejo este comentario para recordarme por si esto generara algun bug
    // this.apiService
    //   .getSeatsAvailability(
    //     this.flowControlService.currentPackageID,
    //     this.flowControlService.currentPackageSeatCategoryId,
    //     section
    //   )
    //   .subscribe((seatmap) => {
    //     viewer.setAvailability('seat', Object.keys(seatmap));
    //   });

    // External callback
    const callbackResponse = this.callbacksService.loadSeatmapHandler(viewer);

    // Aqui puede ponerse logica que tenga que ejecutarse siempre
    // Por ejemplo, las pantallas de carga
    const removeLoader = () =>
      this.mapLoaderService.removeProcess('sections_loading');
    if (callbackResponse instanceof Promise) {
      callbackResponse.then(removeLoader).catch(removeLoader);
    } else if (callbackResponse instanceof Observable) {
      callbackResponse.subscribe(removeLoader, removeLoader);
    } else {
      removeLoader();
    }
  }

  protected handle(error, viewer): void {
    if (error) {
      console.error(error);
    }
    viewer = viewer.instance;
    if (viewer.getMapId() === this.dvmConfig.map_id) {
      this.loadBlockmapHandler(viewer);
    } else {
      this.loadSeatmapHandler(viewer);
    }
  }
}
