<div>
  <div class="super-planseat-container" id="seat-modal">
    <div class="main-panel text-color" >
      <div class="modal-body" *ngFor="let seat of customerPlan.customer_plan_seats; let i = index">
        <div class="panel">
          <table align="center">
            <tr>
              <td class="m-right">Section</td>
              <td class="m-left">{{seat.section}}</td>
            </tr>
            <tr>
              <td class="m-right">Row</td>
              <td class="m-left">{{seat.seat_row}}</td>
            </tr>
            <tr>
              <td class="m-right">Seat</td>
              <td class="m-left">{{seat.seat}}</td>
            </tr>
            <tr class="text-bold">
              <td class="m-right">Price</td>
              <td class="m-left">$ {{seat.price}}</td>
            </tr>
          </table>
          <div class="seatinfo flex">
            <div class="iconmenu flex">
              <button class="btn btn-primary">
                <span class="ti-eye align-items-center" style='font-size: 14px;'></span>
                <span style='font-size: 10px; margin-left: 5px'>360 View</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="closeModal()">
        Close
      </button>
    </div>
  </div>
</div>
