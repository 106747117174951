import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/services';

@Component({
  selector: 'app-assign-struct',
  templateUrl: './assign-struct.component.html',
  styleUrls: ['./assign-struct.component.scss'],
})
export class AssignStructComponent implements OnInit {
  @Output() customer: EventEmitter<any>;
  @Output() structure: EventEmitter<any>;
  @Input('selectedCustomer') currentCustomer;
  @Input('selectedStruct') currentStruct;

  foundCustomers;
  foundStructs;

  selectedCustomer;
  selectedStruct;

  constructor(private userService: UserService) {
    this.customer = new EventEmitter<any>();
    this.structure = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.selectedStruct = this.currentStruct;
    this.selectedCustomer = this.currentCustomer;
  }

  searchCustomer(contactNumber) {
    this.selectedCustomer = null;
    this.userService.searchClient(contactNumber).subscribe((result) => {
      let resultsArray = result instanceof Array ? result : [result];

      this.foundCustomers = resultsArray;
    });
  }

  searchStructure(contactNumber) {
    this.selectedStruct = null;

    this.userService.searchClient(contactNumber).subscribe((result) => {
      let resultsArray = result instanceof Array ? result : [result];

      this.foundStructs = resultsArray;
    });
  }

  selectStruct(struct) {
    this.selectedStruct = struct;
  }

  selectCustomer(customer) {
    this.selectedCustomer = customer;
  }

  selectClient(customer) {
    this.selectedCustomer = customer;
  }

  assignContacttoStruct() {
    const structNumber = this.selectedStruct.contactNumber;
    const contacts = [this.selectedCustomer.contactNumber];

    // console.log(contacts);
    this.userService
      .assignContacttoStruct(structNumber, contacts)
      .subscribe((contact) => {
        // console.log('observable resuelto -> ', contact);
        this.structure.emit(this.selectedStruct);
        this.customer.emit(contact);
      });
  }
}
