import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopbarComponent } from './topbar/topbar.component';
import { MainInterfaceComponent } from './main-interface/main-interface.component';
import { RouterModule } from '@angular/router';
import { APP_CONFIG, ApplicationConfiguration, DVMConfig, DVM_CONFIG } from './digitalvenue.configuration';
import { DVMService } from './services/dvm.service';



@NgModule({
  declarations: [
    MainInterfaceComponent,
    TopbarComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MainInterfaceComponent,
    TopbarComponent
  ],
  providers: [
    {provide: APP_CONFIG, useValue: ApplicationConfiguration},
    {provide: DVM_CONFIG, useValue: DVMConfig},
    DVMService
  ]
})
export class DigitalvenueModule { }
