import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../../services';

@Component({
  selector: 'app-assign-relay',
  templateUrl: './assign-relay.component.html',
  styleUrls: ['./assign-relay.component.scss'],
})
export class AssignRelayComponent implements OnInit {
  // States

  customers: any[] | null = null;
  error: boolean;
  showLoader: boolean;

  @Input() criteria: 'struct-relay-customer';

  // Emitters
  @Output('customer') clientEmitter = new EventEmitter<any>();
  @Input('structure') structure;

  constructor(private userService: UserService) {}

  ngOnInit(): void {}

  handleSubmit(values) {
    const { contactID } = values;
    if (!contactID) {
      return;
    }

    this.showLoader = true;
    this.userService.searchClient(contactID).subscribe((result) => {
      this.showLoader = false;
      let resultsArray = result instanceof Array ? result : [result];

      resultsArray = resultsArray.filter((customer) => {
        // return customer.role === 'RELAY';
        return customer;
      });

      // console.log(resultsArray);

      if (resultsArray.length) {
        this.customers = resultsArray;
        this.error = false;
      } else {
        this.customers = null;
        this.error = true;
      }
      // this.error = result.hasOwnProperty('codeError');
    });
  }

  handleParamsSubmit(values) {
    // if (Object.values(values).some((val) => !val)) {
    //   console.log(values);
    //   // Falta alguna prop por llenar, avisar a la view
    //   return;
    // }
    this.showLoader = true;
    this.userService.findClient(values).subscribe((results: Array<any>) => {
      this.showLoader = false;
      let resultsArray = results instanceof Array ? results : [results];

      resultsArray = resultsArray.filter((customer) => {
        // return customer.role === 'RELAY';
        return customer;
      });

      if (resultsArray.length) {
        this.customers = resultsArray;
        this.error = false;
      } else {
        this.customers = null;
        this.error = true;
      }
      // this.error = results.hasOwnProperty('codeError');
    });
  }

  emitFound(customer) {
    if (Object.keys(customer).length === 3) {
      this.userService
        .searchClient(customer.contactNumber)
        .subscribe((fullCustomer) => {
          this.clientEmitter.emit(fullCustomer);
        });
    } else {
      this.clientEmitter.emit(customer);
    }
  }
}
