<div class="content-container">
  <div id='admin-toolbar' class='row mt-3 mb-3 justify-content-between'>
      <button routerLink="/user-list/" class='btn-start'>Back</button>
      <button type='button' class='btn-start' (click)="onDelete()">Delete</button>
  </div>

  <div class="container">
    <form (ngSubmit)="onSubmit(userForm)" #userForm="ngForm">
      <div class='row justify-content-center' *ngIf="user">
        <div class="form-content">

          <div class="form-item row">
            <div class="col-3">
              <label>Id</label>
            </div>
            <div class='col-9 pr-0 pr-0'>
              <input type="text" [(ngModel)]="user.id" name="userId" [disabled]="true">
            </div>
          </div>

          <div class="form-item row">
            <div class="col-3">
              <label>Username</label>
            </div>
            <div class='col-9 pr-0 pr-0'>
              <input type="text" [(ngModel)]="user.username" name="username">
            </div>
          </div>

          <div class="form-item row">
            <div class="col-3">
              <label>Password</label>
            </div>
            <div class='col-9 pr-0 pr-0'>
              <input type="password" [(ngModel)]="user.password" name="password">
            </div>
          </div>

          <div class="form-item row">
            <div class="col-3">
              <label>First name</label>
            </div>
            <div class='col-9 pr-0 pr-0'>
              <input type="text" [(ngModel)]="user.first_name" name="first_name">
            </div>
          </div>

          <div class="form-item row">
            <div class="col-3">
              <label>Last name</label>
            </div>
            <div class='col-9 pr-0 pr-0'>
              <input type="text" [(ngModel)]="user.last_name" name="last_name">
            </div>
          </div>

          <div class="form-item row">
            <div class="col-3">
              <label>Email</label>
            </div>
            <div class='col-9 pr-0 pr-0'>
              <input type="text" [(ngModel)]="user.email" name="email">
            </div>
          </div>

          <div class="form-item row">
            <div class="col-3">
              <label>Phone</label>
            </div>
            <div class='col-9 pr-0 pr-0'>
              <input type="text" [(ngModel)]="user.phone" name="phone">
            </div>
          </div>

          <div class="form-item row">
            <div class="col-3">
              <label>Type</label>
            </div>
            <div class='col-9 pr-0 pr-0'>
              <select class="form-control" [(ngModel)]="user.type" name="type">
                <option [value]="'root'">Root</option>
                <option [value]="'admin'">Admin</option>
                <option [value]="'sales_rep'">Sales Rep</option>
              </select>
            </div>
          </div>

          <div class="form-item row">
            <div class="col-3">
              <label>Active</label>
            </div>
            <div class='col-9 pr-0 pr-0'>
              <div class="form-check form-check-inline">
                <input class="form-check-input" [value]="true" type="radio" [(ngModel)]="user.is_active"
                       name="is_active">
                <label class="form-check-label">True</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" [value]="false" type="radio" [(ngModel)]="user.is_active"
                       name="is_active">
                <label class="form-check-label">False</label>
              </div>
            </div>
          </div>

        </div>
        <div class="width-70 d-flex justify-content-end">
          <button type='submit' class="btn-start">Update</button>
        </div>
      </div>

    </form>
  </div>


</div>
