import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { PackageModel } from '../../../shared/models';

@Component({
  selector: 'app-package-item-unique-benefits',
  templateUrl: './package-item-unique-benefits.component.html',
  styleUrls: ['./package-item-unique-benefits.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PackageItemUniqueBenefitsComponent implements OnInit {
  @Input() selectedPackage: PackageModel;

  constructor() {}

  ngOnInit(): void {}
}
