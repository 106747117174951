import {Component, Input, OnInit} from '@angular/core';
import {CustomerPackagePlan} from '../../../shared/models/customer-package/customer-package-plan.model';

@Component({
  selector: 'app-home-page-column',
  templateUrl: './home-page-column.component.html',
  styleUrls: ['./home-page-column.component.scss']
})
export class HomePageColumnComponent implements OnInit {
  @Input('packages') availablePackages: Array<CustomerPackagePlan | null>;
  @Input('array_index') index: number;
  @Input('show-package-info') pinfo = true;

  constructor() { }

  ngOnInit(): void {
    // const addStart = this.index * 2;
    // for (let i = 0; i < addStart; i++) {
    //   this.availablePackages = [null].concat(this.availablePackages);
    // }
  }

}
