<div class="d-flex justify-content-center">
  <form
    *ngIf="!isFullDataLoading"
    #structForm="ngForm"
    (ngSubmit)="submitStruct(structForm.value)"
    class="form-group create-struct-form"
  >
    <div class="w-100">
      <div class="row justify-content-center">
        <div class="col-11">
          <input
            type="text"
            placeholder="Business Name"
            name="name"
            class="create-field"
            [ngModel]="baseStruct ? baseStruct.name : ''"
          />
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-11">
          <!-- <select name="structureType" class="create-field" [ngModel]="baseStruct ? baseStruct.structureTypeId : undefined"> -->
          <select
            name="structureType"
            class="create-field read-only"
            [ngModel]="baseStruct ? baseStruct.structureTypeId : 'UNDEFINED'"
          >
            <option [ngValue]="'UNDEFINED'" [disabled]="true">Industry</option>
            <!-- <option [disabled]="true">Type</option> -->
            <option
              [value]="item.key"
              *ngFor="let item of structureTypes | keyvalue"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="d-flex col-11 justify-content-around">
          <!--              line 1-->
          <div class="row-main-entry mrgr-1" ngModelGroup="address">
            <input
              type="text"
              placeholder="Address Line 1"
              class="create-field"
              name="line1"
              #line1="ngModel"
              [ngModel]="baseStruct ? baseStruct.address.line1 : ''"
              required
            />
            <div
              *ngIf="line1.invalid && (line1.dirty || line1.touched)"
              class="alert"
            >
              <div *ngIf="line1.errors.required">Address is required.</div>
            </div>
          </div>
          <!--              end line 1-->
          <!--              line 2-->
          <div class="row-main-entry mrgr-1" ngModelGroup="address">
            <input
              type="text"
              placeholder="Address Line 2"
              class="create-field"
              name="line2"
              #line2="ngModel"
              [ngModel]="baseStruct ? baseStruct.address.line2 : ''"
            />
            <!--                <div *ngIf="line.invalid && (line.dirty || line.touched)" class="alert">-->
            <!--                  <div *ngIf="line.errors.required">-->
            <!--                    line2 is required.-->
            <!--                  </div>-->
            <!--                </div>-->
          </div>

          <!--              end line 2-->
          <!--              line 3-->
          <div class="row-main-entry" ngModelGroup="address">
            <input
              type="text"
              placeholder="Address Line 3"
              class="create-field"
              name="line3"
              #line3="ngModel"
              [ngModel]="baseStruct ? baseStruct.address.line3 : ''"
            />
            <!--                <div *ngIf="line.invalid && (line.dirty || line.touched)" class="alert">-->
            <!--                  <div *ngIf="line.errors.required">-->
            <!--                    Address is required.-->
            <!--                  </div>-->
            <!--                </div>-->
          </div>

          <!--              end line 3-->
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-11 d-flex">
          <div class="mrgr-1 row-main-entry" ngModelGroup="address">
            <input
              type="text"
              placeholder="Town"
              class="create-field"
              name="town"
              #town="ngModel"
              [ngModel]="baseStruct ? baseStruct.address.town : null"
              required
            />
            <div
              *ngIf="town.invalid && (town.dirty || town.touched)"
              class="alert"
            >
              <div *ngIf="town.errors.required">Town is required.</div>
            </div>
          </div>
          <div class="" ngModelGroup="address">
            <input
              type="text"
              placeholder="Postcode"
              class="create-field"
              name="zipCode"
              #zipcode="ngModel"
              [ngModel]="baseStruct ? baseStruct.address.zipCode : null"
              required
            />
            <div
              *ngIf="zipcode.invalid && (zipcode.dirty || zipcode.touched)"
              class="alert"
            >
              <div *ngIf="zipcode.errors.required">Postcode is required.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-11">
          <select
            name="countryCode"
            class="create-field read-only"
            [ngModel]="baseStruct ? baseStruct.countryCode : 'UNDEFINED'"
          >
            <option [ngValue]="'UNDEFINED'" [disabled]="true">Country</option>
            <!-- <option [disabled]="true">Country</option> -->
            <option
              *ngFor="let countryCode of countryCodes"
              [value]="countryCode[0]"
            >
              {{ countryCode[1] }}
            </option>
          </select>
        </div>
      </div>
      <div class="row justify-content-center">
        <button *ngIf="!baseStruct" class="btn-white">Create Business</button>
        <div *ngIf="baseStruct">
          <button (click)="cancelUpdate()" class="btn-white">Cancel</button>
          <button class="btn-white">Update</button>
        </div>
      </div>
    </div>
  </form>
</div>
