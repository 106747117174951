<div class="container-fluid black1 header justify-content-center align-items-center" style="padding-top: 16.5px">
  <div class="col-container">
<!--    Buttons-->
    <div class="col f-start-center">
        <a [routerLink]="'/homepage'" routerLinkActive="active" *ngIf="!router.url.includes('/login')">Packages</a>
<!--      <p class="text-normal"> TODO: Buttons </p>-->
    </div>
<!--    River Side Logo-->
    <div class="col f-center-center">
      <img src="../../assets/svg/TheRiverside_Logo.svg" alt="The Riverside" style="max-height: 30px; width: auto">
    </div>
<!--    Fulham logo and user info -->
    <div class="col f-end-center">
      <div class="pull-right text16 mr-4" *ngIf="!router.url.includes('/login')">
        <p class="text-normal" *ngIf="user_name">Welcome, {{user_name}} | <span style="cursor: pointer;" (click)="logout()">Log Out</span></p>
      </div>
      <img src="../../assets/svg/FFC_Crest_White.svg" alt="Fulham Logo" style="max-height: 57px; width: auto">
    </div>
  </div>
</div>
