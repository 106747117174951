<div class="content-container pt-5 pb-4">
  <div id='admin-toolbar' class='row mx-auto justify-content-start mb-3'>
    <div>
      <button routerLink="/admin-area/" class='btn-white btn-start  mr-3'>Back</button>
    </div>
  </div>

  <table id="user-table" class="width-70 mx-auto mb-3">
    <thead>
    <tr>
      <th>id</th>
      <th class="text_center">Username</th>
      <th class="text_center">Type</th>
      <th class="text_center">Active</th>
      <th class="text_center">First name</th>
      <th class="text_center">Last name</th>
      <th class="text_center">Email</th>
      <th class="text_center">Phone</th>
      <th class="text_center">Details</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of userList; let i = index">
      <td>{{user.id}}</td>
      <td>{{user.username}}</td>
      <td>{{user.type}}</td>
      <td>{{user.is_active}}</td>
      <td>{{user.first_name}}</td>
      <td>{{user.last_name}}</td>
      <td>{{user.email}}</td>
      <td>{{user.phone}}</td>
      <td id="button-center">
        <button class="btn-table btn-start" [routerLink]="['/user-detail/', user.id]">Edit</button>
        <button class="btn-table btn-start" (click)="onDelete(user, i)">Delete</button>
      </td>
    </tr>
    </tbody>
  </table>

  <div class='width-70 row mx-auto justify-content-between mb-3'>
    <pagination
      [totalItems]="totalItems"
      [itemsPerPage]="itemsPerPage"
      [(ngModel)]="currentPage"
      [firstText]="'First'"
      [lastText]="'Last'"
      [previousText]="'Previous'"
      [nextText]="'Next'"
      (pageChanged)="pageChanged($event)"
      [boundaryLinks]="true" [rotate]="true" [maxSize]="5">
    </pagination>
    <button class="btn-white btn-start" [routerLink]="['/user-create']">Create</button>
  </div>
</div>
