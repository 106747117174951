<div class="content-container pt-5">
    <app-sub-header
    [header]="'NEW CUSTOMER'"
    [goback]="'selection/map/'+ currPackageId"
    [gohome]="true"
    [color]="'header-title'"
    >
    <div class="subheader-content">
        <p class="mb-0">
            London's original football club has a new world-class matchday experience.
        </p>
        <p>
            Welcome to The Riverside.
        </p>
    </div>
    </app-sub-header>
    <div class="d-flex flex-column align-items-center" style="text-align: center">
        <form *ngIf="currentTab === 'create'" #customerForm="ngForm" class="form-group d-flex flex-column align-items-center login-form"
            (ngSubmit)="submitForm(customerForm)">
            <div class="col-11" *ngIf="isReadOnly" [ngClass]="{'read-only': isReadOnly}">
                <input type="text" placeholder="Contact Number" class="login-field" readonly name="contactNumber" [ngModel]="linkedAccount ? linkedAccount.contactNumber : null">
            </div>
            <div class="d-flex col-11" [ngClass]="{'read-only': isReadOnly}">
                <div class="mr-2">
                    <select name="title" class="login-field" [ngModel]="linkedAccount ? linkedAccount.title : 'UNDEFINED'" required>
                        <option value="UNDEFINED" [disabled]="isReadOnly">None</option>
                        <option value="MR" [disabled]="isReadOnly">MR</option>
                        <option value="MRS"[disabled]="isReadOnly">MRS</option>
                        <option value="MR_AND_MRS" [disabled]="isReadOnly">MR & MRS</option>
                        <option value="MISS" [disabled]="isReadOnly">MISS</option>
                    </select>
                </div>
                <div class="mr-2 row-main-entry">
                    <input type="text" placeholder="First Name" class="login-field" name="name" [readonly]="isReadOnly" #name="ngModel" [ngModel]="linkedAccount ? linkedAccount.name : null" required>
                  <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert">
                    <div *ngIf="name.errors.required">
                      Name is required.
                    </div>
                  </div>
                </div>


                <div class="row-main-entry">
                    <input type="text" placeholder="Last Name" class="login-field" name="lastname" [readonly]="isReadOnly" #lastname="ngModel" [ngModel]="linkedAccount ? linkedAccount.lastname : null" required>
                  <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="alert">
                    <div *ngIf="lastname.errors.required">
                      Lastname is required.
                    </div>
                  </div>
                </div>

            </div>
            <div class="col-11" [ngClass]="{'read-only': isReadOnly}">
                <input type="text" placeholder="Email" class="login-field" name="email" [readonly]="isReadOnly" [pattern]="emailRegex" #email="ngModel" [ngModel]="linkedAccount ? linkedAccount.email : null" required>
              <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert">
                <div *ngIf="email.errors.required">
                  Email is required.
                </div>
                <div *ngIf="email.errors.pattern">
                  Email must be valid.
                </div>
              </div>
            </div>
            <div class="col-11 d-flex" [ngClass]="{'read-only': isReadOnly}">
                <div class="mrgr-1">
                  <select name="phonePrefix" class="login-field" [ngModel]="linkedAccount ? linkedAccount.phonePrefix : 'UNDEFINED'" required>
                    <option [ngValue]="'UNDEFINED'" [disabled]="true">Prefix</option>
                    <option [value]="item[1]" *ngFor="let item of countryPhone">{{item[1]}} ({{item[0]}})</option>
                  </select>
                </div>
                <div class="mrgr-1">
                  <input type="text" placeholder="Phone number" class="login-field" name="phone" [readonly]="isReadOnly" [pattern]="phoneRegex" #phone="ngModel" [ngModel]="linkedAccount ? linkedAccount.phone : null" required>
                  <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="alert">
                    <div *ngIf="phone.errors.required">
                      Phone is required.
                    </div>
                    <div *ngIf="phone.errors.pattern">
                      Only numbers are allowed.
                    </div>
                  </div>
                </div>
              <div class="row-main-entry">
                  <input type="date" placeholder="Birthdate" class="login-field" name="birthdate" [readonly]="isReadOnly" #birthdate="ngModel" [ngModel]="linkedAccount ? linkedAccount.birthdate : null" required>
                  <div *ngIf="birthdate.invalid && (birthdate.dirty || birthdate.touched)" class="alert">
                    <div *ngIf="birthdate.errors.required">
                      Birthdate is required.
                    </div>
                  </div>
                </div>
            </div>
            <div class="d-flex col-11 justify-content-around" [ngClass]="{'read-only': isReadOnly}">

<!--              line 1-->
              <div class="row-main-entry mrgr-1" ngModelGroup="address">
                <input type="text" placeholder="Adress Line 1" class="login-field" name="line1" [readonly]="isReadOnly" #line1="ngModel" [ngModel]="linkedAccount ? linkedAccount.address.line1 : null" required>
                <div *ngIf="line1.invalid && (line1.dirty || line1.touched)" class="alert">
                  <div *ngIf="line1.errors.required">
                    Address is required.
                  </div>
                </div>
              </div>
              <!--              end line 1-->
              <!--              line 2-->
              <div class="row-main-entry mrgr-1" ngModelGroup="address">
                <input type="text" placeholder="Adress Line 2" class="login-field" name="line2" [readonly]="isReadOnly" #line2="ngModel" [ngModel]="linkedAccount ? linkedAccount.address.line2 : null">
<!--                <div *ngIf="line.invalid && (line.dirty || line.touched)" class="alert">-->
<!--                  <div *ngIf="line.errors.required">-->
<!--                    line2 is required.-->
<!--                  </div>-->
<!--                </div>-->
              </div>

<!--              end line 2-->
              <!--              line 3-->
              <div class="row-main-entry" ngModelGroup="address">
                <input type="text" placeholder="Adress Line 3" class="login-field" name="line3" [readonly]="isReadOnly" #line3="ngModel" [ngModel]="linkedAccount ? linkedAccount.address.line3 : null">
<!--                <div *ngIf="line.invalid && (line.dirty || line.touched)" class="alert">-->
<!--                  <div *ngIf="line.errors.required">-->
<!--                    Address is required.-->
<!--                  </div>-->
<!--                </div>-->
              </div>

<!--              end line 3-->

            </div>
          <div class="col-11 d-flex" [ngClass]="{'read-only': isReadOnly}">
            <div class="mrgr-1 row-main-entry" ngModelGroup="address">
              <input type="text" placeholder="Town" class="login-field" name="town" [readonly]="isReadOnly" #town="ngModel" [ngModel]="linkedAccount ? linkedAccount.address.town : null" required>
              <div *ngIf="town.invalid && (town.dirty || town.touched)" class="alert">
                <div *ngIf="town.errors.required">
                  Town is required.
                </div>
              </div>
            </div>
            <div class="mrgr-1" ngModelGroup="address">
              <input type="text" placeholder="Postcode" class="login-field" name="zipCode" [readonly]="isReadOnly" #zipcode="ngModel" [ngModel]="linkedAccount ? linkedAccount.address.zipCode : null" required>
              <div *ngIf="zipcode.invalid && (zipcode.dirty || zipcode.touched)" class="alert">
                <div *ngIf="zipcode.errors.required">
                  Postcode is required.
                </div>
              </div>
            </div>
            <div class="">
              <!--                    <input type="text" placeholder="Country code" class="login-field" name="countryCode" [readonly]="isReadOnly" [ngModel]="linkedAccount ? linkedAccount.countryCode : null">-->
              <select name="countryCode" class="login-field" #ccode="ngModel" [ngModel]="linkedAccount ? linkedAccount.countryCode : 'UNDEFINED'" required>
                <option [ngValue]="'UNDEFINED'" [disabled]="true">Country Code</option>
                <option [value]="item.key" *ngFor="let item of countryCode | keyvalue">{{item.value}}</option>
              </select>
              <div *ngIf="ccode.invalid && (ccode.dirty || ccode.touched)" class="alert">
                <div *ngIf="ccode.errors.required">
                  Country Code is required.
                </div>
              </div>
            </div>
          </div>
            <div class="col-11" [ngClass]="{'read-only': isReadOnly, 'no-bar': isReadOnly}">
                <input type="text" placeholder="Contact Number of the structure" class="login-field" name="structureContactNumber" [readonly]="isReadOnly || linkedAccount" [ngModel]="linkedAccount ? linkedAccount.structureContactNumber : assignedStruct">
            </div>
            <div *ngIf="!linkedAccount" class="mt-1 col-11 d-flex justify-content-end">
                <label class="d-flex align-items-center text-normal">
                    <span>Opt-In.</span>
                    <input class="TC w-auto ml-3" type="checkbox" name="optin" ngModel [ngModel]="linkedAccount ? linkedAccount.optin : false">
                </label>
            </div>
            <div class="d-flex" *ngIf="isReadOnly else editingButtons">
                <button type="button" *ngIf="linkedAccount" (click)="toggleEditable()">Edit customer</button>
                <button>Set Customer</button>
            </div>
            <ng-template #editingButtons>
                <div class="d-flex">
                    <button type="button" (click)="cancelChanges(customerForm)" *ngIf="linkedAccount">Cancel</button>
                    <button class="mrgr-2" type="button" (click)="changeTab('create-struct')" *ngIf="!linkedAccount">Create Structure</button>
                    <button type="submit"> {{this.linkedAccount ? "Apply" : "Create"}}</button>
                </div>
            </ng-template>
        </form>
        <div *ngIf="currentTab === 'create'" class="mt-4">
            <p class="text-normal">
                Already have an account? <u (click)="changeTab('search')">Sign in</u>
            </p>
        </div>
        <div class="w-50">
            <app-search-client *ngIf="currentTab === 'search'" (client)="selectClient($event)"></app-search-client>
            <div *ngIf="currentTab === 'search'" class="mt-4">
                <p class="text-normal">
                    You don't have an account? <u (click)="changeTab('create')">Create account</u>
                </p>
            </div>
        </div>
        <div class="w-50">
            <app-create-struct *ngIf="currentTab === 'create-struct'" (structContactID)="handleAssignStruct($event)"></app-create-struct>
            <div *ngIf="currentTab === 'create-struct'" class="mt-4">
                <p class="text-normal">
                    Already have a structure? <u (click)="changeTab('create')">Create account</u>
                </p>
            </div>
        </div>
    </div>
</div>
