import { Component, Input, OnInit } from '@angular/core';
import { PackageModel } from '../../../shared/models';

@Component({
  selector: 'app-package-item-member-benefits',
  templateUrl: './package-item-member-benefits.component.html',
  styleUrls: ['./package-item-member-benefits.component.scss']
})
export class PackageItemMemberBenefitsComponent implements OnInit {

  @Input() selectedPackage: PackageModel;

  constructor() { }

  ngOnInit(): void {
  }
}
