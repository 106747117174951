import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list/user-list.component';
import {RouterModule} from '@angular/router';
import { UserDetailComponent } from './user-detail/user-detail.component';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {FormsModule} from '@angular/forms';
import { UserCreateComponent } from './user-create/user-create.component';



@NgModule({
  declarations: [UserListComponent, UserDetailComponent, UserCreateComponent],
  imports: [
    CommonModule,
    RouterModule,
    PaginationModule,
    FormsModule
  ]
})
export class UserManagementModule { }
