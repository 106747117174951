<div class="content-container pt-5 pb-4">
  <div class="container">
    <div class="row">
      <div class="col-4">
        <a routerLink="user-list">Manage users</a>
      </div>
      <div class="col-4">
        <a routerLink="orders-list">Manage Orders</a>
      </div>
    </div>
  </div>
</div>
