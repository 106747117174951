import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanLoad,
  Route, UrlSegment
} from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { UserService } from '../services';
import {UserModel} from "../shared/models";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private router: Router, private userService: UserService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

      if (localStorage.getItem('access_token') === null) {
        this.router.navigate(['/login']);
        return of(false);
      } else {
        const token = JSON.parse(localStorage.getItem('access_token'));
        const date = Date.now();
        if (date > token.expires_at) {
          localStorage.removeItem('access_token');
          this.router.navigate(['/login']);
          return of(false);
        } else {
          return this.userService.getUserLogged()
            .pipe(
              mapTo(true)
            );
        }
      }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
      return this.canActivate(next, state);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    if (localStorage.getItem('access_token') === null) {
      this.router.navigate(['/login']);
      return of(false);
    } else {
      const token = JSON.parse(localStorage.getItem('access_token'));
      const date = Date.now();
      if (date > token.expires_at) {
        localStorage.removeItem('access_token');
        this.router.navigate(['/login']);
        return of(false);
      } else {
        return this.userService.getUserLogged()
          .pipe(
            map(user => {
              if (user.user.type === 'root' || user.user.type === 'admin') {
                return true;
              } else {
                this.router.navigate(['/homepage']);
                return false;
              }
        }));
      }
    }
  }

}
