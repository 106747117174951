import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserModel } from './shared/models';
import { UserService } from './services';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import * as appPackage from '../../package.json';
// declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  user: UserModel;
  salesRepUsername: string;
  title = 'staplescenter-ticket-management-portal';
  salesRepName$ = new BehaviorSubject<boolean>(false);
  version: string;

  constructor(
    private userService: UserService,
    private router: Router,
    private ref: ChangeDetectorRef,
    private routerActive: ActivatedRoute
  ) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     gtag('config', 'UA-74364748-15',
    //       {
    //         page_path: event.urlAfterRedirects
    //       }
    //     );
    //   }
    // });
  }

  ngOnInit(): void {
    this.loadAnayliticsScripts(environment.analytics);
    this.version = appPackage.version;
  }

  private loadAnayliticsScripts(analyticsId: string): void {
    // first part analytics node
    const node = document.createElement('script'); // creates the script tag
    node.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`; // sets the source (insert url in between quotes)
    node.async = true; // makes script run asynchronously
    node.type = 'text/javascript'; // set the script type
    document.getElementsByTagName('head')[0].appendChild(node);
    // second part analytics node
    const node2 = document.createElement('script'); // creates the script tag
    node2.innerHTML = `window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', '${analyticsId}');`;
    node2.type = 'text/javascript'; // set the script type
    // append to head of document
    document.getElementsByTagName('head')[0].appendChild(node2);
  }

  // isAdmin(): boolean {
  //   if (this.user) {
  //     return (this.user.takeover.length > 0);
  //   }
  // }

  // isLogin(): boolean {
  //   return !this.router.url.includes('/login');
  // }

  // getTheme(): any {
  //   return this.themeSelector.isDarkTheme;
  // }
}
