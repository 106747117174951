import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationModel } from '../../../shared/models/PaginationModel';
import { OrderModel } from '../../../shared/models/order.model';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private apiRoot = '/bo';

  constructor(private http: HttpClient) {
  }

  public getOrders(page: number = 1): Observable<PaginationModel<OrderModel>> {
    const url = `${this.apiRoot}/order?page=${page}`;
    return this.http.get<PaginationModel<OrderModel>>(url);
  }

  public getOrder(id: string): Observable<OrderModel> {
    return this.http.get<OrderModel>(`${this.apiRoot}/order/${id}`);
  }

  public updateOrder(order: OrderModel): Observable<any> {
    return this.http.patch<any>(`${this.apiRoot}/order/${order.order_id}`, order);
  }

  public prettyDate(dateStr: string): string {
    const datePipe = new DatePipe('en-US');
    const str = datePipe.transform(dateStr, 'medium');
    return str;
  }
}
