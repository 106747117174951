import { Inject, Injectable } from '@angular/core';
import { DVMService } from './dvm.service';
import { IsolatedSeatsService } from './isolated-seats.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { DvmInterceptor, DVM_CALLBACKS, DVM_CALLBACKS_MODEL, DVM_INTERCEPTORS } from '../digitalvenue.configuration';
import { CartService } from './cart.service';



@Injectable({
  providedIn: 'root'
})
export class SeatSelectionService {

  private countNew = 0;
  additionalSeatsLimit = 5;

  constructor(private dvmService: DVMService,
              @Inject(DVM_CALLBACKS) private callbacksService: DVM_CALLBACKS_MODEL,
              @Inject(DVM_INTERCEPTORS) private dvmInterceptors: DvmInterceptor | [DvmInterceptor],
              private cartService: CartService,
              private isolatedSeatsService: IsolatedSeatsService) {
  }

  public get countOfSelectedSeats(): number {
    return this.countNew;
  }

  // Set count selected seats
  public set resetCountSelectedSeat(num: number) {
    this.countNew = num;
  }

  selectseat(node): any {
    // Obtenemos la area del nodo
    const mmcArea = this.dvmService.viewer.getMapId();

    // Miramos si hay que reseater el buffer para esta area
    this.isolatedSeatsService.checkResetBuffer(mmcArea);

    // Solicitamos la acción de añadir la silla
    const action = 'add';
    const seatObject = { id: node.id, areaid: mmcArea };
    // Pasamos el objeto asiento y la acción al algoritmo de isolated seats

    // Solicitamos los asientos resultantes
    const seats = this.isolatedseats(seatObject, action);

    // Ejecutar solo si tenemos el cart service activado
    let cart;
    // Sync execution, recuperar la cart
    this.cartService.cart$.pipe(take(1)).subscribe(res => cart = res);

    // Ejecutamos el interceptor o interceptoes de la cart para obtener la nueva cart
    let updatedCart;
    if (this.dvmInterceptors instanceof Array) {
      updatedCart = this.dvmInterceptors.reduce((cart, interceptor: DvmInterceptor) => interceptor.addSeatsInterceptor(seats, cart), cart);
    } else if (this.dvmInterceptors) {
      updatedCart = this.dvmInterceptors.addSeatsInterceptor(seats, cart);
    }

    // Emitimos la nueva cart
    this.cartService.emitCart(updatedCart);

    return seats;
  }


  unselectseat(node) {
    const mmcArea = this.dvmService.viewer.getMapId();
    const action = 'remove';
    const seatObject = { id: node.id, areaid: mmcArea };


    const seats = this.isolatedseats(seatObject, action);

    let cart;
    this.cartService.cart$.pipe(take(1)).subscribe(res => cart = res);

    // Ejecutamos el interceptor o interceptoes de la cart para obtener la nueva cart
    let updatedCart;
    if (this.dvmInterceptors instanceof Array) {
      updatedCart = this.dvmInterceptors.reduce((cart, interceptor: DvmInterceptor) => interceptor.removeSeatsInterceptor(seats, cart), cart);
    } else if (this.dvmInterceptors) {
      updatedCart = this.dvmInterceptors.removeSeatsInterceptor(seats, cart);
    }

    //Emitimos la nueva cart
    this.cartService.emitCart(updatedCart);

    return seats;

  }



  private isolatedseats(seatObject, action) {
    // Comprobamos si hay asientos en el buffer
    if (this.isolatedSeatsService.singleSeatsBuffer.length > 0) {
      // Si hay asiento en el buffer comprobamos si se puede solucionar el isolated
      return this.isolatedSeatsService.singleSeatHandling(seatObject, action);
    } else {
      // Si no hay asientos en el buffer
      let existIsolatedseats: boolean;

      // Comprobamos si la app quiere prevenir los isolated seats
      if (false) { //Forzado a siempre prevenir
        existIsolatedseats = false;
      } else {
        let selectedElements = null
        if (action === "add") {
          // Si es una acción de añadir el elemento seleccionado es el que ha recibido la acción
          selectedElements = Array.of(this.dvmService.viewer.getNodeById(seatObject.id));
        } else if (action === "remove") {
          // Si es una acción de quitar los elementos seleccionados son los selected menos el que ha recibido la acción
          this.dvmService.viewer.unselect(seatObject.id);
          selectedElements = this.dvmService.viewer.getNodesByState('seat', 'selected');
        }
        // Comprobamos que asientos quedan aislados
        const isolatedseats = this.isolatedSeatsService.checkIsolatedSeats(selectedElements, seatObject, action);
        //Logger isolated seats
        existIsolatedseats = !!isolatedseats.length;
      }


      if (!existIsolatedseats) {
        // Seleccionamos o deseleccionamos directamente la silla
        if (action === "add") {
          this.countNew++;
          this.dvmService.viewer.select(seatObject.id);
        } else if (action === "remove") {
          this.countNew--;
          this.dvmService.viewer.unselect(seatObject.id);
        }
        // Avisamos de que el asiento se ha conseguido añadir o descartar (que silla y de que sector)
        return [seatObject];
      } else {
        const ISerrorMessage = 'IsolatedSeatsNotHandled';
        const ISerrorText =
          'Your selection is leaving an isolated seat. Please, fix your selection before continuing.';
        throw new Error(ISerrorMessage + "%%%" + ISerrorText)
      }
    }
  }
}
