import { AfterViewChecked, Component, Inject, Input, OnInit } from '@angular/core';
import {APP_CONFIG, DVM_CONFIG} from '../../../digitalvenue/digitalvenue.configuration';
import {DigitalvenueConfiguration} from '../../../digitalvenue/digitalvenue-configuration.model';
import {DVMConfiguration} from '../../../digitalvenue/dvmconfiguration.model';
import {DVMService} from '../../../digitalvenue/services/dvm.service';
import { PackageModel } from '../../../shared/models';

declare const DVM;


@Component({
  selector: 'app-package-item-seat-view',
  templateUrl: './package-item-seat-view.component.html',
  styleUrls: ['./package-item-seat-view.component.scss']
})
export class PackageItemSeatViewComponent implements OnInit, AfterViewChecked {

  @Input() selectedPackage: PackageModel;

  viewer3d;
  constructor(@Inject(APP_CONFIG) public dvConfig: DigitalvenueConfiguration,
              @Inject(DVM_CONFIG) public dvmConfig: DVMConfiguration,
              public dvmService: DVMService)
  {
  }

  ngOnInit(): void {
    if (!this.viewer3d) {
      this.initializeDVM(this.dvmConfig).then(() => {
        this.open3dView();
      });
    } else {
      this.open3dView();
    }
  }

  ngAfterViewChecked(): void {

  }

  initializeDVM(config) {
    const viewer3dConfig = JSON.parse(JSON.stringify(config));
    viewer3dConfig.container = 'viewer3d-container';
    viewer3dConfig.plugins = ['navigation'];
    return DVM.loadModule('3d_viewer', viewer3dConfig)
      .then((viewer3d) => {
        this.viewer3d = viewer3d;
        this.viewer3d.subscribe('click_navigation_node', this.navigationHandler.bind(this));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  navigationHandler(obj) {
    this.open3dView(obj.navigation.view_id);
  }

  open3dView(view = null) {
    // const load3DView = () => {
    const objView = { venue_id: this.dvmConfig.venue_id, view_id: this.selectedPackage.view_id};
    if (view) {
      objView.view_id = view;
    }
    this.viewer3d.loadView3d(objView)
      .then((resolve) => {
        document.getElementById('main-interface').style.height = '550px';
        this.viewer3d.flags.fixed_aspect_ratio = false;
        // if (this.isMobile() || window.innerWidth < 1200) {
        //   this.viewer3dModal.flags.fixed_aspect_ratio = false;
        //   document.getElementById('viewer3d-container-modal').style.height = this.parentModalHeight + 'px';
        // }
        this.viewer3d.open();
      })
      .catch((err) => {
        console.error(err);
      });
    // };

    // if (!this.dvmService.viewer3d) {
    //   const viewer3dConfig = JSON.parse(JSON.stringify(this.DVMConfig));
    //   viewer3dConfig.container = 'viewer3d-container-modal';
    //   DVM.loadModule('3d_viewer', viewer3dConfig)
    //         .then((viewer3d) => {
    //           this.dvmService.viewer3d = viewer3d;
    //           load3DView();
    //         })
    //         .catch((err) => {
    //             console.error(err);
    //         });
    // } else {
    //   load3DView();
    // }

  }

}
