import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PackageSelectionService } from 'src/app/package-selector/services/package-selection.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ModalsService } from 'src/app/shared/modals/modals.service';
import { PackageModel } from 'src/app/shared/models';
import { UserService } from '../services';
import { FlowcontrolService } from '../services/flowcontrol.service';
import { NavigationService } from '../services/navigation.service';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login-client',
  templateUrl: './login-client.component.html',
  styleUrls: ['./login-client.component.scss'],
})

// export interface PhoneCode {
//   [key: string]: string;
// }
export class LoginClientComponent implements OnInit {
  // State variables
  linkedAccount;
  currentTab: 'create' | 'search' | 'create-struct' = 'create';
  assignedStruct;
  countryPhone: any;
  countryCode: any;
  // @ts-ignore
  emailRegex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  phoneRegex = /^[0-9]*$/;

  isReadOnly = false;

  currPackageId: number;

  constructor(
    private navigation: NavigationService,
    private flowcontrolService: FlowcontrolService,
    private packageSelectionService: PackageSelectionService,
    private customerService: CustomerService,
    private http: HttpClient,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const joins = [
      this.getJson('/assets/data/country-codes.json'),
      this.getJson('/assets/data/country-phones.json'),
    ];
    forkJoin(joins).subscribe((responseList) => {
      this.countryCode = responseList[0];
      this.countryPhone = this.sortProperties(responseList[1]);
    });
    this.flowcontrolService.getCustomer().subscribe((customer) => {
      if (customer) {
        this.linkedAccount = customer;
        this.toggleEditable();
      }
    });
  }

  prefixUkFirst(data: Array<any>): any {
    data.forEach((item, i) => {
      if (item[0] === 'GB') {
        data.splice(i, 1);
        data.unshift(item);
      }
    });
    return data;
  }

  sortProperties(obj): Array<any> {
    // convert object into array
    const sortable = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        sortable.push([key, obj[key]]);
      }
    } // each item is an array in format [key, value]

    // sort items by value
    sortable.sort((a, b) => {
      const x = a[1];
      const y = b[1];
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return this.prefixUkFirst(sortable); // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
  }

  toggleEditable() {
    this.isReadOnly = !this.isReadOnly;
  }

  cancelChanges(form: NgForm) {
    this.toggleEditable();
    this.linkedAccount = this.linkedAccount;
    this.linkedAccount.phonePrefix =
      this.countryPhone[this.linkedAccount.countryCode];
    this.linkedAccount.phone = this.linkedAccount.phone.replace(
      this.linkedAccount.phonePrefix,
      ''
    );
    form.resetForm(this.linkedAccount);
  }

  submitForm(form) {
    const { dirty } = form;
    const customer = form.value;
    customer.language = 'en';
    customer.role = 'PUBLIC';
    if (form.invalid) {
      return;
    }

    // const hasEmptyFields = Object.values(customer).some(entry => entry == null);
    // if (hasEmptyFields) {
    //   return
    // }

    if (this.linkedAccount) {
      // Si hay una cuenta vinculada
      if (this.isReadOnly) {
        // Si estaba en modo lectura
        this.flowcontrolService.setCustomer(this.linkedAccount); // Seleccionamos el user
        this.navigation.goTo('selection/map'); // Navegamos al siguiente
      } else {
        // Si estaba en escritura
        // Añadimos el contact id a los datos del form (para que haga el update la API)
        customer.contactNumber = this.linkedAccount.contactNumber;
        customer.phone = `${this.linkedAccount.phonePrefix}${customer.phone}`;
        this.customerService
          .editCustomer(customer)
          .subscribe((editedCustomer) => {
            // Solicitamos edición de usuario
            this.linkedAccount = editedCustomer; // Asignamos la respuesta editada como cuenta vinculada
            this.linkedAccount.phonePrefix = this.setPhoneAndPrefix(
              editedCustomer.countryCode
            );
            this.linkedAccount.birthdate = this.formatDate(
              editedCustomer.birthdate
            );
            this.linkedAccount.phone = this.linkedAccount.phone
              ? this.linkedAccount.phone.replace(
                  this.linkedAccount.phonePrefix,
                  ''
                )
              : '000';
          });
        this.toggleEditable(); // Cambiamos a modo readonly
      }
    } else {
      customer.phone = `${customer.phonePrefix}${customer.phone}`;
      this.customerService
        .createCustomer(customer)
        .subscribe((createdCustomer) => {
          // Solicitamos crear el usuario
          this.linkedAccount = createdCustomer; // Asignamos la respuesta de creación como cuenta vinculada
          this.linkedAccount.phonePrefix = this.setPhoneAndPrefix(
            this.linkedAccount.countryCode
          );
          this.linkedAccount.birthdate = this.formatDate(
            createdCustomer.birthdate
          );
          this.linkedAccount.phone = this.linkedAccount.phone
            ? this.linkedAccount.phone.replace(
                this.linkedAccount.phonePrefix,
                ''
              )
            : '000';
          this.toggleEditable(); // Cambiamos a modo readonly
        });
    }
  }

  handleAssignStruct(structNumber) {
    this.assignedStruct = structNumber;
    this.changeTab('create');
  }

  formatDate(date) {
    // tslint:disable-next-line:one-variable-per-declaration prefer-const
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      // tslint:disable-next-line:prefer-const
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  selectClient(client) {
    this.linkedAccount = client;
    this.linkedAccount.phonePrefix = this.setPhoneAndPrefix(
      this.linkedAccount.countryCode
    );
    this.linkedAccount.phone = this.linkedAccount.phone
      ? this.linkedAccount.phone.replace(this.linkedAccount.phonePrefix, '')
      : '';
    this.linkedAccount.birthdate = this.formatDate(client.birthdate);
    this.changeTab('create');
    this.isReadOnly = true;
  }

  changeTab(tab: 'create' | 'search' | 'create-struct') {
    this.currentTab = tab;
  }

  getJson(urlToJson): Observable<any> {
    return this.http.get(urlToJson);
  }

  setPhoneAndPrefix(countryCode: string): any {
    const prefix = this.countryPhone.find((item, i) => {
      if (item[0] === countryCode) {
        return `${item[1]}`;
      }
    });
    return prefix[1];
    // return `${this.countryPhone[countryCode]}`;
  }
}
