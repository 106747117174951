<div class="item-overview">
  <div id="description" class="text-package-option mt-4 mb-5">
    <p class="text-subtitle mrgb-2">{{ selectedPackage.name }}</p>
    <p class="text-normal text-title">{{ selectedPackage.title }}</p>
    <p class="text-normal w90" [innerHTML]="selectedPackage?.description"></p>
  </div>

  <div class="text-package-option image-holder f-center-center">
    <div style="width: 100%">
      <app-package-carousel
        [images]="selectedPackage.images"
      ></app-package-carousel>
    </div>
  </div>
</div>
