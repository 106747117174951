<div class="sub-header-container mb-5">
  <div
    class="nav-buttons mx-2 d-flex justify-content-between align-items-center"
  >
    <div class="d-flex align-items-center">
      <a
        [ngClass]="colorTextSub"
        *ngIf="goback || overrideBack"
        (click)="onNavigate()"
        class="text-decoration-none mr-4 d-flex align-items-center"
        style="cursor: pointer"
      >
        <img
          src="/assets/img/arrow-left-icon.svg"
          alt="Arrow left icon"
          class="d-flex align-items-center mr-2"
        />
        Go Back
      </a>
      <a
        [ngClass]="colorTextSub"
        *ngIf="gohome"
        (click)="onNavigate('homepage')"
        class="text-decoration-none"
        style="cursor: pointer"
        >Go Home</a
      >
    </div>

    <div class="d-flex align-items-center" *ngIf="customer && includeCustomer">
      <p class="text-normal mb-0">
        On behalf of {{ customer }}
        <span class="mx-2">|</span>
        <span (click)="terminateCustomer()" style="cursor: pointer">
          End session</span
        >
      </p>
    </div>
  </div>

  <div
    class="d-flex flex-column align-items-center mt-5 mb-4"
    style="text-align: center"
    [ngClass]="colorText"
  >
    {{ headerString }}
  </div>

  <div class="text-normal text-center w-49">
    <ng-content select=".subheader-content"></ng-content>
  </div>
</div>
