<div>
  <div class="super-error-container">
    <div class="modal-header">
      <h4 class="pull-left text-bold">{{ title }}</h4>
      <button type="button" class="close" (click)="decline()" data-dismiss="modal">&times;</button>
    </div>
    <div class="modal-body text-color">
      <p> {{ message }}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-error-modal-close " (click)="decline()">Close</button>
    </div>
  </div>
</div>
