import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderModel } from '../../../shared/models/order.model';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  order: OrderModel;

  constructor(
    public orderService: OrderService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.getOrder(id);
  }

  private getOrder(id: string): void {
    this.orderService.getOrder(id).subscribe(
      (response) => {
        this.order = response;
      },
      (error) => {
        if (
          error.hasOwnProperty('codeError') &&
          error.codeError === 'EntityNotFound'
        ) {
          this.toastr.error('', 'Order not exists');
        }
        this.router.navigate(['/admin-area/orders-list']);
      }
    );
  }

  onSubmit(form: NgForm): void {}

  toString(text: any): string {
    return JSON.stringify(text);
  }

  downloadSecutix(response): void {
    const save = JSON.stringify(response);
    const blob = new Blob([save], {
      type: 'application/json',
    });
    saveAs(blob, 'raw_response_secutix.json');
  }

  presentSeats(seats): string {
    let str = '';
    for (const s of seats) {
      str += `${s},`;
    }
    return str;
  }

  updateOrder(order: OrderModel): void {
    this.orderService.updateOrder(order).subscribe(
      (response: any) => {
        this.init();
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
