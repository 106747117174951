import { PackageModel } from '../../shared/models';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  selectedViewPackage: PackageModel;
  availablePackageMap: Map<string, PackageModel>;

  constructor() {
  }

  get getSelectedViewPackage(): PackageModel {
    return this.selectedViewPackage;
  }

  set setSelectedViewPackage(pkg: PackageModel) {
    this.selectedViewPackage = pkg;
  }

  get getAvailablePackageMap(): Map<string, PackageModel> {
    return this.availablePackageMap;
  }

  set setAvailablePackageList(list: Map<string, PackageModel>) {
    this.availablePackageMap = list;
  }

  packageListToMap(list: Array<PackageModel>): Map<string, PackageModel> {
    const mapping = new Map<string, PackageModel>();
    for (const item of list){
      mapping.set(item.name.replace(' ', '-').toLowerCase(), item);
    }
    return mapping;
  }

  filterPackageAvailable(boughtPackages: Array<number>, availablePackages: PackageModel[]) {
  }
}
