import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { UserService } from 'src/app/services';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-create-struct',
  templateUrl: './create-struct.component.html',
  styleUrls: ['./create-struct.component.scss'],
})
export class CreateStructComponent implements OnInit {
  @Input() baseStruct: any;

  @Output() private struct = new EventEmitter<null | any>();
  structureTypes: any;
  countryCodes;

  isFullDataLoading = false;

  constructor(
    private customerService: CustomerService,
    private http: HttpClient,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    const countryCodes$ = this.getJson('/assets/data/country-codes.json');
    const structTypes$ = this.customerService.getStructureTypes();

    let structData$ = of(this.baseStruct);

    if (this.baseStruct && !this.checkCompleteStruct(this.baseStruct)) {
      structData$ = this.userService.searchClient(
        this.baseStruct.contactNumber
      );
      this.isFullDataLoading = true;
    }

    forkJoin([countryCodes$, structTypes$, structData$]).subscribe(
      ([countryCodes, structTypes, structData]) => {
        this.countryCodes = this.sortProperties(countryCodes);
        this.structureTypes = structTypes;
        this.baseStruct = structData;
        this.isFullDataLoading = false;
      }
    );
  }

  submitStruct(data) {
    if (this.baseStruct) {
      data.contactNumber = this.baseStruct.contactNumber;
      this.customerService
        .updateStruct(data)
        .subscribe((response) => this.struct.emit(response));
    } else {
      this.customerService.createStructure(data).subscribe((response) => {
        this.struct.emit(response);
      });
    }
  }

  getJson(urlToJson): Observable<any> {
    return this.http.get(urlToJson);
  }

  cancelUpdate() {
    this.struct.emit(this.baseStruct);
  }

  private checkCompleteStruct(struct) {
    return (
      struct &&
      struct.name &&
      struct.structureTypeId &&
      struct.address?.line1 &&
      struct.address?.line2 &&
      struct.address?.line3 &&
      struct.address?.town &&
      struct.address?.zipCode &&
      struct.address?.countryCode
    );
  }

  prefixUkFirst(data: Array<any>): any {
    data.forEach((item, i) => {
      if (item[0] === 'GB') {
        data.splice(i, 1);
        data.unshift(item);
      }
    });
    return data;
  }

  sortProperties(obj): Array<any> {
    // convert object into array
    const sortable = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        sortable.push([key, obj[key]]);
      }
    } // each item is an array in format [key, value]

    // sort items by value
    sortable.sort((a, b) => {
      const x = a[1];
      const y = b[1];
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return this.prefixUkFirst(sortable); // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
  }
}
