import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalsService } from '../modals.service';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { CustomerPlan } from '../../models';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-plan-seat-element-modal',
  templateUrl: './plan-seat-element-modal.component.html',
  styleUrls: ['./plan-seat-element-modal.component.scss']
})
export class PlanSeatElementModalComponent implements OnInit {

  @ViewChild(ModalDirective, { static: false }) modal: ModalDirective;
  customerPlan: CustomerPlan;
  accordionToggleSubject: Subject<number>;
  isDarkTheme: boolean;

  constructor(public bsModalRef: BsModalRef,
              private modalService: ModalsService,
              private bsModalService: BsModalService) { }

  ngOnInit() {
    if (this.bsModalRef) {
      this.bsModalService.onHidden.subscribe( $reason => {
        const reason = $reason;
        if (reason === 'backdrop-click') {
          this.accordionToggleSubject.next();
        }
      });
    }
  }

  /**
   * Hides the modal and next the subject to close the accordion
   */
  closeModal(): void {
    this.bsModalRef.hide();
    this.accordionToggleSubject.next();
  }

}
