import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoriesYears',
})
export class CategoriesYearPipe implements PipeTransform {
  transform(cats: any, ...args: unknown[]): unknown {
    // console.log(">>>><<<", cats)
    const sorted = cats.sort();
    let sentence = '';
    if (args[0] === 'numbers') {
      sentence = sorted.reduce((prev, curr) => `${prev}, ${curr}`) + ' Years';
    }
    return sentence;
  }
}
