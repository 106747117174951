import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminAreaComponent} from './admin-area/admin-area.component';
import {UserListComponent} from './admin-area/user-management/user-list/user-list.component';
import {UserDetailComponent} from './admin-area/user-management/user-detail/user-detail.component';
import {UserCreateComponent} from './admin-area/user-management/user-create/user-create.component';
import { OrderListComponent } from './admin-area/order-management/order-list/order-list.component';
import { OrderDetailsComponent } from './admin-area/order-management/order-details/order-details.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: AdminAreaComponent },
      { path: 'user-list', component: UserListComponent },
      { path: 'user-detail/:id', component: UserDetailComponent },
      { path: 'user-create', component: UserCreateComponent },
      { path: 'orders-list', component: OrderListComponent },
      { path: 'order/:id', component: OrderDetailsComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BackofficeRoutingModule {
}
