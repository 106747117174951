<div>
  <div class="super-container">
    <div class="modal-header text-bold">
      <h4 class="pull-left">{{ title }}</h4>
    </div>
    <div class="modal-body text-color">
      <p> {{ content }}</p>
    </div>
    <div class="modal-footer">
      <button *ngIf="closeBtnName" type="button" class="btn btn-primary" (click)="close()">{{closeBtnName}}</button>
      <button *ngIf="acceptBtnName=='Just add new seats'; else normalButton" type="button" class="btn btn-modal" (click)="accept()">{{acceptBtnName}}</button>
      <ng-template #normalButton>
        <button  type="button" class="btn btn-primary" (click)="accept()">{{acceptBtnName}}</button>
      </ng-template>
    </div>
  </div>
</div>
