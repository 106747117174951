import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  user$;
  isFormInvalid = false;
  errorText: string;

  showError: boolean;

  constructor(private userService: UserService, private router: Router) {
    this.showError = false;
  }

  ngOnInit(): void {}

  login(): void {
    this.userService.login(this.username, this.password).subscribe(
      (success) => {
        if (success.user.type === 'root' || success.user.type === 'admin') {
          this.router.navigate(['/admin-area']);
        } else {
          this.router.navigate(['/homepage']);
        }
      },
      (error) => {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
        }, 5000);
      }
    );
  }
}
