<div class="content-container pt-5">
  <app-sub-header
    [header]="'Checkout'"
    [gohome]="true"
    [goback]="'/link-account'"
    [color]="'header-title'"
    [includeCustomer]="false"
  >
  </app-sub-header>

  <div class="summary-container text-normal m-auto">
    <div class="d-flex justify-content-center">
      <div [ngClass]="innerWidth < 850 ? 'col-12' : 'col-9'" class="m-auto">
        <div class="mb-5 d-flex justify-content-between">
          <div>
            <div class="mb-4 customerName-container">
              {{ customer.name }} {{ customer.lastname }}
            </div>
            <div class="d-flex">
              <div>
                <div>{{ customer.address.line1 }}</div>
                <div>{{ customer.address.line2 }}</div>
                <div>{{ customer.address.line3 }}</div>
                <div>{{ customer.address.town }}</div>
                <div>{{ customer.address.zipCode }}</div>
              </div>
              <div class="ml-5">
                <div>{{ customer.birthdate }}</div>
                <div>{{ customer.email }}</div>
                <div>{{ customer.phonePrefix }} {{ customer.phone }}</div>
              </div>
            </div>
          </div>

          <div class="ml-5 fileNumber-container text-right">
            <h6>Client Reference Number:</h6>
            <div>{{ customer.contactNumber }}</div>
          </div>
        </div>

        <div class="row">
          <div class="parentof">
            <div class="childof">
              <div
                *ngFor="let acquirement of packageAcquirements"
                class="packages-list table-container"
              >
                <div>
                  <h4 class="package-title mb-3">
                    Package: {{ acquirement.packageName }}
                  </h4>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="d-flex align-items-center">
                      <h6 *ngIf="packagesRelations[acquirement.shortName]">
                        Type:
                      </h6>
                      <select
                        class="select-custom ml-2"
                        [(ngModel)]="
                          selectedTypeOption[acquirement.shortName]
                        "
                        (change)="
                          changePackageType($event, acquirement.packageName)
                        "
                      >
                        <option
                          *ngFor="
                            let item of this.packagesRelations[
                              acquirement.shortName
                            ]
                          "
                          [value]="item.id"
                        >
                          {{ item.type }}
                        </option>
                      </select>
                    </div>
                    <h6
                      class="ml-5"
                      *ngIf="
                        acquirement.packageId == '10228599874597' ||
                        acquirement.packageId == '10228603284752' ||
                        acquirement.packageId == '10228599874598' ||
                        acquirement.packageId == '10228603284751'
                      "
                    >
                      Term Length:<span class="ml-2">{{
                        acquirement.audienceSubCatName
                      }}</span>
                    </h6>
                    <button
                      class="btn btn-secondary ml-5"
                      (click)="removePackage(acquirement.packageId)"
                    >
                      <i class="fa fa-times mr-2"></i> Remove
                    </button>
                  </div>
                </div>

                <div class="row mt-5">
                  <table class="table table-grid w-100">
                    <thead>
                      <tr class="trhead">
                        <th>Block</th>
                        <th>Row</th>
                        <th>Seat Number</th>
                        <th>Net Price</th>
                        <th>Business</th>
                        <th>Assign Seat</th>
                        <th
                          *ngIf="
                            acquirement.packageId == '10228599874597' ||
                            acquirement.packageId == '10228603284752' ||
                            acquirement.packageId == '10228599874598' ||
                            acquirement.packageId == '10228603284751'
                          "
                        >
                          {{ acquirement.pacakgeId }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="trbody"
                        *ngFor="let seatData of acquirement.seats"
                      >
                        <td>{{ seatData.area }}</td>
                        <td>{{ seatData.row }}</td>
                        <td>{{ seatData.seat }}</td>
                        <td>{{ seatData.amount / 1.2 | currency: 'GBP' }}</td>
                        <td>
                          <span *ngIf="!seatData.assignedClient"></span>
                          <span
                            *ngIf="
                              (seatData.assignedClient &&
                                !seatData.assignedClient.structure) ||
                              (seatData.assignedClient &&
                                !seatData.assignedClient.structureContactNumber)
                            "
                            >-</span
                          >
                          <span
                            *ngIf="
                              seatData.assignedClient &&
                              seatData.assignedClient.structure &&
                              seatData.assignedClient.structure.name
                            "
                            >{{ seatData.assignedClient.structure.name }}</span
                          >
                        </td>
                        <td>
                          <i
                            *ngIf="!seatData.assignedClient"
                            (click)="
                              assignSeat(
                                acquirement.packageId,
                                seatData,
                                acquirement
                              )
                            "
                            class="fa fa-user-plus"
                          ></i>
                          <span *ngIf="seatData.assignedClient"
                            >{{ seatData.assignedClient.contactNumber }}
                            <button
                              class="btn btn-secondary ml-1"
                              (click)="
                                removeAssign(
                                  acquirement.packageId,
                                  seatData,
                                  acquirement
                                )
                              "
                            >
                              <i class="fa fa-times"></i>
                            </button>
                          </span>
                        </td>
                        <td
                          *ngIf="
                            acquirement.packageId == '10228599874597' ||
                            acquirement.packageId == '10228603284752' ||
                            acquirement.packageId == '10228599874598' ||
                            acquirement.packageId == '10228603284751'
                          "
                        >
                          <select
                            [ngModel]="seatData.categoryId"
                            (ngModelChange)="
                              handleSeatCategoryChange(
                                acquirement,
                                seatData,
                                $event
                              )
                            "
                            class="select-custom"
                          >
                            <option
                              *ngFor="
                                let availableCategory of acquirement.categories
                              "
                              [value]="availableCategory.id"
                            >
                              {{ availableCategory.name }}
                            </option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="col-3 d-flex flex-column customer-info">-->
      <!--        <div>-->
      <!--          <span class="contact-info-label">Client Reference Number:</span> {{customer.contactNumber}}-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          <span class="contact-info-label">Contact Name:</span> {{customer.name}} {{customer.lastname}}-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          <span class="contact-info-label">Contact Email:</span> {{customer.email}}-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          <span class="contact-info-label">Contact Phone:</span> {{customer.phone}}-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          <span class="contact-info-label">Contact Address:</span>-->
      <!--          <div class="customer-info-adress-container">-->
      <!--            <div>{{customer.address.line1}}</div>-->
      <!--            <div>{{customer.address.line2}}</div>-->
      <!--            <div>{{customer.address.line3}}</div>-->
      <!--            <div>{{customer.address.town}}</div>-->
      <!--            <div>{{customer.address.zipCode}}</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="row mt-5 d-flex justify-content-center">
      <div [ngClass]="innerWidth < 850 ? 'col-12' : 'col-9'">
        <div>
          <p class="text-right price-table" style="font-weight: bold">
            Subtotal: {{ subtotal / 1.2 | currency: 'GBP' }}
          </p>
          <p class="text-right price-table" style="font-weight: bold">
            Total ( + VAT ): {{ total | currency: 'GBP' }}
          </p>
        </div>
        <div class="text-right">
          <label class="w-100">
            <span class="agreement-text"
              >Agree to the
              <a href="../../../assets/TC/tearmsconditions.pdf" target="_blank">
                Seasonal Hospitality T&C</a
              >.
            </span>
            <input
              class="TC w-auto ml-3"
              type="checkbox"
              [(ngModel)]="acceptedTerms"
            />
          </label>
        </div>
      </div>
    </div>
    <!--    <div class="mt-4 row flex-column">-->
    <!--      <div class="">-->
    <!--        <label class="w-100 d-flex">-->
    <!--          <span class="">Agree to the <a href="../../../assets/TC/tearmsconditions.pdf" target="_blank"> Seasonal-->
    <!--              Hospitality T&C</a>. </span>-->
    <!--          <input class="TC w-auto ml-3" type="checkbox" [(ngModel)]="acceptedTerms">-->
    <!--        </label>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="row m-5">
      <hr class="h-line mb-5" />
      <div class="col text-center">
        <button
          class="btn-white btn-selectmore w-25"
          (click)="addMorePackages()"
        >
          Select more packages
        </button>
        <button
          class="btn-white btn-confirm ml-4 w-25"
          [disabled]="!acceptedTerms || !this.checkAllSeatsAssigned()"
          (click)="createOrder()"
        >
          Confirm seats
        </button>
      </div>
    </div>

    <div class="spinner" [style.visibility]="showLoader ? 'visible' : 'hidden'">
      <i class="fa fa-spinner fa-pulse"></i>
      <div>Your order is being processed. Do not close this page.</div>
    </div>
  </div>
</div>
