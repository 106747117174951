
<div class="top66 content-container pt-5">
    <app-sub-header
    [header]="'Packages'"
    [goback]="true"
    [gohome]="true"
    [color]="'header-title'"
    ></app-sub-header>

  <div class="packages-container">
    <div class="row">
      <app-home-page-package
        *ngFor="let pkg of packageAvailableList"
        [package]="pkg"
        [show-package-info]="false"
        class="col-3">
      </app-home-page-package>
    </div>
  </div>
</div>
