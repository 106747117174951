import { Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { FlowcontrolService } from 'src/app/services/flowcontrol.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PackageModel } from 'src/app/shared/models';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss']
})
export class SubHeaderComponent implements OnInit {

  @Input('header') headerString: string;
  @Input('homepage') homepage = false;
  @Input('isselection') selection = false;
  @Input('isummary') summary = false;
  @Input('gohome') gohome = false;
  @Input('goback') goback = false;
  @Input('overrideBack') overrideBack = false;
  @Input('color') colorText = 'title-text';
  @Input('color-sub') colorTextSub = 'subtitle-text';
  @Input('includeCustomer') includeCustomer: boolean = true;

  customer;


  @Output("back") backEmitter: EventEmitter<void>;

  constructor(private navigation: NavigationService,
    private flowControl: FlowcontrolService,
    private customerService: CustomerService) {
      this.backEmitter = new EventEmitter<void>();
    }

  ngOnInit(): void {
    this.flowControl.getCustomer().subscribe(customerData => {

      if (customerData) {
          this.customer = `${customerData.name} ${customerData.lastname}`;
      } else {
        this.customer = null;
      }
    })
  }

  onNavigate(path?: string): void {
    if (path) {
      this.navigation.goTo(path);
    } else {
      if (this.overrideBack) {
        this.backEmitter.emit();
      } else {
        this.navigation.goBack();
      }
    }
  }

  selectText(): string {
    return this.summary ? 'Go home' : 'Go back';
  }


  terminateCustomer() {
    this.flowControl.setCustomer(null);
    this.navigation.goTo("homepage");
  }
}
