// import { Component, OnInit } from '@angular/core';
// import { BsModalRef } from 'ngx-bootstrap/modal';
//
// @Component({
//   selector: 'app-assign-person-modal',
//   templateUrl: './assign-person-modal.component.html',
//   styleUrls: ['./assign-person-modal.component.scss']
// })
// export class AssignPersonModalComponent implements OnInit {
//
//   step: "create-customer" | "select" | "create-business" | "edit-customer" | null = null;
//
//   currentCustomer;
//
//   selectedClient;
//
//   constructor(private bsModalRef: BsModalRef) { }
//
//   ngOnInit(): void {
//   }
//
//   selectStep(step) {
//     this.step = step;
//   }
//
//
//   createCustomer(val) {
//
//   }
//
//   selectClient(client) {
//     this.selectedClient = client;
//     this.closeModal();
//   }
//
//   closeModal() {
//     this.bsModalRef.hide();
//   }
//
//   // -EDIT CUSTOMER (use customer)-
//
//   useCustomer(customer) {
//     this.currentCustomer = customer;
//     this.selectStep("edit-customer");
//   }
//
// }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CustomerService } from '../services/customer.service';
import { FlowcontrolService } from '../services/flowcontrol.service';
import { NavigationService } from '../services/navigation.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-assign-person-modal',
  templateUrl: './assign-person-modal.component.html',
  styleUrls: ['./assign-person-modal.component.scss'],
})
export class AssignPersonModalComponent implements OnInit {
  pageHeader: string = 'Select Account Option';

  step: 'create-customer' | 'select' | 'create-business' | null = null;
  businessStep:
    | 'create-business-member'
    | 'find-stuct'
    | 'assign-struct-to-member'
    | 'create-struct'
    | null = null;

  editCustomerPhase = false;

  atBusinessPage = false;

  selectedStruct;

  selectedClient;

  currentCustomer;

  constructor(
    private navigation: NavigationService,
    private activatedRoute: ActivatedRoute,
    private flowControl: FlowcontrolService,
    private bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {}

  goToMethod(route: string) {
    this.navigation.goTo(route, { relativeTo: this.activatedRoute });
  }

  handleBack() {
    if (this.editCustomerPhase) {
      this.editCustomerPhase = false;
      this.currentCustomer = null;
    } else if (this.atBusinessPage && this.businessStep) {
      this.businessStep = null;
      this.pageHeader = 'Business Page';
    } else if (this.atBusinessPage && !this.businessStep) {
      this.atBusinessPage = false;
      this.pageHeader = 'Customer Account Options';
    } else if (!this.atBusinessPage && this.step) {
      this.step = null;
      this.pageHeader = 'Customer Account Options';
    } else {
      this.closeModal();
    }
  }

  selectStep(step) {
    this.step = step;

    if (step === 'create-customer') {
      this.pageHeader = 'Create New Customer';
    } else if (step === 'select') {
      this.pageHeader = 'Assign Customer - Find Customer';
    }
  }

  selectBusinessStep(step) {
    this.businessStep = step;

    if (step === 'create-business-Member') {
      this.pageHeader = 'Assign Customer - Create Business Member';
    } else if (step === 'assign-structu-to-member') {
      this.pageHeader = 'Assign Customer - Assign Customer to Structure';
    } else if (step === 'create-struct') {
      this.pageHeader = 'Assign Customer - Create Structure';
    }
  }

  toggleBusinessPage(isOpen?: boolean) {
    this.atBusinessPage = isOpen ?? !this.atBusinessPage;
    this.pageHeader = this.atBusinessPage
      ? 'Business Account Options'
      : 'Customer Account Options';
  }

  // -SELECT- STEP

  selectClient(client) {
    this.currentCustomer = client;
    this.editCustomerPhase = true;
  }

  // -CREATE STRUCT- STEP
  createBusinessCustomer(struct) {}

  // -EDIT CUSTOMER (use customer)-

  useCustomer(customer) {
    this.selectedClient = customer;
    this.closeModal();
  }

  handleNewStruct(struct) {
    this.selectedStruct = struct;
    this.businessStep = 'create-business-member';
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
