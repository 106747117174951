<div class="content-container">
    <app-sub-header
    [header]="pageHeader"
    [gohome]="true"
    [color]="'header-title'"
    [overrideBack]="true"
    (back)="handleBack()">
    </app-sub-header>

    <div *ngIf="!editCustomerPhase" class="customer-options-container m-auto">
        <div [ngSwitch]="step" class="standard-customer-options" [class.current]="!atBusinessPage">

            <div *ngSwitchCase="null" class="link-options-container">
                <div class="link-option">
                    <p class="link-option-desc">New Customer?</p>
                    <button class="btn-white link-option-button" (click)="selectStep('create-customer')">Create New Account</button>
                </div>
                <div class="link-option">
                    <p class="link-option-desc">Existing Customer?</p>
                    <button class="btn-white link-option-button" (click)="selectStep('select')">Find Account</button>
                </div>
                <div class="link-option">
                    <p class="link-option-desc">Buying through your business?</p>
                    <button class="btn-white link-option-button" (click)="toggleBusinessPage(true)">Business portal</button>
                </div>
            </div>


            <div *ngSwitchCase="'create-customer'">
                <app-customer-form buttonText="Sign Up" (selectCustomer)="selectClient($event)"></app-customer-form>

            </div>

            <div *ngSwitchCase="'select'">
                <app-search-client [searchType]="'customer'" (client)="selectClient($event)"></app-search-client>
            </div>

            <!-- <div *ngSwitchCase="'create-business'" >
                <app-create-struct (structContactID)="handleAssignStruct($event)"></app-create-struct>
                <app-customer-form
                buttonText="Create business account"
                (selectCustomer)="createBusinessCustomer($event)"
                [includeStructure]="true"
                ></app-customer-form>
            </div> -->

        </div>

        <div [ngSwitch]="businessStep" class="struct-customer-options" [class.current]="atBusinessPage">
            <div *ngSwitchCase="null" class="link-options-container">
                <!-- <div class="link-option">
                    <p class="link-option-desc">Need a new structure member?</p>
                    <button class="btn-white link-option-button" (click)="selectBusinessStep('create-business-member')">Create structure account</button>
                </div> -->
                <div class="link-option">
                    <p class="link-option-desc">Create new contact for existing business</p>
                    <button class="btn-white link-option-button" (click)="selectBusinessStep('find-struct')">Create</button>
                </div>
                <!-- <div class="link-option">
                    <p class="link-option-desc">Already have an account?</p>
                    <button class="btn-white link-option-button" (click)="selectBusinessStep('find-struct-member')">Find customer account</button>
                </div> -->
                <div class="link-option">
                    <p class="link-option-desc">Create new business for existing contact</p>
                    <button class="btn-white link-option-button" (click)="selectBusinessStep('assign-struct-to-member')">Create</button>
                </div>
                <div class="link-option">
                    <p class="link-option-desc">Create new business and contact accounts</p>
                    <button class="btn-white link-option-button" (click)="selectBusinessStep('create-struct')" >Create</button>
                </div>
            </div>

            <div *ngSwitchCase="'create-business-member'">
                <app-customer-form buttonText="Sign Up" (selectCustomer)="useCustomer($event)" [includeStructure]="true" [structure]="selectedStruct"></app-customer-form>
            </div>

            <div *ngSwitchCase="'find-struct'">
                <app-customer-form
                buttonText="Confirm and continue"
                [editable]="true"
                (selectCustomer)="useCustomer($event)"
                [includeStructure]="true"
                ></app-customer-form>
            </div>

            <div *ngSwitchCase="'find-struct-member'">
                <app-search-client [criteria]="'struct-customer'" [searchType]="'business'" (client)="selectClient($event)"></app-search-client>
            </div>

            <!-- <div *ngSwitchCase="'find-struct'">
                <app-search-client [criteria]="'struct'" (client)="handleNewStruct($event)"></app-search-client>
            </div> -->


            <div *ngSwitchCase="'assign-struct-to-member'">
              <app-create-struct (struct)="handleNewStruct($event)"></app-create-struct>
<!--                <app-assign-struct (customer)="selectClient($event)"  (structure)="handleNewStruct($event)"></app-assign-struct>-->
            </div>

            <div *ngSwitchCase="'assign-struct-to-relay'">
                  <app-assign-relay (customer)="selectClient($event)"  [structure]="selectedStruct"></app-assign-relay>
            </div>

          <div *ngSwitchCase="'select-customer-for-structure'">
            <app-search-client [searchType]="'customer'" (client)="selectedClientToAssignStruct($event)"></app-search-client>
          </div>


          <div *ngSwitchCase="'assigning'">
            <app-assign-struct [selectedCustomer]="currentCustomer" [selectedStruct]="selectedStruct" (customer)="selectClient($event)"  (structure)="handleNewStruct($event)"></app-assign-struct>
          </div>

            <div *ngSwitchCase="'create-struct'">
                <app-create-struct (struct)="handleNewStructAndCustomer($event)"></app-create-struct>
            </div>
        </div>
    </div>

    <div *ngIf="editCustomerPhase" class="w-75 m-auto">
        <app-customer-form
        buttonText="Confirm and continue"
        [editable]="true"
        [initReadOnly]="true"
        [baseCustomer]="currentCustomer"
        (selectCustomer)="useCustomer($event)"
        [includeStructure]="!!currentCustomer.structureContactNumber"
        [structure]="selectedStruct"
        ></app-customer-form>
    </div>
</div>
