import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { FlowcontrolService } from '../../../services/flowcontrol.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss'],
})
export class CustomerFormComponent implements OnInit {
  // State variables

  @Input() buttonText: string;

  @Input() baseCustomer: any;

  @Input() editable: boolean;
  @Input() initReadOnly: boolean;
  @Input() includeStructure: boolean = false;
  @Input() structure: any;

  isReadOnly: boolean = false;

  @Output() selectCustomer: EventEmitter<any>;
  @Output() updatedCustomer: EventEmitter<any>;

  @ViewChild('dateElement') inputBirthdate;
  // View
  currentView: 'find-struct' | 'edit-struct' | null = null;

  selectedStruct;

  // State variables
  currentTab: 'create' | 'search' | 'create-struct' = 'create';
  countryPhone: any;
  countryCode: any;
  prefixPhone = 'UNDEFINED';
  // @ts-ignore
  emailRegex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  phoneRegex = /^[0-9]*$/;

  currPackageId: number;

  structContactNumber;

  varDateSwitcher = false;

  constructor(
    private http: HttpClient,
    private customerService: CustomerService,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private flowControl: FlowcontrolService
  ) {
    this.selectCustomer = new EventEmitter<any>();
    this.updatedCustomer = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.isReadOnly = this.editable && this.initReadOnly;
    // console.log('form status readonly ->: ', this.isReadOnly);
    // console.log('el customer base es', this.baseCustomer);

    if (this.baseCustomer && this.baseCustomer.structure) {
      // console.log('entro');
      this.structContactNumber = this.baseCustomer.structure.name;
      this.selectedStruct = this.baseCustomer.structure;
    } else if (this.structure && this.structure.name) {
      // console.log('salgo');
      this.structContactNumber = this.structure.name;
      this.selectedStruct = this.structure;
    }

    const joins = [
      this.getJson('/assets/data/country-codes.json'),
      this.getJson('/assets/data/country-phones.json'),
    ];
    forkJoin(joins).subscribe((responseList) => {
      this.countryCode = this.sortProperties(responseList[0]);
      this.countryPhone = this.sortProperties(responseList[1]);
      if (this.baseCustomer) {
        this.baseCustomer.phonePrefix = this.setPhoneAndPrefix(
          this.baseCustomer.countryCode
        );
        this.baseCustomer.phone = this.baseCustomer.phone
          ? this.baseCustomer.phone.replace(this.baseCustomer.phonePrefix, '')
          : '';
        if (this.baseCustomer.birthdate) {
          this.baseCustomer.birthdate = this.formatDate(
            this.baseCustomer.birthdate
          );
        }
      }
    });
  }

  formatDate(date) {
    // tslint:disable-next-line:one-variable-per-declaration prefer-const
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      // tslint:disable-next-line:prefer-const
      year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  setPhoneAndPrefix(countryCode: string): any {
    const prefix = this.countryPhone.find((item, i) => {
      if (item[0] === countryCode) {
        return `${item[1]}`;
      }
    });
    return prefix[1];
    // return `${this.countryPhone[countryCode]}`;
  }

  getJson(urlToJson): Observable<any> {
    return this.http.get(urlToJson);
  }

  prefixUkFirst(data: Array<any>): any {
    data.forEach((item, i) => {
      if (item[0] === 'GB') {
        data.splice(i, 1);
        data.unshift(item);
      }
    });
    return data;
  }

  sortProperties(obj): Array<any> {
    // convert object into array
    const sortable = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        sortable.push([key, obj[key]]);
      }
    } // each item is an array in format [key, value]

    // sort items by value
    sortable.sort((a, b) => {
      const x = a[1];
      const y = b[1];
      return x < y ? -1 : x > y ? 1 : 0;
    });
    return this.prefixUkFirst(sortable); // array in format [ [ key1, val1 ], [ key2, val2 ], ... ]
  }

  toggleReadOnly() {
    this.isReadOnly = !this.isReadOnly;
  }

  cancelChanges(form: NgForm) {
    form.resetForm();
  }

  submitForm(form: NgForm) {
    if (this.baseCustomer && this.isReadOnly) {
      this.selectCustomer.emit(this.baseCustomer);
      return;
    }
    const customer = form.value;
    if (this.structure) {
      // console.log('if structure');
      // console.log(this.structure.contactNumber);
      customer.structureContactNumber = this.structure.contactNumber;
    } else if (this.selectedStruct) {
      customer.structureContactNumber = this.selectedStruct.contactNumber;
    }


    // console.log(customer);

    if (this.baseCustomer) {
      customer.contactNumber = this.baseCustomer.contactNumber;
      // console.log('enviando solitude editar');
      customer.role = 'PUBLIC';
      // ver si debemos cambiar el prefijo
      if (!form.controls.countryCode.pristine) {
        customer.phone = `${this.baseCustomer.phonePrefix}${customer.phone}`;
        delete customer.phonePrefix;
      }
      this.customerService.editCustomer(customer).subscribe(
        (response) => {
          // this.baseCustomer = response;
          this.baseCustomer.phonePrefix = this.setPhoneAndPrefix(
            response.countryCode
          );
          this.baseCustomer.phone = this.baseCustomer.phone
            ? this.baseCustomer.phone // this.baseCustomer.phone.replace(this.baseCustomer.phonePrefix, '')
            : '';
          this.baseCustomer.birthdate = this.formatDate(
            this.baseCustomer.birthdate
          );
          this.isReadOnly = true;
        },
        (error) => form.resetForm(this.baseCustomer)
      );
    } else {
      customer.phone = `${this.prefixPhone}${customer.phone}`;
      customer.role = 'PUBLIC';
      this.customerService.createCustomer(customer).subscribe((response) => {
        this.baseCustomer = response;
        this.baseCustomer.phonePrefix = this.setPhoneAndPrefix(
          this.baseCustomer.countryCode
        );
        this.baseCustomer.phone = this.baseCustomer.phone
          ? this.baseCustomer.phone.replace(this.baseCustomer.phonePrefix, '')
          : '';
        this.baseCustomer.birthdate = this.formatDate(
          this.baseCustomer.birthdate
        );
        this.selectCustomer.emit(this.baseCustomer);
      });
    }

    // const { dirty } = form;
    // const customer = form.value;
    // customer.role = "PUBLIC";
    // if (this.baseCustomer) {
    //   customer.contactId = this.baseCustomer.contactId;
    // }

    // customer.phone = `${customer.phonePrefix}${customer.phone}`;

    // this.selectCustomer.emit(customer)
  }

  emitEdit(form) {
    this.toggleReadOnly();
  }

  selectView(view) {
    this.currentView = view;
  }

  selectStruct(struct) {
    // console.log('selecting the struct', struct);
    this.structContactNumber = struct.structure.name;
    this.selectedStruct = struct;
    this.selectView(null);
  }

  public displayDate(): void {
    this.renderer.setProperty(
      this.inputBirthdate.nativeElement,
      'type',
      'date'
    );
    this.cd.detectChanges();
    // this.inputBirthdate.nativeElement.showPicker();
    this.inputBirthdate.nativeElement.click();
    // let el: HTMLElement = this.inputBirthdate.nativeElement;
    // el.click();
  }

  // Cambia el prefijo del telefono en el formulario
  onChangeCountry({ value }): void {
    if (this.baseCustomer) {
      this.baseCustomer.phonePrefix = this.setPhoneAndPrefix(value);
    } else {
      this.prefixPhone = this.setPhoneAndPrefix(value);
    }

  }
}
