import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';
import { OrderModel } from '../../../shared/models/order.model';
import { ActivatedRoute } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PaginationModel } from '../../../shared/models/PaginationModel';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  orderList: Array<OrderModel>;
  // PaginationModel variables
  page: number;
  totalItems: number;
  itemsPerPage = 10;
  totalPages: number;
  currentPage: number;

  constructor(public orderService: OrderService,
              private route: ActivatedRoute) {
    this.currentPage = 1;
  }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    const startpage = parseInt(this.route.snapshot.queryParamMap.get('startpage'), 10);
    this.currentPage = isNaN(startpage) ? 1 : startpage;
    this.getOrders();
  }

  private getOrders(page: number = 1): void {
    this.orderService.getOrders(page).subscribe((orders: PaginationModel<OrderModel>) => {
      this.orderList = orders.list;
      this.totalItems = orders.count;
      this.totalPages = this.totalItems / this.itemsPerPage;
      document.getElementById('user-table')?.classList.remove('loading');
    });
  }

  public pageChanged(event: PageChangedEvent): void {
    document.getElementById('user-table').classList.add('loading');
    this.getOrders(event.page);
  }

}
