<div class="selection-container">
  <div class="mt-4 h-100" *ngIf="dvmSelectedSeats.length; else noSelectedSeats">
    <div class="table-container p-5">
      <table class="table-grid text-normal">
        <thead>
          <tr class="trhead">
            <th>Block</th>
            <th>Row</th>
            <th>Seat Number</th>
            <th>Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="trbody" *ngFor="let seat of dvmSelectedSeats">
            <td>{{ seat.split('-')[0].replace('S_', '') }}</td>
            <td>{{ seat.split('-')[1] }}</td>
            <td>{{ seat.split('-')[2] }}</td>
            <td>{{ categories[categoryID].amount | currency: 'GBP' }}</td>
            <td id="table-360" (click)="open3dView(seat)">360º</td>
          </tr>
        </tbody>
      </table>
      <div
        class="p-3 mt-4 text-normal text-left"
        *ngIf="
          currentPackage.packageId != '10228599874597' &&
          currentPackage.packageId != '10228603284752' &&
          currentPackage.packageId != '10228599874598' &&
          currentPackage.packageId != '10228603284751'
        "
      >
        <label> Select Term Length </label>
        <select
          name="select"
          (change)="onTermChange(selector.value)"
          #selector
          class="select-custom ml-4"
        >
          <option
            *ngFor="let category of categoryNames; let i = index"
            value="{{ category.id }}"
            [selected]="i === 0"
          >
            {{ category.name }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <ng-template #noSelectedSeats>
    <div class="mt-4 d-flex justify-content-center align-items-center">
      <h6 class="no-results-msg">
        Please select one or more seats in the viewer to continue.
      </h6>
    </div>
  </ng-template>
</div>
