import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { APP_CONFIG, ApplicationConfiguration } from './configuration/ticket-management-portal-configuration';
import { LoginModule } from './login/login.module';
import { DigitalvenueModule } from './digitalvenue/digitalvenue.module';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalModule} from 'ngx-bootstrap/modal';
import { AuthInterceptor } from './auth/auth-interceptor';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TopbarComponent} from './topbar/topbar.component';
import {PackageSelectorModule} from './package-selector/package-selector.module';
import { CheckoutComponent } from './checkout/checkout.component';
import { InitializerService } from './services/initializer.service';
import { LoginClientComponent } from './login-client/login-client.component';
import { DVM_CALLBACKS, DVM_INTERCEPTORS } from './digitalvenue/digitalvenue.configuration';
import { DvmCallbacksService } from './services/dvm-callbacks.service';
import {BackofficeModule} from './backoffice/backoffice.module';
import { SummaryComponent } from './summary/summary.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CartDataInterceptor } from './services/cart-data.interceptor';
import { SingleSeatInterceptor } from './services/single-seat.interceptor';
import { LinkAccountComponent } from './link-account/link-account.component';
import { AssignPersonModalComponent } from './assign-person-modal/assign-person-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    LoginClientComponent,
    SummaryComponent,
    LinkAccountComponent,
    AssignPersonModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    LoginModule,
    DigitalvenueModule,
    PackageSelectorModule,
    SharedModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BackofficeModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: DVM_CALLBACKS, useClass: DvmCallbacksService},
    {provide: APP_CONFIG, useValue: ApplicationConfiguration},
    {provide: LOCALE_ID, useValue: 'en-GB'},
    {provide: DVM_INTERCEPTORS, useClass: CartDataInterceptor, multi: true},
    {provide: APP_INITIALIZER, useFactory: (initializer: InitializerService) => () => initializer.init(),
      multi: true , deps: [InitializerService] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
