import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/';


@Injectable()
/**
 * `HttpInterceptor` which adds an XSRF token to eligible outgoing requests.
 */
@Injectable()
export class HttpTokenInterceptorMod implements HttpInterceptor {

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = '';
    let token = '';
    if (localStorage.getItem('access_token') !== null) {
      token = JSON.parse(localStorage.getItem('access_token'));
      // @ts-ignore
      if (token.token !== null && !req.headers.has(headerName)){
        req = req.clone({
          setHeaders: {
            // @ts-ignore
            Authorization: `Bearer ${token.token}`
          }
        });
      }
    }
    return next.handle(req);
  }
}
